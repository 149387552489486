import {BooleanField, StepNavButtons, StepHeader, ProductPartsField} from 'components'
import {z} from 'zod'
import {zodResolver} from 'ui/form'
import {useForm} from 'core/hooks'
import {formatTestID} from 'core/utils'
import {Stack} from 'ui/core'
import {Product} from 'model'
import {IssuesFormData} from '../types'

export interface IssuesProps {
  product?: Product
  initialValues?: IssuesFormData
  onBack: (data: IssuesFormData) => void
  onNext: (data: IssuesFormData) => void
  testID?: string
}

const issueSchema = () =>
  z
    .object({
      part: z.object({
        id: z.string(),
        name: z.string(),
        image: z.string().optional(),
      }),
      description: z.string().optional(),
    })
    .array()
    .optional()

const damagesSchema = z
  .object({
    hasDamages: z.boolean({required_error: 'Select an option'}),
    damages: issueSchema(),
  })
  .refine((data) => (data.hasDamages ? !!data.damages?.length : true), {
    message: 'Select damaged parts',
    path: ['damages'],
  })

const repairsSchema = z
  .object({
    wasRepaired: z.boolean({required_error: 'Select an option'}),
    repairs: issueSchema(),
  })
  .refine((data) => (data.wasRepaired ? !!data.repairs?.length : true), {
    message: 'Select repaired parts',
    path: ['repairs'],
  })

const schema = z.intersection(damagesSchema, repairsSchema)

const Issues = ({initialValues, product, onBack, onNext, testID}: IssuesProps) => {
  const form = useForm({
    initialValues: {
      wasRepaired: initialValues?.repairs ? true : undefined,
      repairs: initialValues?.repairs,
      hasDamages: initialValues?.damages ? true : undefined,
      damages: initialValues?.damages,
    },
    validate: zodResolver(schema),
  })

  const back = () => {
    const {repairs, damages} = form.values
    onBack({repairs, damages})
  }

  const next = () => {
    if (form.validate().hasErrors) return
    const {repairs, damages} = schema.parse(form.values)
    onNext({repairs, damages})
  }

  const repairChange = (value: boolean) => {
    form.setFieldValue('wasRepaired', value)
    form.setFieldValue('repairs', value ? [] : undefined)
  }

  const damageChange = (value: boolean) => {
    form.setFieldValue('hasDamages', value)
    form.setFieldValue('damages', value ? [] : undefined)
  }

  return (
    <>
      <StepHeader title="Issues" testID={formatTestID(testID, 'header')} />
      <Stack gap="xl">
        <BooleanField
          {...form.getInputProps('hasDamages')}
          required
          size="lg"
          label="Does it have any damaged parts?"
          value={form.values.hasDamages}
          onChange={damageChange}
          testID={formatTestID(testID, 'has-damages')}
        >
          <ProductPartsField
            {...form.getInputProps('damages')}
            product={product}
            trapFocus={form.values.hasDamages}
            testID={formatTestID(testID, 'damaged-parts')}
          />
        </BooleanField>
        <BooleanField
          {...form.getInputProps('wasRepaired')}
          required
          size="lg"
          label="Was it repaired before?"
          value={form.values.wasRepaired}
          onChange={repairChange}
          testID={formatTestID(testID, 'was-repaired')}
        >
          <ProductPartsField
            {...form.getInputProps('repairs')}
            product={product}
            trapFocus={form.values.wasRepaired}
            testID={formatTestID(testID, 'repaired-parts')}
          />
        </BooleanField>
      </Stack>
      <StepNavButtons
        back={{onClick: back}}
        next={{onClick: next}}
        testID={formatTestID(testID, 'nav-buttons')}
      />
    </>
  )
}

export default Issues
