import {Button, Group, Icon, Loader, Menu, TextInput, TextInputProps, ThemeIcon} from 'ui/core'
import {forwardRef, useImperativeHandle, useRef} from 'react'
import {useBreakpoint} from 'core/hooks'
import {useProductSearch} from '../useProductSearch'
import {SearchType} from '../types'

interface ProductSearchInputProps extends TextInputProps {
  onCancel?: () => void
}

const ProductSearchInput = forwardRef<HTMLInputElement, ProductSearchInputProps>(
  ({onCancel, ...rest}, ref) => {
    const {autocomplete, searchType, setSearchType, status} = useProductSearch()
    const {isXs} = useBreakpoint()
    const inputRef = useRef<HTMLInputElement>(null!)
    const stalled = status === 'stalled'
    const loading = status === 'loading'

    useImperativeHandle(ref, () => inputRef?.current, [inputRef])

    const changeSearchType = (type: SearchType) => {
      setSearchType(type)
      inputRef.current?.focus()
    }

    const menuTrigger = isXs ? (
      <ThemeIcon size="lg" variant="filled" color="#fff" radius="xl">
        <Icon name="chevronDown" size="md" color="blue" />
      </ThemeIcon>
    ) : (
      <Button
        radius="xl"
        size="sm"
        color="dark"
        variant="white"
        rightSection={<Icon name="chevronDown" />}
        styles={{section: {marginLeft: 4}}}
      >
        {searchType}
      </Button>
    )

    const menu = (
      <Menu withinPortal shadow="md" width={100} position="bottom-end">
        <Menu.Target>{menuTrigger}</Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => changeSearchType('Buy')}>Buy</Menu.Item>
          <Menu.Item onClick={() => changeSearchType('Sell')}>Sell</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    )

    return (
      <Group wrap="nowrap" gap="xs">
        <TextInput
          {...(autocomplete.getInputProps({} as any) as any)}
          {...rest}
          w="100%"
          ref={inputRef}
          variant="filled"
          size="lg"
          radius="xl"
          autoFocus
          placeholder={`What are you ${searchType === 'Sell' ? 'selling' : 'buying'}?`}
          leftSection={
            loading || stalled ? (
              <Loader size="xs" color="gray" />
            ) : (
              <Icon name="search" color="blue" />
            )
          }
          rightSectionWidth={isXs ? undefined : 88}
          rightSection={
            <Group wrap="nowrap" gap={8} align="center" justify="right">
              {menu}
            </Group>
          }
        />
        <Button size="compact-md" variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
      </Group>
    )
  },
)

export default ProductSearchInput
