import {useState} from 'react'
import {Button} from 'ui/core'
import {useAuth} from '@resellam/auth'
import {getErrorMessage} from '@resellam/firebase'
import {EmailForm} from '../EmailForm'
import {EmailSent} from '../AuthForm'
import {useNotifications} from '../../hooks'
import {useUpdateUserEmail} from '../../services/user'
import Reauthenticate from './Reauthenticate'

interface EmailAddressFormProps {
  onStart?: () => void
  onSuccess: () => void
}

const EmailAddressForm = ({onStart, onSuccess}: EmailAddressFormProps) => {
  const auth = useAuth()
  const [updateEmail, updateEmailState] = useUpdateUserEmail()
  const notifications = useNotifications()
  const [email, setEmail] = useState<string | undefined>()
  const [reauthenticate, setReauthenticate] = useState(false)

  const submit = async () => {
    try {
      if (!email) throw new Error('Email is blank')
      onStart?.()
      const result = await updateEmail({email})
      if (!result)
        notifications.show({
          variant: 'error',
          action: 'update',
          entity: 'email address',
        })
    } catch (error) {
      notifications.show({
        variant: 'error',
        message: getErrorMessage(error),
      })
    }
  }

  if (reauthenticate)
    return (
      <Reauthenticate
        onSuccess={() => {
          setReauthenticate(false)
          submit()
        }}
      />
    )

  if (updateEmailState.value)
    return (
      <>
        <EmailSent email={auth.user?.email} />
        <Button fullWidth mt="md" variant="default" onClick={onSuccess}>
          Close
        </Button>
      </>
    )

  return (
    <EmailForm
      showHelperText
      loading={updateEmailState.isRunning}
      onSubmit={(submittedEmail) => {
        setEmail(submittedEmail)
        setReauthenticate(true)
      }}
    />
  )
}

export default EmailAddressForm
