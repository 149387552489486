import {forwardRef} from 'react'
import {Dropzone as UIDropzone, DropzoneProps as UIDropzoneProps} from 'ui/dropzone'
import {useNotifications} from '../../hooks'
import {IMAGE_MIME_TYPE} from '../../utils'
import classes from './Dropzone.module.css'

// 10MB
const MAX_MB_SIZE = 10

const mbToBytes = (mb: number) => mb * 1024 ** 2

export interface DropzoneProps extends UIDropzoneProps {
  unstyled?: boolean
  testID?: string
}

const Dropzone = forwardRef<HTMLDivElement, DropzoneProps>(
  ({children, unstyled, testID, ...rest}, ref) => {
    const notifications = useNotifications()

    const onReject: UIDropzoneProps['onReject'] = (files) => {
      files.forEach(({file, errors: [error]}) => {
        let message = `${error.message}. (${file.name}).`

        if (error.code === 'file-too-large') {
          message = `${file.name} is too large. Maximum size is ${MAX_MB_SIZE}MB.`
        }

        notifications.show({
          variant: 'error',
          message,
        })
      })
    }

    return (
      <UIDropzone
        ref={ref}
        p={unstyled ? 0 : rest.p}
        maxSize={mbToBytes(MAX_MB_SIZE)}
        accept={IMAGE_MIME_TYPE}
        onReject={onReject}
        classNames={
          unstyled
            ? {
                root: classes.root,
                inner: classes.inner,
              }
            : undefined
        }
        data-testid={testID}
        {...rest}
      >
        {children}
      </UIDropzone>
    )
  },
)

export default Dropzone
