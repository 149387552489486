import {Group, IconButton, Pagination as UIPagination} from 'ui/core'
import {usePagination} from 'react-instantsearch-hooks-web'
import {useBreakpoint} from 'core/hooks'

const Pagination = () => {
  const pagination = usePagination()
  const {isSm} = useBreakpoint()

  if (!pagination.canRefine) return null

  return isSm ? (
    <Group>
      <IconButton
        name="chevronLeft"
        title="Previous"
        onClick={() => pagination.refine(pagination.currentRefinement - 1)}
        disabled={pagination.isFirstPage}
      />
      <IconButton
        name="chevronRight"
        title="Next"
        onClick={() => pagination.refine(pagination.currentRefinement + 1)}
        disabled={pagination.isLastPage}
      />
    </Group>
  ) : (
    <UIPagination
      size="lg"
      siblings={1}
      boundaries={1}
      total={pagination.nbPages}
      value={pagination.currentRefinement + 1}
      onChange={(val) => pagination.refine(val - 1)}
    />
  )
}

export default Pagination
