import {useUpdateSellRequest} from '@resellam/sell-request'
import {useNotifications, useForm} from 'core/hooks'
import {SellRequest} from 'model'
import {Button, Textarea, ModalActions, Box, LoadingOverlay} from 'ui/core'
import {zodResolver} from 'ui/form'
import {z} from 'zod'

const schema = z.object({
  additionalInfo: z.string().trim().min(1, {message: 'Additional information is required'}),
})

interface AdditionalInfoFormProps {
  sellRequest: SellRequest
  onCancel: () => void
  onSuccess: (additionalInfo: string) => void
}

const AdditionalInfoForm = ({sellRequest, onCancel, onSuccess}: AdditionalInfoFormProps) => {
  const notifications = useNotifications()
  const [updateSellRequest, updateSellRequestState] = useUpdateSellRequest()

  const form = useForm({
    initialValues: {
      additionalInfo: sellRequest.additionalInfo || '',
    },
    validate: zodResolver(schema),
  })

  const submit = async ({additionalInfo}: typeof form.values) => {
    if (form.validate().hasErrors) return
    const result = await updateSellRequest({id: sellRequest.id, additionalInfo})
    notifications.show({
      variant: result ? 'success' : 'error',
      action: 'update',
      entity: 'sell request',
    })
    result && onSuccess(additionalInfo)
  }

  return (
    <Box component="form" style={{position: 'relative'}} onSubmit={form.onSubmit(submit)}>
      <LoadingOverlay visible={updateSellRequestState.isRunning} />
      <Textarea
        {...form.getInputProps('additionalInfo')}
        minRows={4}
        disabled={updateSellRequestState.isRunning}
        label="More information"
        placeholder="Please provide more information"
      />
      <ModalActions>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" loading={updateSellRequestState.isRunning} disabled={!form.isDirty()}>
          Save
        </Button>
      </ModalActions>
    </Box>
  )
}

export default AdditionalInfoForm
