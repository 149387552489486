import {Product, SellRequest} from 'model'
import {Stack, IconButton} from 'ui/core'
import {ProductDetailWrapper, DetailItem, SectionHeader} from 'core/components'
import {formatTestID, formatVariantValue} from 'core/utils'
import {useModals} from 'ui/modals'
import {useAuth} from '@resellam/auth'
import {EditSellRequestDetails} from './EditSellRequestDetails'
import {SellRequestDetailsFormProvider} from '../../contexts'

export interface SellRequestProductDetailProps {
  loading?: boolean
  product?: Product
  sellRequest?: Partial<SellRequest>
  testID?: string
  onEdit?: (data: SellRequest) => void
}

const SellRequestProductDetail = ({
  loading,
  product,
  sellRequest,
  onEdit,
  testID,
}: SellRequestProductDetailProps) => {
  const modals = useModals()
  const auth = useAuth()

  const openEditModal = () => {
    const id = modals.openModal({
      title: 'Edit Sell Request',
      children: sellRequest?.id && (
        <SellRequestDetailsFormProvider product={product} initialValues={sellRequest}>
          <EditSellRequestDetails
            product={product}
            sellRequest={sellRequest as Required<SellRequest>}
            onCancel={() => modals.closeModal(id)}
            onSave={(data) => {
              modals.closeModal(id)
              onEdit?.(data)
            }}
          />
        </SellRequestDetailsFormProvider>
      ),
    })
  }

  return (
    <div>
      <SectionHeader
        title="Details"
        action={
          auth.isAdmin &&
          onEdit && (
            <IconButton
              name="edit"
              title="Edit"
              onClick={openEditModal}
              testID={formatTestID(testID, 'edit')}
            />
          )
        }
      />
      <ProductDetailWrapper mt="xl" loading={loading} product={product} testID={testID}>
        <Stack>
          <DetailItem
            label="Device"
            value={product?.title}
            testID={formatTestID(testID, 'device')}
          />

          {sellRequest?.variants?.map((variant) => (
            <DetailItem
              key={variant.label}
              label={variant.label}
              value={formatVariantValue(variant.value)}
              testID={formatTestID(testID, variant.label)}
            />
          ))}

          {sellRequest?.imeiNumber && (
            <DetailItem
              label="IMEI"
              value={sellRequest?.imeiNumber}
              testID={formatTestID(testID, 'imei-number')}
            />
          )}

          {sellRequest?.serialNumber && (
            <DetailItem
              label="Serial number"
              value={sellRequest?.serialNumber}
              testID={formatTestID(testID, 'serial-number')}
            />
          )}

          {sellRequest?.batteryHealth && (
            <DetailItem
              label="Battery health"
              value={`${sellRequest?.batteryHealth}%`}
              testID={formatTestID(testID, 'battery-health')}
            />
          )}
        </Stack>
      </ProductDetailWrapper>
    </div>
  )
}

export default SellRequestProductDetail
