import {ProductCondition} from 'model'
import {Badge, BadgeProps} from 'ui/core'

export interface ConditionProps extends BadgeProps {
  condition: ProductCondition
  testID?: string
}

const Condition = ({condition, testID, ...rest}: ConditionProps) => (
  <Badge variant="outline" color="red" data-testid={testID} {...rest}>
    {condition}
  </Badge>
)

export default Condition
