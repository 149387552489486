import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {User} from 'model'
import {useMemo} from 'react'
import {getAuth} from '@resellam/firebase'
import {useSWRConfig} from 'swr'
import {PhoneAuthCredential, updatePhoneNumber} from 'firebase/auth'

type UpdateUserPhone = {
  phoneNumber: string
  credential: PhoneAuthCredential
}

export const useUpdateUserPhone = (): [
  (data: UpdateUserPhone) => Promise<boolean | null>,
  TaskState<boolean>,
] => {
  const {mutate} = useSWRConfig()
  const [taskFn, taskState] = useTask<boolean>()
  const run = ({credential, phoneNumber}: UpdateUserPhone) =>
    taskFn(async () => {
      const {currentUser} = getAuth()
      if (!currentUser?.uid) throw new Error('Cannot update unauthenticated User')
      await updatePhoneNumber(currentUser, credential)
      mutate(currentUser.uid, (val: User = {} as User) => ({...val, phoneNumber}))
      return true
    })
  return useMemo(() => [run, taskState], [taskState])
}
