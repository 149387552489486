import {Seo} from 'components/Seo'
import {siteMetadata} from 'core/utils'
import {Product} from 'model'
import {ProductJsonLd} from 'next-seo'

interface ProductSeoProps {
  title?: string
  path: string
  product: Product
}

const ProductSeo = ({product, title, path}: ProductSeoProps) => (
  <>
    <Seo
      title={title || product.title}
      description={product.description}
      canonical={`${siteMetadata.siteUrl}${path}`}
      openGraph={{
        url: `${siteMetadata.siteUrl}${path}`,
        title: title || product.title,
        images: [
          {
            url: `${siteMetadata.siteUrl}/api/og?title=${product.title}&image=${product.image}`,
            width: 1200,
            height: 630,
            alt: product.title,
          },
          {
            url: `${siteMetadata.siteUrl}/api/og?image=${product.image}&size=256&width=256&height=256`,
            width: 256,
            height: 256,
            alt: product.title,
          },
        ],
      }}
    />
    <ProductJsonLd
      productName={product.title || ''}
      images={
        product.images ? product.images.map(({url}) => url) : product.image ? [product.image] : []
      }
      description={product.description}
      brand={product.brand?.title}
      manufacturerName={product.brand?.title}
      manufacturerLogo={product.brand?.logo}
      offers={[
        {
          price: product.price,
          priceCurrency: 'NGN',
          itemCondition:
            product.condition === 'New' || !product.condition
              ? 'https://schema.org/NewCondition'
              : 'https://schema.org/UsedCondition',
          availability: product.inStock
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
          url: `${siteMetadata.siteUrl}${path}`,
          seller: {
            name: 'Resellam',
          },
        },
      ]}
    />
  </>
)

export default ProductSeo
