import {BRAND_FIELDS} from './brand'

export const PRODUCT_PART_FIELDS = `
  'id': _id,
  name,
  'image': image.asset -> url
`

export const PRODUCT_FIELDS = `
  'id': _id,
  'slug': slug.current,
  title,
  price,
  active,
  featured,
  condition,
  'image': image.asset -> url,
  'images': images[] {
    caption,
    "url": asset -> url,
  },
  'metas': metas[] {
    name,
    value,
  },
  'variants': variants[] {
    variantName,
    variantInputType,
     'variantValue': variantValue[] {
      key,
      value,
    }
  },
  inStock,
  'brand': brand -> {
    ${BRAND_FIELDS}
  },
  'category': category -> {
    'id': _id,
    'slug': slug.current,
    title,
    'image': image.asset -> url,
  },
  'discount': discount -> {
    'id': _id,
    amount,
    percentage,
    startDate,
    endDate,
  }
`
