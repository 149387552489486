import {
  query,
  Query,
  QueryConstraint,
  where as fsWhere,
  limit as fsLimit,
  orderBy as fsOrderBy,
  getDocs,
  endBefore,
  limitToLast,
  QueryDocumentSnapshot,
  snapshotEqual,
  startAfter,
} from 'firebase/firestore'
import {SellRequest} from 'model'
import useSWR from 'swr/immutable'
import {getCollectionRef, OrderByParam, WhereParam} from '@resellam/firebase'
import {useAuth} from '@resellam/auth'
import {useMemo, useRef} from 'react'
import {useComputedState} from '@resellam/hooks'
import {sellRequestConverter} from '../../utils'

type UseQuerySellRequestsProps = {where?: WhereParam[]; limit?: number; orderBy?: OrderByParam[]}

const getConstrainst = ({where, orderBy}: UseQuerySellRequestsProps = {}) => {
  const constraints: QueryConstraint[] = []
  if (where) {
    where.forEach(({field, operator, value}) => constraints.push(fsWhere(field, operator, value)))
  }
  if (orderBy) {
    orderBy.forEach(({field, direction}) => constraints.push(fsOrderBy(field, direction)))
  }
  return constraints
}
const getBaseQuery = ({where, orderBy}: UseQuerySellRequestsProps = {}) =>
  query(getCollectionRef('sell-requests'), ...getConstrainst({where, orderBy})).withConverter(
    sellRequestConverter,
  )

const useQuerySellRequests = ({where, orderBy, limit = 10}: UseQuerySellRequestsProps = {}) => {
  const {user} = useAuth()

  const nextQueryRef = useRef<Query>()
  const prevQueryRef = useRef<Query>()
  const [currentQuery, setCurrentQuery] = useComputedState(
    () => query(getBaseQuery({where, orderBy}), fsLimit(limit)),
    [where, orderBy, limit],
  )

  const lastResult = useSWR<QueryDocumentSnapshot>(
    user ? ['last-sell-request', where, orderBy] : null,
    async () => (await getDocs(query(getBaseQuery({where, orderBy}), limitToLast(1)))).docs[0],
  )

  const firstResult = useSWR<QueryDocumentSnapshot>(
    user ? ['first-sell-request', where, orderBy] : null,
    async () => (await getDocs(query(getBaseQuery({where, orderBy}), fsLimit(1)))).docs[0],
  )

  const result = useSWR<SellRequest[]>(
    user ? [currentQuery, firstResult.data, lastResult.data] : null,
    async () => {
      const snap = await getDocs(currentQuery)
      const {docs} = snap
      const baseQuery = getBaseQuery({where, orderBy})

      const firstDoc = docs[0]
      const lastDoc = docs[docs.length - 1]
      const hasDocs = !!docs.length

      const isEnd = lastDoc && lastResult.data && snapshotEqual(lastDoc, lastResult.data)
      const isStart = firstDoc && firstResult.data && snapshotEqual(firstDoc, firstResult.data)

      nextQueryRef.current =
        hasDocs && !isEnd ? query(baseQuery, startAfter(lastDoc), fsLimit(limit)) : undefined

      prevQueryRef.current =
        hasDocs && !isStart
          ? query(baseQuery, endBefore(firstDoc), limitToLast(limit + 1))
          : undefined

      return docs.map((document) => document.data())
    },
  )

  return useMemo(
    () => ({
      ...result,
      prev: prevQueryRef.current ? () => setCurrentQuery(prevQueryRef.current!) : undefined,
      next: nextQueryRef.current ? () => setCurrentQuery(nextQueryRef.current!) : undefined,
    }),
    [result],
  )
}

export default useQuerySellRequests
