import {parsePhoneNumber, PhoneNumberFormat} from 'awesome-phonenumber'
import {useMemo} from 'react'

const REGION_CODE = 'NG'

const usePhoneNumber = (phoneNumber?: string, format?: PhoneNumberFormat) =>
  useMemo(
    () => (phoneNumber ? parsePhoneNumber(phoneNumber, REGION_CODE).getNumber(format) : undefined),
    [phoneNumber],
  )

export default usePhoneNumber
