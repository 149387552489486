import {DocumentSnapshot, serverTimestamp, SnapshotOptions, Timestamp} from 'firebase/firestore'
import {SellRequest} from 'model'
import {getAuth} from '@resellam/firebase'

const toJSDate = (ts?: any) => ts?.toDate()
const fromJSDate = (date?: any) => (date ? Timestamp.fromDate(date) : null)

export const sellRequestConverter = {
  toFirestore: ({
    id,
    pickup,
    offer,
    status,
    createdAt,
    updatedAt,
    createdById,
    ...sellRequest
  }: SellRequest) => ({
    ...sellRequest,
    createdAt: id ? fromJSDate(createdAt) : serverTimestamp(),
    updatedAt: id ? serverTimestamp() : fromJSDate(updatedAt),
    createdById: !id ? getAuth().currentUser?.uid : createdById,
    pickup: pickup
      ? {
          ...pickup,
          date: fromJSDate(pickup.date),
        }
      : null,
    offer: offer
      ? {
          ...offer,
          createdAt: fromJSDate(offer.createdAt),
          expiresAt: fromJSDate(offer.expiresAt),
        }
      : null,
    status: status
      ? {
          ...status,
          createdAt: fromJSDate(status.createdAt),
        }
      : null,
  }),
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
    const {createdAt, updatedAt, pickup, status, offer, ...data} = snapshot.data(options) as any
    return {
      ...data,
      id: snapshot.id,
      createdAt: toJSDate(createdAt),
      updatedAt: toJSDate(updatedAt),
      pickup: pickup
        ? {
            ...pickup,
            date: toJSDate(pickup.date),
          }
        : null,
      offer: offer
        ? {
            ...offer,
            createdAt: toJSDate(offer.createdAt),
            expiresAt: toJSDate(offer.expiresAt),
          }
        : null,
      status: status
        ? {
            ...status,
            createdAt: toJSDate(status.createdAt),
          }
        : null,
    }
  },
}
