import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {useMemo} from 'react'
import {getAuth, getDocumentRef} from '@resellam/firebase'
import {serverTimestamp, updateDoc} from 'firebase/firestore'

export const useDeleteUser = (): [
  (reason?: string) => Promise<boolean | null>,
  TaskState<boolean>,
] => {
  const [taskFn, taskState] = useTask<boolean>()
  const run = (reason?: string) =>
    taskFn(async () => {
      const {currentUser} = getAuth()
      const userId = currentUser?.uid
      if (!userId) throw new Error('Cannot delete unauthenticated User')
      await updateDoc(getDocumentRef('users', userId), {
        deleteReason: reason,
        deletedAt: serverTimestamp(),
      })
      await currentUser.delete()
      return true
    })
  return useMemo(() => [run, taskState], [taskState])
}
