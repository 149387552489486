import {Box, BoxProps, Button, ButtonProps} from 'ui/core'
import {AgentInfo, Alert} from 'components'
import {formatTestID} from 'core/utils'

export interface SellRequestDropoffProps extends BoxProps {
  testID?: string
  confirm?: ButtonProps
}

const SellRequestDropoff = ({testID, confirm, ...rest}: SellRequestDropoffProps) => (
  <Box {...rest} data-testid={testID}>
    <AgentInfo testID={formatTestID(testID, 'agent-info')} />
    <Alert
      mt="md"
      alertStyle="warning"
      title="Requires Government ID"
      testID={formatTestID(testID, 'alert')}
    >
      You&rsquo;ll be asked to provide your Driver&apos;s license, Voters card, or International
      passport.
    </Alert>
    {confirm && (
      <Button mt="md" fullWidth data-testid={formatTestID(testID, 'confirm-button')} {...confirm} />
    )}
  </Box>
)

export default SellRequestDropoff
