import {Flex, Skeleton} from 'ui/core'
import {Image} from 'core/components'

export interface SellRequestOfferDetailsLoadingProps {
  testID?: string
}

const SellRequestOfferDetailsLoading = ({testID}: SellRequestOfferDetailsLoadingProps) => (
  <Flex direction="column" align="center" data-testid={testID}>
    <Image alt="" height={280} width={280} isLoading radius="md" />
    <Flex mt="sm" gap="xs" direction="column" align="center">
      <Skeleton height={20} width="100px" radius="md" />
      <Skeleton height={20} width="140px" radius="md" />
      <Skeleton height={28} width="120px" radius="md" />
      <Skeleton height={20} width="100px" radius="md" />
    </Flex>
  </Flex>
)

export default SellRequestOfferDetailsLoading
