import {AlgoliaProductHit, clickedObjectIDsAfterSearch, clickedProductIDs} from 'lib/algolia'
import {ProductCard} from 'components'
import analytics from 'lib/analytics'
import {useShop} from 'lib/shop'

interface RelatedItemProps {
  item: AlgoliaProductHit
  testID?: string
}

const RelatedItem = ({item, testID}: RelatedItemProps) => {
  const shop = useShop()
  return (
    <ProductCard
      testID={testID}
      href={`/shop/${item.slug}`}
      product={{...item, id: item.objectID, variants: []}}
      onClick={() => {
        if (shop.queryID) {
          clickedObjectIDsAfterSearch(
            [item.objectID],
            'pdpRelatedProductClickedAfterSearch',
            [0],
            shop.queryID,
          )
        } else {
          clickedProductIDs([item.objectID], 'pdpRelatedProductClicked')
        }
        analytics.track('shop_view_related_product', {
          category: 'engagement',
          product_name: item.title,
          product_brand_name: item.brand.title,
          product_category_name: item.category.title,
        })
      }}
    />
  )
}

export default RelatedItem
