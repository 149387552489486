import {formatTestID} from 'core/utils'
import {Collapse, SegmentedControl, SegmentedControlProps} from 'ui/core'
import {ReactNode} from 'react'

interface SegmentsProps extends Omit<SegmentedControlProps, 'data'> {
  data: {value: string; label: ReactNode; children: ReactNode}[]
  testID?: string
}

const Segments = ({value, data, testID, ...rest}: SegmentsProps) => (
  <div data-testid={testID}>
    <SegmentedControl
      mb="md"
      {...rest}
      data={data}
      value={value}
      data-testid={formatTestID(testID, 'segmented-control')}
    />
    {data.map((item) => (
      <Collapse
        key={item.value}
        data-testid={formatTestID(testID, 'collapse', item.value)}
        in={item.value === value}
      >
        {item.children}
      </Collapse>
    ))}
  </div>
)

export default Segments
