import {Card, Text} from 'ui/core'
import {SellRequestRejectionReason, useSellRequest} from '@resellam/sell-request'
import {DetailItem, Money, SectionHeader} from 'core/components'
import {SellRequestAccepted} from './SellRequestAccepted'
import {SellRequestExpired} from './SellRequestExpired'

const SellRequestStatus = () => {
  const {sellRequest} = useSellRequest()
  const {status: statusObject, product} = sellRequest || {}
  const status = statusObject?.name

  const getTitle = () => {
    switch (status) {
      case 'SUBMITTED':
        return 'Your sell request was received!'
      case 'REJECTED':
        return 'Your sell request was rejected!'
      case 'CANCELLED':
        return 'Your sell request was cancelled!'
      case 'COMPLETED':
        return 'Yay! your device has been sold!'
      default:
        return product?.title || ''
    }
  }

  const getContent = () => {
    if (status === 'REJECTED') return <SellRequestRejectionReason sellRequest={sellRequest} />

    if (status === 'CANCELLED')
      return <DetailItem label="Cancellation reason" value={sellRequest?.status?.notes} />

    if (status === 'COMPLETED')
      return (
        <DetailItem
          label="Price"
          value={sellRequest?.offer?.price ? <Money value={sellRequest?.offer?.price} /> : null}
        />
      )

    if (status === 'SUBMITTED')
      return (
        <Text size="md" wordBreak="break-word">
          We&apos;ll let you know as soon as we review your request.
        </Text>
      )

    return <></>
  }

  if (status === 'ACCEPTED') return <SellRequestAccepted />
  if (status === 'EXPIRED') return <SellRequestExpired />

  return (
    <Card>
      <SectionHeader title={getTitle()} />
      {getContent()}
    </Card>
  )
}

export default SellRequestStatus
