import {Enum} from '../Enum'

export const SELL_REQUEST_STATUSES = [
  'SUBMITTED',
  'ACCEPTED',
  'REJECTED',
  'COMPLETED',
  'CANCELLED',
  'EXPIRED',
] as const

type SellRequestStatusTuple = typeof SELL_REQUEST_STATUSES

export type SellRequestStatusName = SellRequestStatusTuple[number]

export interface SellRequestStatus {
  name?: SellRequestStatusName
  createdAt?: Date
  createdBy?: string
  notes?: string
  reason?: Enum
}

export default SellRequestStatus
