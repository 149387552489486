import {forwardRef} from 'react'
import {createPolymorphicComponent, Text, TextProps} from 'ui/core'
import {formatMoney} from '../../utils'

export interface MoneyProps extends TextProps {
  value: number
}

const Money = forwardRef<HTMLDivElement, MoneyProps>(({value, ...rest}, ref) => (
  <Text {...rest} ref={ref}>
    {formatMoney(value)}
  </Text>
))

export default createPolymorphicComponent<'div', MoneyProps>(Money)
