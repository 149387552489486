import {useSellRequest} from '@resellam/sell-request'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import {useMemo} from 'react'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

export const formatDeliveryDate = (date: Date | dayjs.Dayjs) => {
  const dayjsDate = dayjs(date)
  return dayjsDate.isToday()
    ? 'Today'
    : dayjsDate.isTomorrow()
    ? 'Tomorrow'
    : dayjsDate.format('dddd (MMM D)')
}

export const useSellRequestFrontImage = () => {
  const {sellRequest} = useSellRequest()
  return useMemo(
    () =>
      sellRequest?.images?.find((image) => image.side === 'FRONT')?.fullPath ||
      sellRequest?.images?.[0]?.fullPath,
    [sellRequest],
  )
}
