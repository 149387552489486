import {Address} from 'model'
import {BoxProps} from 'ui/core'
import {AddressLink, DateTime, DetailList} from 'core/components'
import {useMemo} from 'react'
import {useGetDocument} from '@resellam/firebase'
import {useSellRequest} from '../../contexts/SellRequestContext'

interface SellRequestPickupInfoProps extends BoxProps {
  address?: Address
  testID?: string
}

const SellRequestPickupInfo = ({
  testID,
  address: _address,
  ...rest
}: SellRequestPickupInfoProps) => {
  const {sellRequest} = useSellRequest()
  const {data: address = _address} = useGetDocument<Address>({
    collection: 'addresses',
    id: _address ? undefined : sellRequest?.pickup?.addressId,
  })
  const date = sellRequest?.pickup?.date

  const items = useMemo(
    () => [
      ...(date
        ? [
            {
              label: 'Pickup Date',
              value: <DateTime withIcon value={date} format="default" variant="date" />,
            },
          ]
        : []),
      ...(address?.googlePlaceId
        ? [
            {
              label: 'Pickup Address',
              value: <AddressLink placeName={address.street} placeId={address.googlePlaceId} />,
            },
          ]
        : []),
    ],
    [date, address],
  )
  return <DetailList {...rest} items={items} />
}

export default SellRequestPickupInfo
