import {Image} from 'core/components'
import {Product} from 'model'

interface ProductImageProps {
  product: Product
  size?: number
}

const ProductImage = ({product, size = 200}: ProductImageProps) => (
  <Image height={size} width={size} src={product.image} alt={product.title ?? ''} />
)

export default ProductImage
