import {logger} from '@resellam/logger'

export const createRequest =
  ({
    baseURL,
    headers,
    transformResponse,
    transformRequest,
  }: {
    baseURL: string
    headers: Record<string, string>
    transformResponse?: (data: Record<string, any>) => any
    transformRequest?: (
      info: RequestInfo,
      init?: RequestInit,
    ) => [RequestInfo, RequestInit | undefined]
  }) =>
  async <T = Record<string, any>>(config: {
    url: string
    method?: string
    body?: Record<string, any>
    transformResponse?: (response: Record<string, any>) => T
  }) => {
    try {
      let requestInfo: RequestInfo = `${baseURL}${config.url}`
      let requestInit: RequestInit | undefined = {
        method: config.method || 'GET',
        body: config.body ? JSON.stringify(config.body) : undefined,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      }

      if (transformRequest) {
        ;[requestInfo, requestInit] = transformRequest(requestInfo, requestInit)
      }

      const res = await fetch(requestInfo, requestInit)
      const json = await res.json()
      const transform = config.transformResponse || transformResponse
      return transform ? transform(json) : (json as T)
    } catch (error: any) {
      const message = error.message || 'Error'
      logger.error(error, message)
      throw error
    }
  }
