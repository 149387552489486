import {ReactNode} from 'react'
import {BoxProps, SimpleGrid} from 'ui/core'
import {Product} from 'model'
import {Image} from '../Image'
import classes from './ProductDetailWrapper.module.css'

export interface ProductDetailWrapperProps extends BoxProps {
  children?: ReactNode
  product?: Product
  imageSize?: number
  loading?: boolean
  testID?: string
}

const ProductDetailWrapper = ({
  children,
  product,
  loading,
  imageSize,
  testID,
}: ProductDetailWrapperProps) => (
  <SimpleGrid
    spacing="md"
    cols={{base: 1, sm: children ? 2 : 1}}
    style={{alignItems: 'center'}}
    data-testid={testID}
  >
    <div className={classes.photo}>
      <div className={imageSize ? undefined : classes.image}>
        <Image
          priority
          src={product?.image}
          alt={product?.title || ''}
          height={imageSize}
          width={imageSize}
          fill={!imageSize}
          isLoading={loading}
        />
      </div>
    </div>
    {children}
  </SimpleGrid>
)

export default ProductDetailWrapper
