import {Stack, Text, Button, Icon, StackProps} from 'ui/core'
import {useCart} from 'lib/cart'
import Link from 'next/link'

const EmptyCart = (props: StackProps) => {
  const cart = useCart()

  return (
    <Stack align="center" justify="center" {...props}>
      <Icon name="shoppingCart" size="xl" />
      <Text>Cart is empty</Text>
      <Button component={Link} href="/shop" variant="subtle" onClick={() => cart.close()}>
        Start Shopping
      </Button>
    </Stack>
  )
}

export default EmptyCart
