import {createContext} from 'react'
import {User} from 'model'

export interface AuthContextValue {
  user?: User
  loading?: boolean
  isAdmin?: boolean
  signout: () => Promise<void>
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined)

export default AuthContext
