import {z} from 'zod'
import {zodResolver} from 'ui/form'
import {useForm} from 'core/hooks'
import {useCheckout} from 'lib/checkout'
import {PaymentData} from 'lib/checkout/types'
import {StepHeader, StepNavButtons, PaymentMethods, Segments} from 'components'
import {PaymentMethod, PaymentType} from 'model'
import {Text} from 'ui/core'
import {useMemo} from 'react'
import {formatTestID} from 'ui/utils'

const schema = z.object({
  type: z.nativeEnum(PaymentType),
  method: z.nativeEnum(PaymentMethod, {required_error: 'Select payment method'}),
})

interface CheckoutPaymentProps {
  testID?: string
}

const CheckoutPayment = ({testID}: CheckoutPaymentProps) => {
  const checkout = useCheckout()
  const form = useForm<PaymentData & {type: PaymentType}>({
    initialValues: {
      type: PaymentType.NOW,
      method: checkout.data.payment?.method,
    },
    validate: zodResolver(schema),
  })

  const next = () => {
    if (form.validate().hasErrors) return
    checkout.nextStep('payment', schema.parse(form.values))
  }

  const back = () => {
    checkout.prevStep('payment', form.values)
  }

  const segmentsData = useMemo(
    () => [
      {
        label: 'Pay Now',
        value: PaymentType.NOW,
        children: (
          <div>
            <Text>
              Pay instantly and securely with your credit/debit card or via Bank transfer.
            </Text>
            <PaymentMethods
              {...form.getInputProps('method')}
              mt="sm"
              error={undefined}
              height={80}
              methods={[PaymentMethod.CARD, PaymentMethod.BANK_TRANSFER]}
            />
          </div>
        ),
      },
      {
        label: 'Pay On Delivery',
        value: PaymentType.ON_DELIVERY,
        children: (
          <div>
            <Text>
              Please note that you will have to make payment before opening the package. Once the
              seal is broken, the item can only be returned if it is damaged, defective or has
              missing parts.
            </Text>
            <PaymentMethods
              mt="sm"
              value={PaymentMethod.BANK_TRANSFER}
              height={80}
              methods={[PaymentMethod.BANK_TRANSFER]}
            />
          </div>
        ),
      },
    ],
    [form],
  )

  return (
    <>
      <StepHeader title="Payment" testID={formatTestID(testID, 'title')} />

      <Segments
        fullWidth
        {...form.getInputProps('type')}
        onChange={(val) => {
          if (val === PaymentType.NOW) form.setFieldValue('method', PaymentMethod.CARD)
          if (val === PaymentType.ON_DELIVERY)
            form.setFieldValue('method', PaymentMethod.BANK_TRANSFER)
          form.setFieldValue('type', val as PaymentType)
        }}
        data={segmentsData}
        testID={formatTestID(testID, 'segments')}
      />

      <StepNavButtons
        back={{onClick: back}}
        next={{onClick: next, children: 'Continue', loading: checkout.isCreatingOrder}}
      />
    </>
  )
}

export default CheckoutPayment
