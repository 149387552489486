import {forwardRef, MouseEventHandler} from 'react'
import {Button, ButtonProps} from '@mantine/core'
import GoogleIcon from './GoogleIcon'
import FacebookIcon from './FacebookIcon'
import classes from './SocialButton.module.css'

export interface SocialButtonProps extends ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const SocialButton = forwardRef<HTMLButtonElement, SocialButtonProps>(({...rest}, ref) => (
  <Button
    color="gray"
    variant="outline"
    {...rest}
    ref={ref}
    classNames={{
      section: classes.leftSection,
    }}
  />
))

export const GoogleButton = ({children, ...props}: SocialButtonProps) => (
  <SocialButton
    leftSection={<GoogleIcon width={20} height={20} />}
    variant="default"
    color="gray"
    {...props}
  >
    {children || 'Google'}
  </SocialButton>
)

export const FacebookButton = ({children, ...props}: SocialButtonProps) => (
  <SocialButton
    leftSection={<FacebookIcon width={20} height={20} />}
    className={classes.facebook}
    {...props}
  >
    {children || 'Facebook'}
  </SocialButton>
)

export default SocialButton
