import {Card, Group, Text} from 'ui/core'
import {Notification} from 'model'
import Link from 'next/link'
import dayjs from 'dayjs'
import {useReadNotification} from 'lib/notification'

export interface ActivityCardProps {
  notification: Notification
  onClick?: () => void
}

const ActivityCard = ({notification, onClick}: ActivityCardProps) => {
  const [readNotification] = useReadNotification()

  const handleClick = () => {
    readNotification({notificationId: notification.id})
    onClick?.()
  }

  const resourceId = notification.resourceId || notification.data?.sellRequestId
  const resourceType =
    notification.resourceType || (notification.data?.sellRequestId ? 'sell-requests' : undefined)

  return (
    <Card component={Link} href={`/${resourceType}/${resourceId}`} onClick={() => handleClick()}>
      <Group wrap="nowrap" gap="xs" justify="space-between">
        <Text>{notification.body}</Text>
        <Text lineClamp={2} size="sm" color="gray">
          {dayjs(notification.createdAt).fromNow()}
        </Text>
      </Group>
    </Card>
  )
}

export default ActivityCard
