import {useEffect} from 'react'
import {
  TextInput,
  Button,
  Stack,
  ModalActions,
  Loader,
  Center,
  Text,
  Group,
  NativeSelect,
  LoadingOverlay,
  Box,
} from 'ui/core'
import {useForm, zodResolver} from 'ui/form'
import {useCreateBankAccount} from 'lib/bank-account'
import {Bank, BankAccount} from 'model'
import {useApi, useApiMutation, useNotifications} from 'core/hooks'
import {BankAccountResolve} from 'lib/paystack'
import {z} from 'zod'
import analytics from 'lib/analytics'

const schema = z.object({
  bankCode: z.string({required_error: 'Select a bank'}).min(1),
  accountNumber: z
    .string({required_error: 'Enter account number'})
    .length(10, {message: 'Enter a 10 digit account number'}),
})

interface BankAccountFormProps {
  initalData?: FormData
  onCancel: () => void
  onSave: (bankAccount: BankAccount) => void
}

const BankAccountForm = ({onCancel, onSave}: BankAccountFormProps) => {
  const notifications = useNotifications()
  const {data, isLoading} = useApi<{data?: Bank[]}>('/banks')
  const [resolveBankAccount, resolveBankAccountState] = useApiMutation<{
    data?: BankAccountResolve
  }>()
  const [createBankAccount, createBankAccountState] = useCreateBankAccount()

  const banks = data?.data || []
  const resolvedBankAccount = resolveBankAccountState?.value?.data

  const form = useForm({
    initialValues: {
      bankCode: '',
      accountNumber: '',
    },
    validate: zodResolver(schema),
  })

  useEffect(() => {
    const {bankCode, accountNumber} = form.values
    if (form.validate().hasErrors || !bankCode) return
    resolveBankAccount({
      url: `/banks/resolve?account_number=${accountNumber}&bank_code=${bankCode}`,
    })
  }, [form.values.bankCode, form.values.accountNumber])

  const submit = async (values: typeof form.values) => {
    if (!resolvedBankAccount) return

    analytics.track('add_payout_info', {
      category: 'engagement',
      type: 'bank_account',
    })

    const bank = banks.find(({code}) => code === values.bankCode)
    const {accountNumber, accountName} = resolvedBankAccount
    if (form.validate().hasErrors || !bank) return
    const bankAccount = await createBankAccount({
      bank: {
        type: bank.type,
        longcode: bank.longcode,
        currency: bank.currency,
        country: bank.country,
        code: bank.code,
        name: bank.name,
        id: bank.id,
      },
      accountNumber,
      accountName,
    })
    notifications.show({
      variant: bankAccount ? 'success' : 'error',
      action: 'create',
      entity: 'bank account',
    })
    if (bankAccount) {
      onSave(bankAccount)
    }
  }

  return (
    <Box component="form" style={{position: 'relative'}} onSubmit={form.onSubmit(submit)}>
      <Stack>
        <NativeSelect
          label="Bank"
          placeholder="Select bank"
          data={banks.map((bank) => ({
            label: bank.name,
            value: bank.code,
          }))}
          value={form.values.bankCode}
          onChange={({currentTarget}) => form.setFieldValue('bankCode', currentTarget.value)}
        />
        <TextInput
          type="number"
          placeholder="Account number"
          label="Account number"
          {...form.getInputProps('accountNumber', {withError: false})}
        />
        {resolveBankAccountState.isRunning && (
          <Group justify="center">
            <Text>Fetching account information</Text>
            <Loader type="dots" />
          </Group>
        )}
        {resolveBankAccountState.isSuccess && !resolvedBankAccount && (
          <Center>
            <Text color="red">Could not resolve account information</Text>
          </Center>
        )}
        {resolveBankAccountState.isSuccess && resolvedBankAccount && (
          <TextInput
            disabled
            variant="filled"
            label="Account name"
            value={resolvedBankAccount.accountName}
          />
        )}
      </Stack>
      <ModalActions>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" disabled={!resolvedBankAccount?.accountName}>
          Save
        </Button>
      </ModalActions>
      <LoadingOverlay visible={isLoading || createBankAccountState.isRunning} />
    </Box>
  )
}

export default BankAccountForm
