import {Product} from 'model'
import {useRelatedProducts} from '@algolia/recommend-react'
import {AlgoliaProductHit, PRODUCTS_INDEX, recommendClient} from 'lib/algolia'
import {RowCarousel} from 'components'
import {SectionHeader} from 'core/components'
import {formatTestID} from 'core/utils'
import {RelatedItem} from './RelatedItem'

interface RelatedProductsProps {
  product: Product
  testID?: string
}

const RelatedProducts = ({product, testID}: RelatedProductsProps) => {
  const {recommendations} = useRelatedProducts<AlgoliaProductHit>({
    recommendClient,
    indexName: PRODUCTS_INDEX,
    objectIDs: [product.id],
    maxRecommendations: 10,
    queryParameters: {
      filters: `inStock:true`,
      numericFilters: `price>0`,
      facetFilters: [
        `brand.title:${product.brand?.title}`,
        `category.title:${product.category?.title}`,
      ],
    },
    fallbackParameters: {
      filters: `inStock:true`,
      numericFilters: 'price>0',
      facetFilters: [`category.title:${product.category?.title}`],
    },
  })

  if (!recommendations.length) return null

  return (
    <div data-testid={testID}>
      <SectionHeader title="Related Products" testID={formatTestID(testID, 'header')} />
      <RowCarousel
        key={product.id}
        items={recommendations}
        testID={formatTestID(testID, 'carousel')}
      >
        {(item) => (
          <RelatedItem item={item} testID={formatTestID(testID, `related-item-${item.objectID}`)} />
        )}
      </RowCarousel>
    </div>
  )
}

export default RelatedProducts
