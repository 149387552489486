import {useContext} from 'react'
import {PopupContext} from '../../contexts/PopupContext'

const usePopup = () => {
  const context = useContext(PopupContext)
  if (context === undefined) {
    throw new Error('usePopup must be used within a PopupContext')
  }
  return context
}

export default usePopup
