import {useForm as useUIForm} from 'ui/form'
import {Icon} from 'ui/core'
import {showNotification, hideNotification} from 'ui/notifications'
import {useRef} from 'react'

const randomId = () => `mantine-${Math.random().toString(36).slice(2, 11)}`

export const handleFormError = (errors: Record<string, any>) => {
  Object.values(errors).forEach((error) => {
    showNotification({
      message: error,
      color: 'red',
      icon: <Icon name="alertCircle" />,
    })
  })
}

const useForm: typeof useUIForm = (props) => {
  const form = useUIForm(props)
  const errorNotificationIDs = useRef<Set<string>>(new Set())

  const validate = () => {
    const result = form.validate()

    errorNotificationIDs.current.forEach((id) => hideNotification(id))
    errorNotificationIDs.current.clear()

    if (result.hasErrors) {
      const id = randomId()
      errorNotificationIDs.current.add(id)
      showNotification({
        id,
        message: Object.values(result.errors)[0],
        color: 'red',
        icon: <Icon name="alertCircle" />,
      })
    }
    return result
  }

  return {...form, validate}
}

export default useForm
