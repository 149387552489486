import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import {getAuth} from '@resellam/firebase'
import {logger} from '@resellam/logger'

export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider()
    return await signInWithPopup(getAuth(), provider)
  } catch (error: any) {
    logger.error(error)
    throw error
  }
}
