import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import {siteMetadata} from 'core/utils'
import {Product} from 'model'

type EventName = 'checkout_progress' | 'purchase' | 'set_checkout_option' | 'remove_from_cart'

const analytics = Analytics({
  app: siteMetadata.name,
  version: '1.0.0',
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID as string],
    }),
  ],
})

interface TrackProductsOptions {
  [key: string]: any
  productsListName?: 'Search Results' | 'Deals'
  products?: (Product & {
    variant?: string
    quantity?: number
  })[]
}

export const trackEvent = (eventName: EventName, payload: any) =>
  analytics.track(eventName, payload)

export const trackProducts = (
  eventName: EventName | string,
  {products, productsListName = 'Search Results', ...rest}: TrackProductsOptions,
) =>
  analytics.track(eventName, {
    ...rest,
    items: products?.map((product, index) => ({
      id: product?.id,
      name: product?.title,
      list_name: productsListName,
      brand: product?.brand?.title,
      category: product?.category?.title,
      variant: product.variant,
      list_position: index + 1,
      quantity: product.quantity || 1,
      price: product?.price,
    })),
  })

export default analytics
