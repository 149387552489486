import {useSWR} from '@resellam/hooks'
import {getSanityClient} from '../../config/client'
import {Query, buildQuery, DocumentType} from '../../utils/helpers'

const countQuery = (type: DocumentType, query?: Query) => {
  const {where} = buildQuery(query)
  return `count(*[_type == "${type}"${where}])`
}

export const countDocuments = (type: DocumentType, query?: Query): Promise<number> =>
  getSanityClient().fetch<number>(countQuery(type, query))

export const useCountDocuments = (type: DocumentType, query?: Query) =>
  useSWR<number>(['count', type], () => countDocuments(type, query))
