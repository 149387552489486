import {Chip, ChipProps, Tooltip, Box} from 'ui/core'

const SIZE = 42

interface ColorChipProps extends Omit<ChipProps, 'value' | 'children'> {
  value: string
}

const ColorChip = ({value, ...rest}: ColorChipProps) => {
  const [color, label] = value.split('||')
  return (
    <Tooltip withArrow position="top" label={label}>
      <div>
        <Chip
          {...rest}
          variant="outline"
          value={value}
          styles={(theme) => ({
            label: {
              height: SIZE,
              width: SIZE,
              padding: 0,
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&[data-variant="outline"]': {
                backgroundColor: theme.white,
                borderColor: 'transparent',
              },
              '&[data-variant="outline"]:hover:not([data-checked])': {
                borderColor: theme.colors.gray[4],
                borderWidth: 1,
              },
              '&[data-checked]': {
                borderWidth: 1,
              },
            },
            iconWrapper: {display: 'none'},
          })}
        >
          <Box
            style={(theme) => ({
              borderColor: theme.colors.gray[4],
              borderWidth: 1,
              borderStyle: 'solid',
              backgroundColor: color,
              height: SIZE - 8,
              width: SIZE - 8,
              borderRadius: '50%',
              flexShrink: 0,
            })}
          />
        </Chip>
      </div>
    </Tooltip>
  )
}

export default ColorChip
