import {TaskState} from '@resellam/hooks'
import {useApiMutation} from 'core/hooks'
import {calculateFees} from 'helpers/paystack'
import {PickupEstimate} from './types'

interface UsePickupEstimateLocation {
  id: string
  name: string
}

export interface UsePickupEstimateProps {
  pickupLocation: UsePickupEstimateLocation
  dropoffLocation: UsePickupEstimateLocation
}

export interface UsePickupEstimateResult {
  data?: PickupEstimate
}

const computeResult = (result: UsePickupEstimateResult | null) =>
  result?.data
    ? {
        ...result,
        data: {
          ...result.data,
          geoId: result.data.geoId,
          estimate: result.data.estimate + calculateFees(result.data.estimate),
        },
      }
    : result

export const usePickupEstimate = (): [
  (data: UsePickupEstimateProps) => Promise<UsePickupEstimateResult | null>,
  TaskState<UsePickupEstimateResult>,
] => {
  const [getPickupEstimate, getPickupEstimateState] = useApiMutation<UsePickupEstimateResult>()

  const estimate = async (data: UsePickupEstimateProps) => {
    const params = new URLSearchParams()
    params.set('pickup_location_id', data.pickupLocation.id)
    params.set('pickup_location_name', data.pickupLocation.name)
    params.set('dropoff_location_id', data.dropoffLocation.id)
    params.set('dropoff_location_name', data.dropoffLocation.name)

    const result = await getPickupEstimate({
      url: `/pickup/estimate?${params.toString()}`,
    })

    return computeResult(result)
  }

  return [estimate, {...getPickupEstimateState, value: computeResult(getPickupEstimateState.value)}]
}
