import {Card, Group, Icon, UnstyledButton} from 'ui/core'
import {ReactNode} from 'react'
import cx from 'clsx'
import classes from './SelectableCard.module.css'

export interface SelectableCardProps {
  selected?: boolean
  onClick?: () => void
  children: ReactNode
  testID?: string
}

const SelectableCard = ({children, selected, onClick, testID}: SelectableCardProps) => (
  <Card<any>
    component={selected ? 'div' : UnstyledButton}
    className={cx(classes.root, selected && classes.selected)}
    onClick={onClick}
    data-testid={testID}
  >
    <Group wrap="nowrap" justify="space-between" align="center">
      {children}
      {selected && <Icon name="circleCheck" color="blue" size="xl" />}
    </Group>
  </Card>
)

export default SelectableCard
