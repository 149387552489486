import {Select, Group, IconButton} from 'ui/core'

export interface PaginationProps {
  size: number
  onChange: (size: number) => void
  onNext?: () => void
  onPrev?: () => void
}

const Pagination = ({size = 5, onChange, onNext, onPrev}: PaginationProps) => (
  <Group justify="right" mt="xl">
    <Select
      label="Show"
      value={`${size}`}
      data={['10', '20', '50']}
      styles={{
        root: {display: 'flex', alignItems: 'center'},
        wrapper: {width: '80px', marginLeft: '8px'},
        label: {marginBottom: '0px'},
      }}
      comboboxProps={{position: 'top'}}
      onChange={(val) => (val ? onChange(parseInt(val, 10)) : null)}
    />
    <Group>
      <IconButton name="chevronLeft" title="Previous" onClick={onPrev} disabled={!onPrev} />
      <IconButton name="chevronRight" title="Next" onClick={onNext} disabled={!onNext} />
    </Group>
  </Group>
)

export default Pagination
