import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  signInWithPopup,
} from 'firebase/auth'
import {getAuth} from '@resellam/firebase'
import {logger} from '@resellam/logger'
import {FirebaseAuthProviderID} from '../utils/helpers'

const getProviderTypeForProviderId = (providerId: string) => {
  switch (providerId) {
    case GoogleAuthProvider.PROVIDER_ID:
      return GoogleAuthProvider
    case FacebookAuthProvider.PROVIDER_ID:
      return FacebookAuthProvider
    default:
      return null
  }
}

export const signInWithExistingProvider = async (
  signinProvider: FirebaseAuthProviderID,
  providerId: FirebaseAuthProviderID,
  error: any,
) => {
  try {
    const SignInProviderType = getProviderTypeForProviderId(signinProvider)
    const ProviderType = getProviderTypeForProviderId(providerId)
    if (!ProviderType || !SignInProviderType) {
      throw new Error('Failed to get provider type')
    }
    const credential = SignInProviderType.credentialFromError(error)
    if (!credential) {
      throw new Error('Failed to get credential from error')
    }
    const provider = new ProviderType()
    const result = await signInWithPopup(getAuth(), provider)
    return await linkWithCredential(result.user, credential)
  } catch (err) {
    logger.error(err)
    throw err
  }
}
