import {Alert as UIAlert, AlertProps as UIAlertProps, Icon} from 'ui/core'

type AlertStyle = 'warning'

interface AlertProps extends UIAlertProps {
  alertStyle: AlertStyle
  testID?: string
}

const colorMap: Record<AlertStyle, string> = {
  warning: 'yellow.9',
}

const Alert = ({testID, alertStyle, ...rest}: AlertProps) => (
  <UIAlert
    icon={<Icon name="infoCircle" />}
    color={colorMap[alertStyle]}
    data-testid={testID}
    {...rest}
  />
)

export default Alert
