import {useEffect, useRef} from 'react'
import {useRouter} from 'next/router'
import analytics from 'lib/analytics'
import {useAuth} from '@resellam/auth'

const Analytics = () => {
  const router = useRouter()
  const {user} = useAuth()
  const timeoutRef = useRef<number>()

  useEffect(() => {
    if (user) {
      analytics.identify(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      })
    }
  }, [user])

  const handleRouteChange = () => {
    window.clearTimeout(timeoutRef.current)
    timeoutRef.current = window.setTimeout(() => {
      analytics.page()
    }, 1000)
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return null
}

export default Analytics
