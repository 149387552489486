import {Box, Group, Stack} from 'ui/core'
import {
  algoliaSearchClient,
  BRAND_FACET_ATTRIBUTE,
  CATEGORY_FACET_ATTRIBUTE,
  CONDITION_FACET_ATTRIBUTE,
  PRODUCTS_INDEX,
  algoliaInsightsMiddleware,
} from 'lib/algolia'
import {InstantSearch, useInstantSearch} from 'react-instantsearch-hooks-web'
import {useNextRouterHandler} from 'lib/algolia/useNextRouterHandler'
import {useRouter} from 'next/router'
import {useLayoutEffect} from 'react'
import {Filters, FiltersButton, VirtualFilters} from '../Filters'
import {Hits} from '../Hits'
import {HitsPerPage} from '../HitsPerPage'
import {Pagination} from '../Pagination'
import {SortBy} from '../SortBy'
import {Stats} from '../Stats'
import {Configure} from '../Configure'
import classes from './Search.module.css'

const serializeParam = (param?: string[]) => param?.join('|')
const normalizeParam = (param?: string) => (param ? [...param.split('|')] : [])

const InsightsMiddleware = () => {
  const {use} = useInstantSearch()
  useLayoutEffect(() => use(algoliaInsightsMiddleware), [use])
  return null
}

type RouteParams = {
  q?: string
  brand?: string
  category?: string
  condition?: string
  price?: string
  page?: number
  perPage?: number
  sort?: string
}

const Search = () => {
  const router = useRouter()

  const searchUrl =
    typeof window === 'undefined' ? undefined : `${window.location.origin}${router.asPath}`

  const {initialUiState, NextRouterHandler} = useNextRouterHandler<RouteParams>({
    dynamicRouteQuery: {},
    url: searchUrl,
    stateToRoute(uiState) {
      const indexUiState = uiState[PRODUCTS_INDEX]
      return {
        q: indexUiState?.query,
        brand: serializeParam(indexUiState.refinementList?.[BRAND_FACET_ATTRIBUTE]),
        category: serializeParam(indexUiState.refinementList?.[CATEGORY_FACET_ATTRIBUTE]),
        condition: serializeParam(indexUiState.refinementList?.[CONDITION_FACET_ATTRIBUTE]),
        price: indexUiState.numericMenu?.price,
        page: indexUiState.page,
        perPage: indexUiState.hitsPerPage,
        sort: indexUiState.sortBy,
      }
    },
    routeToState(routeState) {
      return {
        [PRODUCTS_INDEX]: {
          query: routeState.q,
          refinementList: {
            [BRAND_FACET_ATTRIBUTE]: normalizeParam(routeState.brand),
            [CATEGORY_FACET_ATTRIBUTE]: normalizeParam(routeState.category),
            [CONDITION_FACET_ATTRIBUTE]: normalizeParam(routeState.condition),
          },
          numericMenu: {
            price: routeState.price as string,
          },
          page: routeState.page,
          hitsPerPage: routeState.perPage,
          sortBy: routeState.sort,
        },
      }
    },
  })

  return (
    <InstantSearch
      searchClient={algoliaSearchClient}
      indexName={PRODUCTS_INDEX}
      initialUiState={initialUiState}
      stalledSearchDelay={500}
    >
      <InsightsMiddleware />
      <NextRouterHandler />
      <VirtualFilters />
      <Configure />
      <Stack>
        <Group wrap="nowrap" justify="space-between" align="center">
          <Stats />
          <FiltersButton hiddenFrom="sm" />
          <SortBy visibleFrom="sm" />
        </Group>
        <Box className={classes.root}>
          <Box className={classes.sidebar} visibleFrom="sm">
            <Filters />
          </Box>
          <Stack className={classes.content}>
            <Hits />
            <Group justify="right">
              <Box visibleFrom="sm">
                <HitsPerPage />
              </Box>
              <Pagination />
            </Group>
          </Stack>
        </Box>
      </Stack>
    </InstantSearch>
  )
}

export default Search
