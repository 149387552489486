import {Order} from 'model'
import {useMemo} from 'react'
import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {logger} from '@resellam/logger'
import {pay} from 'helpers/paystack'

interface PayOrder {
  order: Order
}

const usePayOrder = (): [
  (payment: PayOrder) => Promise<PaystackTransaction | null>,
  TaskState<PaystackTransaction>,
] => {
  const [taskFn, taskState] = useTask<PaystackTransaction>()

  const run = async (payOrder: PayOrder) =>
    taskFn(async () => {
      const {order} = payOrder
      const orderId = order.id
      const method = order.payment?.method
      const amount = order.payment?.amount
      const reference = order.payment?.paystack?.reference

      if (!orderId || !reference || !amount || !method) {
        logger.error('Missing payment parameters', {orderId, reference, amount, method})
        throw new Error('Missing payment parameters')
      }

      return pay({
        type: 'ORDER',
        reference,
        method,
        amount,
        metadata: {
          order_id: orderId,
          custom_fields: [
            {
              display_name: 'Order ID',
              variable_name: 'order_id',
              value: orderId,
            },
          ],
        },
      })
    })

  return useMemo(() => [run, taskState], [run, taskState])
}

export default usePayOrder
