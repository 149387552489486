import {doc, setDoc, updateDoc} from 'firebase/firestore'
import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {getAuth, getCollectionRef, getDocumentRef} from '@resellam/firebase'
import {useMemo} from 'react'
import {sellRequestConverter} from '@resellam/sell-request'
import {SellRequest} from 'model'
import {getBlob, getStorage, ref} from 'firebase/storage'
import {uploadSellRequestImages} from './create-sell-request'

type ResubmitSellRequest = {
  sellRequest: SellRequest
}

const resubmitSellRequest = async ({sellRequest}: ResubmitSellRequest): Promise<SellRequest> => {
  const {
    product,
    variants,
    repairs,
    damages,
    batteryHealth,
    additionalInfo,
    upgrade,
    images = [],
  } = sellRequest
  const docRef = doc(getCollectionRef('sell-requests')).withConverter(sellRequestConverter)

  const storage = getStorage()
  const downloadedImages = await Promise.all(
    images.map((image) => getBlob(ref(storage, image.fullPath))),
  )
  const uploadResult = await uploadSellRequestImages(
    downloadedImages.map((file, index) => ({file, name: images[index].name})),
    docRef.id,
  )

  const resubmit = {
    toSellRequestId: docRef.id,
    fromSellRequestId: sellRequest.id,
  }

  const data: Partial<SellRequest> = {
    product,
    variants,
    repairs,
    damages,
    batteryHealth,
    additionalInfo,
    upgrade,
    status: {
      name: 'SUBMITTED',
      createdAt: new Date(),
      createdBy: getAuth().currentUser?.uid,
    },
    images: uploadResult.map((result, index) => ({
      name: result.ref.name,
      fullPath: result.ref.fullPath,
      contentType: result.metadata.contentType,
      isReceipt: images[index].isReceipt,
      side: images[index].side,
    })),
    resubmit,
  }
  await setDoc(docRef, data)
  updateDoc(getDocumentRef('sell-requests', sellRequest.id), {resubmit})
  return {...data, id: docRef.id} as SellRequest
}

export const useResubmitSellRequest = (): [
  (data: ResubmitSellRequest) => Promise<Partial<SellRequest> | null>,
  TaskState<Partial<SellRequest>>,
] => {
  const [taskFn, taskState] = useTask<SellRequest>()
  const create = (data: ResubmitSellRequest) => taskFn(() => resubmitSellRequest(data))
  return useMemo(() => [create, taskState], [taskState])
}
