import {addDoc} from 'firebase/firestore'
import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {getAuth, getCollectionRef, defaultConverter} from '@resellam/firebase'
import {BankAccount} from 'model'
import {useMemo} from 'react'

type CreateBankAccount = Omit<BankAccount, 'id'>

const createBankAccount = async (bankAccount: CreateBankAccount): Promise<BankAccount> => {
  const collectionRef = getCollectionRef('bank-accounts').withConverter(defaultConverter)
  const data = {
    ...bankAccount,
    createdById: getAuth().currentUser?.uid,
  }
  const docRef = await addDoc(collectionRef, data)
  return {...data, id: docRef.id} as BankAccount
}

export const useCreateBankAccount = (): [
  (bankAccount: CreateBankAccount) => Promise<BankAccount | null>,
  TaskState<BankAccount>,
] => {
  const [taskFn, taskState] = useTask<BankAccount>()
  const run = (bankAccount: CreateBankAccount) => taskFn(() => createBankAccount(bankAccount))
  return useMemo(() => [run, taskState], [taskState])
}
