import {z} from 'zod'
import {zodResolver} from 'ui/form'
import {useForm} from 'core/hooks'
import {SELL_REQUEST_IMAGE_SIDES} from 'model'
import {formatTestID} from 'core/utils'
import {StepNavButtons, StepHeader} from 'components'
import UploadPhotos from '../UploadPhotos'
import {PhotosFormData} from '../types'

const photoSchema = (params?: {required_error?: string}) =>
  z.object(
    {
      file: z.object({name: z.string(), type: z.string()}).nullish(),
      isReceipt: z.boolean().default(false).nullish(),
      sellReason: z.enum(SELL_REQUEST_IMAGE_SIDES).optional(),
    },
    params,
  )

const schema = z
  .object({
    photos: z.object(
      {
        sides: photoSchema()
          .array()
          .refine(
            (val) => val.every((photo) => photo.file),
            () => ({message: 'Upload photos of all sides'}),
          ),
        more: photoSchema().array(),
      },
      {required_error: 'Upload photos'},
    ),
  })
  .strict()

export interface PhotosProps {
  hideMore?: boolean
  initialValues?: PhotosFormData
  onBack: (data: PhotosFormData) => void
  onNext: (data: PhotosFormData) => void
  testID?: string
}

const Photos = ({initialValues, hideMore, onBack, onNext, testID}: PhotosProps) => {
  const form = useForm<PhotosFormData>({
    initialValues,
    validate: zodResolver(schema),
  })

  const back = () => {
    onBack(form.values)
  }

  const next = () => {
    if (form.validate().hasErrors) return
    onNext(form.values)
  }

  return (
    <div data-testid={testID}>
      <StepHeader title="Photos" testID={formatTestID(testID, 'header')} />
      <UploadPhotos
        {...form.getInputProps('photos')}
        hideMore={hideMore}
        initialValue={form.values.photos}
        error={form.errors.photos || form.errors['photos.sides']}
        testID={formatTestID(testID, 'upload-photos')}
      />
      <StepNavButtons
        back={{onClick: back}}
        next={{onClick: next}}
        testID={formatTestID(testID, 'nav-buttons')}
      />
    </div>
  )
}

export default Photos
