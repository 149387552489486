import {Icon, RadioGroupProps, Stack} from 'ui/core'
import {PaymentMethod as PaymentMethodModel} from 'model'
import {formatTestID, paymentMethodMap} from 'core/utils'
import {RadioButton, RadioButtons} from 'components/RadioButtons'

interface PaymentMethodsProps extends Omit<RadioGroupProps, 'children' | 'onChange'> {
  methods: PaymentMethodModel[]
  onChange?: (value: PaymentMethodModel) => void
  height?: number
  testID?: string
}

const PaymentMethods = ({testID, methods, height, onChange, ...rest}: PaymentMethodsProps) => (
  <RadioButtons {...rest} onChange={(val) => onChange?.(val as PaymentMethodModel)} testID={testID}>
    <Stack>
      {methods.map((method) => (
        <RadioButton
          key={method}
          h={height}
          leftSection={<Icon name={paymentMethodMap[method].icon} size={40} />}
          value={method}
          testID={formatTestID(testID, method)}
        >
          {paymentMethodMap[method].title}
        </RadioButton>
      ))}
    </Stack>
  </RadioButtons>
)

export default PaymentMethods
