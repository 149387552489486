import {Button, ButtonProps} from 'ui/core'

export interface SellRequestOfferButtonProps extends ButtonProps {
  testID?: string
  onClick?: () => void
}

const SellRequestOfferButton = ({testID, ...rest}: SellRequestOfferButtonProps) => (
  <Button {...rest} bottom={16} left={16} right={16} pos="absolute" data-testid={testID} />
)

export default SellRequestOfferButton
