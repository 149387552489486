import {useMemo} from 'react'
import {useCartContext} from './CartProvider'
import {Cart} from './types'
import useCartItems from './use-cart-items'
import useCartTotal, {UseCartTotalProps} from './use-cart-total'

interface UseCartProps {
  checkout?: boolean
  total?: UseCartTotalProps
}

const useCart = (options?: UseCartProps) => {
  const cartTotal = useCartTotal(options?.total)
  const cartItems = useCartItems()
  const cartContext = useCartContext()

  const open = () => cartContext.setIsOpen(true)
  const close = () => cartContext.setIsOpen(false)
  const show = () => cartContext.setIsHidden(false)
  const hide = () => cartContext.setIsHidden(true)
  const items = cartContext.items.filter((item) => (options?.checkout ? item.checkout : true))

  return useMemo<Cart>(
    () => ({
      isOpen: cartContext.isOpen,
      isHidden: cartContext.isHidden,
      isEmpty: !items.length,
      open,
      close,
      show,
      hide,
      items,
      addItem: cartItems.add,
      removeItem: cartItems.remove,
      setItemCheckout: cartItems.setCheckout,
      setItemQuantity: cartItems.setQuantity,
      clearCheckedoutItems: cartItems.clearCheckedout,
      total: cartTotal,
    }),
    [cartTotal, cartItems, cartContext, items],
  )
}

export default useCart
