import {
  PhoneAuthCredential,
  ConfirmationResult,
  PhoneAuthProvider,
  RecaptchaVerifier,
  UserCredential,
  linkWithCredential,
} from 'firebase/auth'
import {getAuth, FirebaseErrorCode, getErrorMessage} from '@resellam/firebase'
import {logger} from '@resellam/logger'
import {LoadingOverlay, Box} from 'ui/core'
import {useRef} from 'react'
import {useNotifications} from '../../hooks'
import {useUpdateUserPhone} from '../../services/user'
import {PhoneForm} from '../AuthForm'

interface UpdatePhoneNumberProps {
  onSuccess: () => void
  onError?: () => void
  onStart?: () => void
  notify?: boolean
}

const UpdatePhoneNumber = ({
  notify = true,
  onError,
  onStart,
  onSuccess,
}: UpdatePhoneNumberProps) => {
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | undefined>()
  const notifications = useNotifications()
  const [updatePhone, updatePhoneState] = useUpdateUserPhone()

  const handleError = (error: Error, message: string = 'Error updating phone number') => {
    notifications.show({
      variant: 'error',
      message: getErrorMessage(error) || message,
    })
    logger.error(error, message)
    onError?.()
  }

  const handleResult = (success: boolean) => {
    if (notify)
      notifications.show({
        variant: success ? 'success' : 'error',
        action: 'update',
        entity: 'phone number',
      })
    if (success) onSuccess()
  }

  const recoverFromError = async (error: any) => {
    const {currentUser} = getAuth()
    const errorCode = error.code as FirebaseErrorCode
    if (errorCode === 'auth/account-exists-with-different-credential' && currentUser) {
      try {
        const cred = PhoneAuthProvider.credentialFromError(error)
        if (!cred) throw new Error('Failed to get credentialFromError')
        await linkWithCredential(currentUser, cred)
        handleResult(true)
      } catch (err: any) {
        handleError(err, err.message || 'Failed to linkWithCredential')
      }
    } else {
      handleError(error)
    }
  }

  const success = async (phoneNumber: string, credential: PhoneAuthCredential) => {
    try {
      const result = await updatePhone({phoneNumber, credential})
      handleResult(!!result)
    } catch (error: any) {
      recoverFromError(error)
    }
  }

  const verifyPhoneNumber = async (
    phoneNumber: string,
    recaptchaVerifier: RecaptchaVerifier,
  ): Promise<ConfirmationResult> => {
    recaptchaVerifierRef.current = recaptchaVerifier
    const provider = new PhoneAuthProvider(getAuth())
    const verificationId = await provider.verifyPhoneNumber(phoneNumber, recaptchaVerifier)
    return {
      verificationId,
      confirm: async (verificationCode: string) => {
        const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode)
        return phoneCredential as unknown as UserCredential
      },
    }
  }

  return (
    <Box style={{position: 'relative'}}>
      <PhoneForm onStart={onStart} onSuccess={success} onSignIn={verifyPhoneNumber} />
      <LoadingOverlay visible={updatePhoneState.isRunning} />
    </Box>
  )
}

export default UpdatePhoneNumber
