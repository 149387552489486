import {z} from 'zod'
import {zodResolver} from 'ui/form'
import {useForm} from 'core/hooks'
import {useCheckout} from 'lib/checkout'
import {addressSchema, formatTestID} from 'core/utils'
import {Address, ShippingMethod} from 'model'
import {AgentInfo, Segments, ShippingAddresses, StepHeader, StepNavButtons} from 'components'
import {SectionHeader} from 'core/components'
import {Card} from 'ui/core'

const schema = z
  .object({
    method: z.nativeEnum(ShippingMethod),
    address: addressSchema({required_error: 'Choose address'}).extend({id: z.string()}).optional(),
  })
  .refine((data) => (data.method === ShippingMethod.DELIVERY ? !!data.address : true), {
    message: 'Choose address',
    path: ['address'],
  })

interface CheckoutShippingProps {
  testID?: string
}

const CheckoutShipping = ({testID}: CheckoutShippingProps) => {
  const checkout = useCheckout()
  const form = useForm({
    initialValues: {
      method: checkout.data.shipping?.method || ShippingMethod.DELIVERY,
      address: checkout.data.shipping?.address,
    },
    validate: zodResolver(schema),
  })

  const next = () => {
    if (form.validate().hasErrors) return
    checkout.nextStep('shipping', schema.parse(form.values))
  }

  const handleChangeAddress = (address?: Address) => {
    form.setFieldValue('address', address)
    checkout.setData('shipping', {...checkout.data.shipping, address})
    checkout.setData('payment', {})
  }

  return (
    <>
      <StepHeader title="Shipping" testID={formatTestID(testID, 'title')} />
      <Segments
        fullWidth
        value={form.values.method}
        testID={formatTestID(testID, 'segments')}
        onChange={(val) => {
          if (val === ShippingMethod.PICKUP) handleChangeAddress(undefined)
          form.setFieldValue('method', val as ShippingMethod)
        }}
        data={[
          {
            label: 'Delivery',
            value: ShippingMethod.DELIVERY,
            children: (
              <div>
                <SectionHeader title="Address" />
                <ShippingAddresses value={form.values.address} onChange={handleChangeAddress} />
              </div>
            ),
          },
          {
            label: 'Pickup',
            value: ShippingMethod.PICKUP,
            children: (
              <Card>
                <AgentInfo />
              </Card>
            ),
          },
        ]}
      />
      <StepNavButtons next={{onClick: next, children: 'Continue', disabled: !form.isValid()}} />
    </>
  )
}

export default CheckoutShipping
