import {getAuth} from '../config'
import {DocumentSnapshot, serverTimestamp, Timestamp} from 'firebase/firestore'

const toJSDate = (ts?: any) => ts?.toDate()
const fromJSDate = (date?: any) => (date ? Timestamp.fromDate(date) : null)

export const defaultConverter = {
  toFirestore: ({id, createdAt, updatedAt, createdById, ...rest}: any) => ({
    ...rest,
    createdAt: id ? fromJSDate(createdAt) : serverTimestamp(),
    updatedAt: id ? serverTimestamp() : fromJSDate(updatedAt),
    createdById: !id ? getAuth().currentUser?.uid : createdById,
  }),
  fromFirestore: (snapshot: DocumentSnapshot) => {
    const {createdAt, updatedAt, ...data} = snapshot.data() as any
    return {
      ...data,
      id: snapshot.id,
      createdAt: toJSDate(createdAt),
      updatedAt: toJSDate(updatedAt),
    }
  },
}
