import {Card, IconName} from 'ui/core'
import {formatTestID} from 'core/utils'
import {SellRequestOfferHeader} from '../SellRequestOfferHeader'
import {SellRequestOfferDetailsLoading} from '../SellRequestOfferDetails'
import {SellRequestOfferButton, SellRequestOfferButtonProps} from '../SellRequestOfferButton'

export interface SellRequestOfferLoadingProps {
  title: string
  iconName: IconName
  accept: SellRequestOfferButtonProps
  testID?: string
}

const SellRequestOfferLoading = ({
  title,
  iconName,
  accept,
  testID,
}: SellRequestOfferLoadingProps) => (
  <Card pos="relative" pb={74} maw={350} data-testid={testID}>
    <SellRequestOfferHeader
      title={title}
      iconName={iconName}
      testID={formatTestID(testID, 'offer-header')}
    />
    <SellRequestOfferDetailsLoading testID={formatTestID(testID, 'offer-details')} />
    <SellRequestOfferButton {...accept} disabled />
  </Card>
)

export default SellRequestOfferLoading
