import {useState, ReactNode} from 'react'
import {Stack} from 'ui/core'
import {capitalize, compressFile, formatTestID} from 'core/utils'
import {SELL_REQUEST_IMAGE_SIDES} from 'model'
import SidePhotos from './SidePhotos'
import MorePhotos from './MorePhotos'
import {Photo} from './types'

export interface UploadPhotosState {
  sides: Photo[]
  more: Photo[]
}

export interface UploadPhotosProps {
  error?: ReactNode
  readonly?: boolean
  initialValue?: UploadPhotosState
  onChange?: (photos: UploadPhotosState) => void
  testID?: string
  hideMore?: boolean
}

const UploadPhotos = ({
  initialValue,
  readonly,
  error,
  hideMore,
  onChange,
  testID,
}: UploadPhotosProps) => {
  const [photos, setPhotos] = useState(() => {
    if (initialValue) return initialValue
    const sides: Photo[] = SELL_REQUEST_IMAGE_SIDES.map((side) => ({
      side,
      isReceipt: false,
      label: `${capitalize(side)} Side`,
    }))
    const more: Photo[] = []
    return {
      sides,
      more,
    }
  })

  const updatePhotos = (update: UploadPhotosState) => {
    setPhotos(update)
    onChange?.(update)
  }

  const addPhoto = (file: File, index: number, list: keyof UploadPhotosState) => {
    photos[list][index] = {
      ...photos[list][index],
      file,
      compressed: compressFile(file),
    }
    updatePhotos({
      ...photos,
      [list]: [...photos[list]],
    })
  }

  const removePhoto = (index: number, list: keyof UploadPhotosState) => {
    photos[list][index] = {...photos[list][index], file: undefined}
    updatePhotos({
      ...photos,
      [list]: [...photos[list]],
    })
  }

  const addMorePhotos = (files: File[]) => {
    updatePhotos({
      ...photos,
      more: [
        ...photos.more,
        ...files.map((file) => ({file, isReceipt: false, compressed: compressFile(file)})),
      ],
    })
  }

  const removeMorePhoto = (index: number) => {
    photos.more.splice(index, 1)
    updatePhotos({
      ...photos,
      more: photos.more,
    })
  }

  return (
    <Stack gap="xl" data-testid={testID}>
      <SidePhotos
        photos={photos.sides}
        error={error}
        readonly={readonly}
        onChange={(file, index) =>
          file ? addPhoto(file, index, 'sides') : removePhoto(index, 'sides')
        }
        testID={formatTestID(testID, 'side-photos')}
      />
      {((readonly && photos.more.length) || !readonly) && !hideMore ? (
        <MorePhotos
          readonly={readonly}
          photos={photos.more}
          onDelete={removeMorePhoto}
          onChange={addMorePhotos}
          testID={formatTestID(testID, 'more-photos')}
        />
      ) : null}
    </Stack>
  )
}

export default UploadPhotos
