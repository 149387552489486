import {PaymentMethod} from 'model'
import {PaymentMethods} from 'components/PaymentMethods'
import {StepNavButtons} from 'components/StepNavButtons'
import {z} from 'zod'
import {zodResolver} from 'ui/form'
import {useForm} from 'core/hooks'
import {formatTestID} from 'core/utils'

type PaymentData = {
  method: PaymentMethod
}

const schema = z.object({
  method: z.nativeEnum(PaymentMethod, {required_error: 'Select payment method'}),
})

interface SellRequestPickupPaymentProps {
  onBack: () => void
  onNext: (data: PaymentData) => void
  testID?: string
}

const SellRequestPickupPayment = ({testID, onNext, onBack}: SellRequestPickupPaymentProps) => {
  const form = useForm<Partial<PaymentData>>({
    initialValues: {},
    validate: zodResolver(schema),
  })

  const next = () => {
    if (form.validate().hasErrors) return
    onNext(schema.parse(form.values))
  }

  return (
    <div data-testid={testID}>
      <PaymentMethods
        {...form.getInputProps('method')}
        error={undefined}
        height={60}
        methods={[PaymentMethod.CARD, PaymentMethod.BANK_TRANSFER]}
        testID={formatTestID(testID, 'payment-methods')}
      />
      <StepNavButtons
        size="md"
        back={{onClick: onBack}}
        next={{onClick: next, disabled: !form.isValid()}}
        testID={formatTestID(testID, 'step-nav')}
      />
    </div>
  )
}

export default SellRequestPickupPayment
