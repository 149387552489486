import {Button} from 'ui/core'
import Link from 'next/link'
import {useBreakpoint} from 'core/hooks'

const FooterLink = ({title, href}: {title: string; href: string}) => {
  const {isXs} = useBreakpoint()
  return (
    <Button component={Link} href={href} variant="light" color="gray" size={isXs ? 'md' : 'xl'}>
      {title}
    </Button>
  )
}

export default FooterLink
