import type {ReactNode} from 'react'
import {useRouter} from 'next/router'
import Link from 'next/link'
import {Container, NavLinkProps, NavLink, Grid} from 'ui/core'

export interface SettingsLayoutProps {
  navLinks: (NavLinkProps & {route: string})[]
  children: ReactNode
}

const SettingsLayout = ({navLinks, children}: SettingsLayoutProps) => {
  const router = useRouter()
  return (
    <Container>
      <Grid gutter="xl">
        <Grid.Col span={{base: 12, md: 2}}>
          {navLinks.map((navLink) => (
            <NavLink
              component={Link}
              key={navLink.route}
              href={navLink.route}
              {...navLink}
              active={router.pathname === navLink.route}
            />
          ))}
        </Grid.Col>
        <Grid.Col span={{base: 12, md: 10}}>{children}</Grid.Col>
      </Grid>
    </Container>
  )
}

export default SettingsLayout
