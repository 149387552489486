import {Button, ButtonProps, Radio, RadioProps} from 'ui/core'
import {useRef} from 'react'
import {formatTestID} from 'core/utils'

type RadioButtonProps = ButtonProps &
  Pick<RadioProps, 'value' | 'onChange'> & {
    testID?: string
  }

const RadioButton = ({children, value, onChange, testID, ...rest}: RadioButtonProps) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <Button
      {...rest}
      fullWidth
      size="lg"
      variant="default"
      radius="lg"
      onClick={() => ref.current?.click()}
      rightSection={
        <Radio
          ref={ref}
          value={value}
          onChange={onChange}
          data-testid={formatTestID(testID, 'radio')}
          m={0}
        />
      }
      styles={() => ({
        inner: {
          justifyContent: 'space-between',
        },
      })}
      data-testid={testID}
    >
      {children}
    </Button>
  )
}

export default RadioButton
