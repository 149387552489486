export const COMPANY = {
  firstName: 'Ahmed',
  lastName: 'Onawale',
  name: 'Ahmed Onawale',
  phone: '+2348034674046',
  location: {
    googlePlaceId: 'ChIJC59G5XKMOxARFBvWMCFzEU8',
    googlePlaceName: '20 Eric Manuel Crescent',
    state: 'lagos',
    country: 'nigeria',
    city: 'surulere',
    localGovt: 'surulere',
  },
}

export const TUNDE = {
  firstName: 'Tunde',
  lastName: 'Ashiru',
  name: 'Tunde Ashiru',
  phone: '+2347062801670',
  location: {
    googlePlaceId: 'ChIJ7V4gRyeSOxARUTeU3tJo5W8',
    googlePlaceName: 'Powa Shopping Complex Ikeja',
    state: 'lagos',
    country: 'nigeria',
    city: 'ikeja',
    localGovt: 'ikeja',
  },
}

export const JULIUS = {
  firstName: 'Julius',
  lastName: 'Adinya',
  name: 'Julius Adinya',
  phone: '+2349022611902',
  location: {
    googlePlaceId: 'ChIJ7V4gRyeSOxARUTeU3tJo5W8',
    googlePlaceName: 'Powa Shopping Complex Ikeja',
    state: 'lagos',
    country: 'nigeria',
    city: 'ikeja',
    localGovt: 'ikeja',
  },
}

export const AGENT = {
  smartphone: JULIUS,
  laptop: JULIUS,
  wearable: JULIUS,
  tablet: JULIUS,
  default: JULIUS,
}

export const getAgentForCategory = (category?: string) => {
  const agentKey = category as keyof typeof AGENT
  return agentKey ? AGENT[agentKey] || AGENT.default : AGENT.default
}
