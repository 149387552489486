import {useHits, useInstantSearch} from 'react-instantsearch-hooks-web'
import {AlgoliaProductHit, CLICK_EVENT_NAMES} from 'lib/algolia'
import {NoResults} from 'core/components'
import {Button, Flex} from 'ui/core'
import {useState} from 'react'
import {useDidUpdate} from 'ui/hooks'
import {Catalogue, ProductCard, ProductCardLoading} from 'components'
import {trackProducts} from 'lib/analytics'
import {useShop} from 'lib/shop'

const Hits = () => {
  const hits = useHits<AlgoliaProductHit>()
  const shop = useShop()
  const instantSearch = useInstantSearch()
  const {status} = instantSearch
  const [ready, setReady] = useState(false)
  const loading = ready ? status === 'stalled' : status === 'loading'

  useDidUpdate(() => {
    shop.setQueryID(hits.results?.queryID)
  }, [hits.results?.queryID])

  useDidUpdate(() => {
    if (!ready && (status === 'idle' || status !== 'loading')) setReady(true)
  }, [status])

  const clearFilters = () => {
    instantSearch.setIndexUiState({query: undefined})
  }

  if (!instantSearch.results.__isArtificial && instantSearch.results.nbHits === 0) {
    return (
      <Flex direction="column" align="center">
        <NoResults query={instantSearch.indexUiState.query as string} />
        <Button variant="outline" onClick={clearFilters}>
          Clear filters
        </Button>
      </Flex>
    )
  }

  return (
    <Catalogue>
      {loading
        ? [...new Array(12).keys()].map((i) => (
            <ProductCardLoading imageWidth={180} imageHeight={180} key={i} />
          ))
        : hits.hits.map((hit) => (
            <ProductCard
              key={hit.objectID}
              href={`/shop/${hit.slug}`}
              product={{...hit, id: hit.objectID, variants: []}}
              onClick={() => {
                hits.sendEvent('click', hit, CLICK_EVENT_NAMES.plpProductClicked)
                trackProducts('shop_view_product', {
                  products: [
                    {
                      ...hit,
                      variants: undefined,
                      id: hit.objectID,
                      variant: hit.variants?.join(', '),
                      title: hit.title,
                      brand: hit?.brand,
                      category: hit?.category,
                      price: hit?.price,
                      condition: hit.condition,
                      slug: hit.slug,
                      image: hit.image,
                    },
                  ],
                })
              }}
            />
          ))}
    </Catalogue>
  )
}

export default Hits
