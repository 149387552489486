import {Button, ButtonProps} from 'ui/core'
import {useAuth} from 'hooks/use-auth'
import {CartItem} from 'lib/cart/types'
import {CheckoutProvider} from 'lib/checkout'
import {CartProvider} from 'lib/cart'
import {FullscreenModal} from 'core/components'
import {trackProducts} from 'lib/analytics'
import {useState} from 'react'
import {formatTestID} from 'core/utils'
import {convertedObjectIDsAfterSearch, convertedProductIDs} from 'lib/algolia'
import {useShop} from 'lib/shop'
import {Checkout} from '../Checkout'

interface BuyNowButtonProps extends ButtonProps {
  getItem: () => CartItem | undefined
  testID?: string
}

const BuyNowButton = ({getItem, testID, ...rest}: BuyNowButtonProps) => {
  const auth = useAuth()
  const shop = useShop()
  const [buyItem, setBuyItem] = useState<CartItem>()

  const click = async () => {
    const item = getItem()

    if (!item) return

    if (shop.queryID) {
      convertedObjectIDsAfterSearch([item.product.id], 'pdpProductBuyNowAfterSearch', shop.queryID)
    } else {
      convertedProductIDs([item.product.id], 'pdpProductBuyNow')
    }

    trackProducts('shop_buy_item_now', {
      products: [
        {
          ...item.product,
          price: item.price,
          quantity: item.quantity,
          variant: item.variants?.map((variant) => variant.value).join(', '),
        },
      ],
    })

    await auth.requireAuth()

    setBuyItem(item)
  }

  return (
    <>
      <Button {...rest} onClick={click} data-testid={testID}>
        Buy Now
      </Button>
      {buyItem && (
        <FullscreenModal
          opened
          title="Checkout"
          onClose={() => setBuyItem(undefined)}
          testID={formatTestID(testID, 'checkout')}
        >
          <CartProvider items={buyItem ? [buyItem] : []}>
            <CheckoutProvider>
              <Checkout onSuccessContinue={() => setBuyItem(undefined)} />
            </CheckoutProvider>
          </CartProvider>
        </FullscreenModal>
      )}
    </>
  )
}

export default BuyNowButton
