import {Button, Card, Stack} from 'ui/core'
import {useNotifications, usePopup} from 'core/hooks'
import {useResubmitSellRequest} from 'lib/sell-request'
import {useRouter} from 'next/router'
import {formatTestID} from 'core/utils'
import {useSellRequest} from '@resellam/sell-request'
import {DetailItem, RelativeDate, SectionHeader} from 'core/components'

interface SellRequestExpiredProps {
  testID?: string
}

const SellRequestExpired = ({testID}: SellRequestExpiredProps) => {
  const popup = usePopup()
  const router = useRouter()
  const notifications = useNotifications()
  const {sellRequest} = useSellRequest()
  const [resubmitSellRequest, resubmitSellRequestState] = useResubmitSellRequest()

  const resubmit = async () => {
    if (!sellRequest) return

    const notif = notifications.show({
      loading: true,
      title: 'Resubmitting sell request',
      message: 'Please wait, this may take a few seconds.',
      autoClose: false,
      withCloseButton: false,
    })

    const result = await resubmitSellRequest({sellRequest})

    notif.update({
      action: 'create',
      variant: result ? 'success' : 'error',
      entity: 'sell request',
    })

    if (result) router.replace(`/sell-requests/${result.id}`)
  }

  const confirm = () =>
    popup.show({
      variant: 'confirmation',
      title: 'Resubmit',
      message: 'Are you sure you want to resubmit this request?',
      confirm: {
        onClick: resubmit,
      },
    })

  return (
    <Card>
      <SectionHeader title="Your sell request has expired!" />
      <Stack>
        <DetailItem
          label="Expired"
          value={
            sellRequest?.offer?.expiresAt ? (
              <RelativeDate
                value={sellRequest?.offer?.expiresAt}
                testID={formatTestID(testID, 'expiry')}
              />
            ) : null
          }
        />
        {!sellRequest?.resubmit && (
          <Button
            variant="default"
            loading={resubmitSellRequestState.isRunning}
            onClick={confirm}
            data-testid={formatTestID(testID, 'resubmit')}
          >
            Resubmit
          </Button>
        )}
      </Stack>
    </Card>
  )
}

export default SellRequestExpired
