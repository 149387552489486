import {DetailList, ProductDetailWrapper} from 'core/components'
import {Product, SellRequestSwap} from 'model'
import {Card, CardProps} from 'ui/core'
import {formatTestID, formatVariantValue} from 'core/utils'
import {PRODUCT_FIELDS, getSanityClient} from '@resellam/sanity'
import {useMemo} from 'react'
import {useGetDocument} from '@aonawale/react-sanity-hooks'

export interface SellRequestUpgradeProps extends Omit<CardProps, 'children'> {
  upgrade?: SellRequestSwap
  testID?: string
}

const SellRequestUpgrade = ({upgrade, testID, ...rest}: SellRequestUpgradeProps) => {
  const {data: product} = useGetDocument<Product>(
    getSanityClient(),
    upgrade?.productId ? {id: upgrade?.productId} : undefined,
    PRODUCT_FIELDS,
  )

  const items = useMemo(
    () => [
      {
        label: 'Device',
        value: product?.title || upgrade?.title,
        testID: formatTestID(testID, 'device'),
      },
      ...(upgrade?.variants?.map((variant) => ({
        label: variant.label,
        value: formatVariantValue(variant.value),
        testID: formatTestID(testID, variant.label),
      })) || []),
    ],
    [product, upgrade],
  )

  return (
    <Card {...rest} data-testid={testID}>
      <ProductDetailWrapper product={product} imageSize={280}>
        <DetailList items={items} />
      </ProductDetailWrapper>
    </Card>
  )
}

export default SellRequestUpgrade
