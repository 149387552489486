import {ReactNode} from 'react'
import {List, ListProps} from 'ui/core'

export interface HitListProps extends ListProps {
  children?: ReactNode
}

const HitList = ({children, ...rest}: HitListProps) => (
  <List
    listStyleType="none"
    spacing="xs"
    styles={{itemWrapper: {width: '100%'}, itemLabel: {width: '100%'}}}
    {...rest}
  >
    {children}
  </List>
)

export default HitList
