import {MantineColor} from 'ui/core'

export interface LogoProps {
  color?: MantineColor
  width?: number
  height?: number
}

const Logo = ({width, height, color}: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 44 32"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M42,8.16c-.12-.22-.25-.44-.38-.65s-.27-.42-.41-.63-.44-.61-.67-.9L40,5.41c-.16-.19-.33-.37-.5-.55l-.27-.27A16,16,0,0,0,28.44,0H27.6l-.43,0h0l-.42,0h0l-.33,0h0L25.7.15,25.38.2h-.09l-.42.07c-.39.06-.78.14-1.16.23l-.36.08-.4.1c-.73.2-1.43.43-2.1.68l-.32.13-.34.13a26.43,26.43,0,0,0-3,1.45L17,3.22l-1.12.68c.35.22.7.43,1.17.67a14.42,14.42,0,0,0,3.76,1.26l1.28.2c-.45,0-.88-.12-1.28-.2-.58-.07-1.24-.14-2-.18h-.05A13.68,13.68,0,0,0,9.38,8.71c-.84.69-1.67,1.39-2.52,2.08-.61.5-1.22,1-1.84,1.49A7.19,7.19,0,0,1,0,14a11.43,11.43,0,0,0,3.34,3.8l.4.3c.2.16.41.32.63.47l.4.29c.81.56,1.6,1.06,2.3,1.48a27.52,27.52,0,0,0,6.83,3,27.73,27.73,0,0,0,5,.95,8.7,8.7,0,0,1-3.15,1.74l-.31.09a8.5,8.5,0,0,1-3.25.2,23.39,23.39,0,0,0,7.42,4.15A23.8,23.8,0,0,0,28,31.85h0a16.12,16.12,0,0,0,4.59-.66h0A15.91,15.91,0,0,0,42,8.16ZM28.12,28.38a12.42,12.42,0,0,1-5.22-1.14h.53a12.46,12.46,0,0,0,12.4-11,10.1,10.1,0,0,1,3.85,4.36A12.48,12.48,0,0,1,28.12,28.38ZM40.6,15.93c0,.16,0,.32,0,.48s0,.21,0,.32a2.29,2.29,0,0,1,0,.25c0,.12,0,.24,0,.36l-.06.47a12.5,12.5,0,0,0-10.35-5.47h-.45v2.57l-2.9-2.13L20.42,8.16l6.27-4.6,3-2.17V3.58h0l.56.08.51.1.16,0,.32.08a8.57,8.57,0,0,1,1,.3l.25.09.29.12.26.11c.34.14.66.3,1,.47l.23.13a.27.27,0,0,1,.1.06l.17.1.29.17,0,0,.19.13.29.2.2.14a12.49,12.49,0,0,1,1,.8c.12.1.23.21.34.32s.17.17.26.26l.29.31.39.46.27.33.34.47.13.2A11.71,11.71,0,0,1,39.59,11c0,.11.1.23.14.34s.12.31.17.46S40,12,40,12l.1.31c0,.09.05.17.07.26a.29.29,0,0,1,0,.09,2.86,2.86,0,0,1,.07.28l.06.21c0,.14.06.27.08.41l.09.51,0,.28c0,.1,0,.2,0,.3s0,.14,0,.22V15c0,.11,0,.22,0,.34s0,.39,0,.59Z" />
      </g>
    </g>
  </svg>
)

export default Logo
