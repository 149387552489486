import {useMemo} from 'react'
import {useRouter} from 'next/router'

const PAGE_SIZE_KEY = 'page_size'

const usePagination = () => {
  const router = useRouter()
  const size = router.query[PAGE_SIZE_KEY]
    ? parseInt(router.query[PAGE_SIZE_KEY] as string, 10)
    : 10

  const change = (value: number) =>
    router.replace({query: {...router.query, [PAGE_SIZE_KEY]: value}}, undefined, {shallow: true})

  return useMemo(() => ({size, change}), [size])
}

export default usePagination
