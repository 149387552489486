import {Stepper as MantineStepper, StepperProps as MantineStepperProps} from '@mantine/core'
import {forwardRef} from 'react'
import classes from './Stepper.module.css'

// @ts-ignore
const Stepper: typeof MantineStepper = forwardRef<HTMLDivElement, MantineStepperProps>(
  ({classNames, ...rest}, ref) => (
    <MantineStepper classNames={{steps: classes.steps, ...classNames}} {...rest} ref={ref} />
  ),
)

Stepper.Step = MantineStepper.Step
Stepper.Completed = MantineStepper.Completed

export default Stepper
