import {MantineProvider, createTheme} from 'ui/core'
import {Notifications} from 'ui/notifications'
import {ModalsProvider} from 'ui/modals'
import {ReactNode} from 'react'
import dynamic from 'next/dynamic'
import {PopupProvider} from '../PopupContext'
import {theme as uiTheme} from '../../utils'

const DynamicRouteProgress = dynamic(() => import('../../components/RouteProgress/RouteProgress'), {
  suspense: false,
})

export interface CoreProviderProps {
  children: ReactNode
  defaultColorScheme?: 'light' | 'dark'
}

const fontFamily =
  'Inter,sans-serif,apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;'

const theme = createTheme({
  cursorType: 'pointer',
  primaryColor: 'blue',
  colors: {
    blue: [
      '#ddefff',
      '#cddff7',
      '#abcaf2',
      '#81afec',
      '#5795e6',
      uiTheme.colors.primary.blue,
      '#0360d9',
      '#0350b5',
      '#024091',
      '#02306d',
    ],
  },
  defaultRadius: 'md',
  fontFamily,
  headings: {
    fontFamily,
  },
  components: {
    Loader: {defaultProps: {type: 'oval'}},
    Avatar: {defaultProps: {radius: '50%'}},
    Dropzone: {defaultProps: {radius: 'lg'}},
    SegmentedControl: {defaultProps: {radius: 'xl', size: 'md'}},
    Checkbox: {defaultProps: {radius: 'sm'}},
    Menu: {defaultProps: {shadow: 'xl', radius: 'md'}},
    Container: {defaultProps: {size: 'lg'}},
    Card: {defaultProps: {radius: 'lg', withBorder: true}},
    Chip: {defaultProps: {size: 'md'}},
    Button: {
      defaultProps: {size: 'md', radius: 'xl'},
      styles: () => ({
        label: {textTransform: 'capitalize'},
      }),
    },
    CloseButton: {defaultProps: {size: 'md', radius: 'xl'}},
    Select: {defaultProps: {size: 'md', variant: 'default'}},
    NativeSelect: {defaultProps: {size: 'md', variant: 'default'}},
    Switch: {defaultProps: {size: 'md'}},
    Radio: {defaultProps: {size: 'md'}},
    RadioGroup: {defaultProps: {size: 'md'}},
    Textarea: {defaultProps: {size: 'md'}},
    TextInput: {defaultProps: {size: 'md'}},
    NumberInput: {defaultProps: {size: 'md'}},
    TimeInput: {defaultProps: {size: 'md'}},
    DatePicker: {defaultProps: {size: 'md'}},
    Input: {defaultProps: {size: 'md'}},
    InputWrapper: {defaultProps: {size: 'md'}},
    Popover: {defaultProps: {shadow: 'md'}},
    HoverCard: {defaultProps: {shadow: 'md'}},
    Autocomplete: {defaultProps: {size: 'md', variant: 'default'}},
    Modal: {
      styles: {
        title: {
          flex: 1,
          textAlign: 'center',
          fontSize: '20px',
        },
      },
    },
  },
})

const CoreProvider = ({defaultColorScheme, children}: CoreProviderProps) => (
  <MantineProvider theme={theme} defaultColorScheme={defaultColorScheme}>
    <Notifications position="top-right" />
    <ModalsProvider>
      <PopupProvider>{children}</PopupProvider>
      <DynamicRouteProgress />
    </ModalsProvider>
  </MantineProvider>
)

export default CoreProvider
