import {SellRequest, User} from 'model'
import {useAuth} from '@resellam/auth'
import {ReactNode, useContext, useMemo} from 'react'
import {useGetDocument} from '@resellam/firebase'
import SellRequestContext, {SellRequestContextValue} from './SellRequestContext'
import {sellRequestConverter} from '../../utils'

interface SellRequestProps {
  sellRequestId: string
  children: ReactNode
}

const SellRequestProvider = ({sellRequestId, children}: SellRequestProps) => {
  const {isAdmin} = useAuth()
  const {
    data: sellRequest,
    isLoading,
    mutate,
  } = useGetDocument<SellRequest>({
    collection: 'sell-requests',
    id: sellRequestId,
    converter: sellRequestConverter,
  })
  const {data: seller} = useGetDocument<User>({collection: 'users', id: sellRequest?.createdById})
  const {data: statusBy} = useGetDocument<User>({
    collection: 'users',
    id: isAdmin ? sellRequest?.status?.createdBy : undefined,
  })
  const {data: acceptedBy} = useGetDocument<User>({
    collection: 'users',
    id: isAdmin ? sellRequest?.offer?.createdBy : undefined,
  })

  const sellRequestContext: SellRequestContextValue = useMemo(
    () => ({
      isLoading: !sellRequestId || isLoading,
      sellRequest,
      seller,
      product: sellRequest?.product,
      statusBy,
      acceptedBy,
      updateSellRequest: (data: Partial<SellRequest>) =>
        mutate((val) => (val ? {...val, ...data} : undefined), false),
    }),
    [sellRequest, seller, statusBy, acceptedBy, isLoading],
  )

  return (
    <SellRequestContext.Provider value={sellRequestContext}>{children}</SellRequestContext.Provider>
  )
}

export default SellRequestProvider

export const useSellRequest = () => {
  const context = useContext(SellRequestContext)
  if (context === undefined) {
    throw new Error('useSellRequest must be used within a SellRequestContext')
  }
  return context
}
