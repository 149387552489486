import {User} from 'model'
import {useMemo} from 'react'
import {Avatar, AnchorProps, Card, Flex, Skeleton} from 'ui/core'
import {FirebaseImage} from '../FirebaseImage'
import {DetailList} from '../DetailList'
import {fullName, initials} from '../../utils'
import {Email} from '../Email'
import {PhoneNumber} from '../PhoneNumber'

interface CustomerCardProps extends AnchorProps {
  customer?: User
  testID?: string
}

const CustomerCard = ({customer, testID}: CustomerCardProps) => {
  const customerName = fullName(customer?.firstName, customer?.lastName)

  const items = useMemo(
    () => [
      {
        value: customerName,
      },
      ...(customer?.phoneNumber
        ? [
            {
              value: <PhoneNumber inline value={customer.phoneNumber} />,
            },
          ]
        : []),
      ...(customer?.email
        ? [
            {
              value: <Email inline value={customer.email} />,
            },
          ]
        : []),
    ],
    [customer],
  )

  return (
    <Card title="Customer" testID={testID}>
      <Flex gap="md" direction={{base: 'column', sm: 'row'}} align={{base: 'start', sm: 'center'}}>
        {customer ? (
          <>
            <FirebaseImage
              path={customer?.photo?.path}
              renderRoot={(props) => (
                <Avatar {...props} alt={customerName} style={{alignSelf: 'center'}} size={84} />
              )}
            >
              {initials(customer?.firstName, customer?.lastName)}
            </FirebaseImage>
            <DetailList items={items} />
          </>
        ) : (
          <>
            <Skeleton
              radius="50%"
              width={84}
              height={84}
              style={{alignSelf: 'center', flexShrink: 0}}
            />
            <DetailList
              items={[
                {value: <Skeleton height={16} width="50%" />},
                {value: <Skeleton height={16} width="80%" />},
              ]}
              style={{width: '100%'}}
            />
          </>
        )}
      </Flex>
    </Card>
  )
}

export default CustomerCard
