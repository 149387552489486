import {ReactNode} from 'react'
import {Container, Modal, ModalProps, ScrollArea} from 'ui/core'
import ModalHeader from '../ModalHeader'

export const FullscreenModalHeader = ({title, onBack}: {title: ReactNode; onBack: () => void}) => (
  <ModalHeader container title={title} onBack={onBack} />
)

export const FullscreenModalContent = ({children}: {children: ReactNode}) => {
  const scrollHeight = typeof window === 'undefined' ? 500 : window.innerHeight - 96
  return (
    <Container px={0}>
      <ScrollArea offsetScrollbars style={{height: `${scrollHeight}px`}}>
        {children}
      </ScrollArea>
    </Container>
  )
}

export interface FullscreenModalProps extends ModalProps {
  testID?: string
}

const FullscreenModal = ({onClose, children, title, testID, ...rest}: FullscreenModalProps) => (
  <Modal
    {...rest}
    fullScreen
    closeOnEscape={false}
    withCloseButton={false}
    withOverlay={false}
    onClose={onClose}
    transitionProps={{transition: 'fade'}}
    title={<FullscreenModalHeader title={title} onBack={onClose} />}
    data-testid={testID}
  >
    <FullscreenModalContent>{children}</FullscreenModalContent>
  </Modal>
)

export default FullscreenModal
