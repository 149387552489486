import {
  ActionIcon,
  ActionIconProps,
  MantineSize,
  Tooltip,
  useMantineTheme,
  createPolymorphicComponent,
} from '@mantine/core'
import {useMediaQuery} from '@mantine/hooks'
import {forwardRef, MouseEventHandler} from 'react'
import Icon, {IconName} from '../Icon/Icon'

export interface IconButtonProps extends ActionIconProps {
  name: IconName
  title?: string
  iconSize?: MantineSize
  onClick?: MouseEventHandler<HTMLButtonElement>
  testID?: string
}

const sizes: MantineSize[] = ['xs', 'sm', 'md', 'lg', 'xl']

const IconButton = forwardRef(
  ({name, title, iconSize, disabled, testID, size, ...rest}: IconButtonProps, ref: any) => {
    const theme = useMantineTheme()
    const isLg = useMediaQuery(`(min-width: ${theme.breakpoints.md})`, false)

    const actualIconSize =
      iconSize || typeof size === 'string'
        ? sizes[sizes.indexOf((size as MantineSize) || 'xl') - 1]
        : undefined

    const button = (
      <ActionIcon
        title={title}
        radius="xl"
        size={iconSize ? size : size || 'xl'}
        variant="subtle"
        color="blue"
        disabled={disabled}
        {...rest}
        ref={ref}
        data-testid={testID}
      >
        <Icon name={name} size={actualIconSize || 'md'} />
      </ActionIcon>
    )

    return isLg && title ? (
      <Tooltip withinPortal label={title} disabled={disabled}>
        {button}
      </Tooltip>
    ) : (
      button
    )
  },
)

export default createPolymorphicComponent<'div', IconButtonProps>(IconButton)
