import {
  IconPlus,
  IconEdit,
  IconTrash,
  IconBell,
  IconMenu,
  IconMenu2,
  IconSettings,
  IconLayoutList,
  IconHelp,
  IconLogout,
  IconUsers,
  IconCategory,
  IconBrandProducthunt,
  IconBuildingSkyscraper,
  IconArrowRight,
  IconFilter,
  IconChevronRight,
  IconChevronLeft,
  IconPhoto,
  IconMail,
  IconBrandGoogle,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandLinkedin,
  IconAlertCircle,
  IconCircleCheck,
  IconUserCircle,
  IconCreditCard,
  IconDots,
  IconHome2,
  IconMapPin,
  IconCalendar,
  IconClock,
  IconGps,
  IconMessage2,
  IconShield,
  IconBan,
  IconUserCheck,
  IconInfoCircle,
  IconPhotoUp,
  IconFileDescription,
  IconTool,
  IconInfoSquare,
  IconReceipt,
  IconX,
  IconBraces,
  IconGripVertical,
  IconApps,
  IconSpeakerphone,
  IconBrandWhatsapp,
  IconSearch,
  IconChevronDown,
  IconShoppingCart,
  IconMinus,
  IconTruckDelivery,
  IconCash,
  IconArrowUpLeft,
  IconBuildingBank,
  IconBuildingStore,
  IconCopy,
  IconShoppingCartPlus,
  IconChecks,
  IconShoppingBag,
  IconPhone,
  IconRepeat,
} from '@tabler/icons-react'

const icons = {
  plus: IconPlus,
  edit: IconEdit,
  trash: IconTrash,
  bell: IconBell,
  menu: IconMenu,
  menu2: IconMenu2,
  settings: IconSettings,
  layoutList: IconLayoutList,
  help: IconHelp,
  logout: IconLogout,
  users: IconUsers,
  category: IconCategory,
  brandProducthunt: IconBrandProducthunt,
  buildingSkyscraper: IconBuildingSkyscraper,
  arrowRight: IconArrowRight,
  filter: IconFilter,
  chevronRight: IconChevronRight,
  chevronLeft: IconChevronLeft,
  photo: IconPhoto,
  mail: IconMail,
  brandGoogle: IconBrandGoogle,
  brandFacebook: IconBrandFacebook,
  brandInstagram: IconBrandInstagram,
  brandTwitter: IconBrandTwitter,
  brandLinkedin: IconBrandLinkedin,
  alertCircle: IconAlertCircle,
  circleCheck: IconCircleCheck,
  userCircle: IconUserCircle,
  creditCard: IconCreditCard,
  dots: IconDots,
  home2: IconHome2,
  mapPin: IconMapPin,
  calendar: IconCalendar,
  clock: IconClock,
  gps: IconGps,
  message2: IconMessage2,
  shield: IconShield,
  ban: IconBan,
  userCheck: IconUserCheck,
  infoCircle: IconInfoCircle,
  photoUp: IconPhotoUp,
  fileDescription: IconFileDescription,
  tool: IconTool,
  infoSquare: IconInfoSquare,
  receipt: IconReceipt,
  x: IconX,
  braces: IconBraces,
  gripVertical: IconGripVertical,
  apps: IconApps,
  speakerphone: IconSpeakerphone,
  brandWhatsapp: IconBrandWhatsapp,
  search: IconSearch,
  chevronDown: IconChevronDown,
  shoppingCart: IconShoppingCart,
  minus: IconMinus,
  truckDelivery: IconTruckDelivery,
  cash: IconCash,
  arrowUpLeft: IconArrowUpLeft,
  buildingBank: IconBuildingBank,
  buildingStore: IconBuildingStore,
  copy: IconCopy,
  shoppingCartPlus: IconShoppingCartPlus,
  checks: IconChecks,
  shoppingBag: IconShoppingBag,
  phone: IconPhone,
  repeat: IconRepeat,
}

export default icons
