export const primaryColor = '#2d7bdf'
export const waterBlue = '#ddefff'

export const theme = {
  colors: {
    primary: {
      blue: primaryColor,
    },
    secondary: {
      waterBlue,
    },
  },
}
