import {useHitsPerPage as useISHitsPerPage} from 'react-instantsearch-hooks-web'
import {Select} from 'ui/core'
import {useStats} from '../Stats'

export const useHitsPerPage = () => {
  const hitsPerPage = useISHitsPerPage({
    items: [
      {label: '20 items', value: 20, default: true},
      {label: '40 items', value: 40},
    ],
  })
  return hitsPerPage
}

const HitsPerPage = () => {
  const stats = useStats()
  const hitsPerPage = useHitsPerPage()

  if (!stats.nbHits) return null

  return (
    <Select
      label="Show"
      styles={{
        root: {display: 'flex', alignItems: 'center'},
        wrapper: {maxWidth: '128px', marginLeft: '8px'},
        label: {marginBottom: '0px'},
      }}
      value={hitsPerPage.items.find(({isRefined}) => isRefined)?.value.toString()}
      data={hitsPerPage.items.map((item) => ({label: item.label, value: item.value.toString()}))}
      onChange={(val) => hitsPerPage.refine(val ? parseInt(val, 10) : hitsPerPage.items[0].value)}
    />
  )
}

export default HitsPerPage
