import {hideNotification, updateNotification, showNotification} from 'ui/notifications'
import {Icon, Loader} from 'ui/core'
import {useMemo} from 'react'

type NotificationProps = {
  loading?: boolean
  title?: string
  message?: string
  color?: string
  icon?: React.ReactNode
  autoClose?: boolean
  withCloseButton?: boolean
  onClose?: () => void
}

type Action = 'create' | 'update' | 'delete' | 'cancel'

const randomId = () => `mantine-${Math.random().toString(36).slice(2, 11)}`

type CustomNotificationProps = {
  variant?: 'success' | 'error'
  action?: Action
  entity?: string
  message?: string
} & Partial<NotificationProps>

const getPastTense = (action: Action) => (action === 'cancel' ? 'cancelled' : `${action}d`)

const useNotifications = () => {
  const build = ({variant, action, entity, message, loading, ...rest}: CustomNotificationProps) => {
    const isSuccess = variant === 'success'
    let actualMessage = message
    if (action && entity) {
      actualMessage = isSuccess
        ? `Successfully ${getPastTense(action)} ${entity}`
        : `Failed to ${action} ${entity}`
    }

    let icon
    let color
    let title

    if (variant) {
      color = isSuccess ? 'green' : 'red'
      title = isSuccess ? 'Success' : 'Error'
      icon = isSuccess ? <Icon name="circleCheck" /> : <Icon name="alertCircle" />
    }
    if (loading) {
      icon = <Loader />
    }

    return {
      color,
      title,
      icon,
      message: actualMessage,
      ...rest,
    }
  }

  const update = ({id, ...props}: CustomNotificationProps & {id: string}) => {
    updateNotification({
      id,
      ...build(props),
    })
  }

  const show = (props: CustomNotificationProps) => {
    const id = randomId()
    showNotification({
      id,
      ...build(props),
    })
    return {
      hide: () => hideNotification(id),
      update: (updateProps: CustomNotificationProps) => update({...updateProps, id}),
    }
  }

  return useMemo(() => ({show, update, showNotification}), [])
}

export default useNotifications
