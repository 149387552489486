import {FirebaseApp, getApps, initializeApp} from 'firebase/app'
import {getAuth as getFBAuth} from 'firebase/auth'
import {initializeFirestore, getFirestore as getFBFirestore} from 'firebase/firestore'
import {getStorage as getFBStorage} from 'firebase/storage'
import {getRemoteConfig as getFBRemoteConfig} from 'firebase/remote-config'
// import {connectAuthEmulator} from 'firebase/auth'
// import {connectFirestoreEmulator} from 'firebase/firestore'
// import {connectStorageEmulator} from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

let app: FirebaseApp

if (!getApps()[0]) {
  app = initializeApp(firebaseConfig)
  initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  })

  // const auth = getFBAuth(app)
  // const storage = getFBStorage(app)
  // const firestore = getFBFirestore(app)
  // connectAuthEmulator(auth!, 'http://localhost:3031')
  // connectFirestoreEmulator(firestore!, 'localhost', 3032)
  // connectStorageEmulator(storage!, 'localhost', 3035)
}

export const getAuth = () => getFBAuth(app)
export const getFirestore = () => getFBFirestore(app)
export const getStorage = () => getFBStorage(app)
export const getRemoteConfig = () => getFBRemoteConfig(app)
