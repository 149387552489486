import {useMemo} from 'react'
import {AlgoliaProductHit} from 'lib/algolia'
import {AutocompleteCollection} from '@algolia/autocomplete-core'
import {useProductSearchContext} from '../ProductSearchProvider'
import {QuerySuggestionsHit, RecentSearchesItem} from '../types'

const useProductSearch = () => {
  const {autocomplete, autocompleteState, searchType, setSearchType} = useProductSearchContext()

  const products = autocompleteState.collections?.find(
    (coll) => coll.source.sourceId === 'products',
  ) as AutocompleteCollection<AlgoliaProductHit> | undefined

  const recentSearches = autocompleteState.collections?.find(
    (coll) => coll.source.sourceId === 'recentSearchesPlugin',
  ) as AutocompleteCollection<RecentSearchesItem> | undefined

  const querySuggestions = autocompleteState.collections?.find(
    (coll) => coll.source.sourceId === 'querySuggestionsPlugin',
  ) as AutocompleteCollection<QuerySuggestionsHit> | undefined

  const {status, query, isOpen} = autocompleteState
  const noResults = status === 'idle' && query && !products?.items.length

  return useMemo(
    () => ({
      searchType,
      setSearchType,
      autocomplete,
      status,
      isOpen,
      query,
      noResults,
      products,
      recentSearches,
      querySuggestions,
    }),
    [autocompleteState, searchType],
  )
}

export default useProductSearch
