import {Product} from 'model'
import {Stepper, Icon} from 'ui/core'
import {useEffect, useState} from 'react'
import {useDidUpdate, useWindowScroll} from 'ui/hooks'
import {useBreakpoint} from 'core/hooks'
import {formatTestID} from 'core/utils'
import {CreateSellRequest} from 'lib/sell-request'
import Photos from './Photos'
import Issues from './Issues'
import MoreInfo from './MoreInfo'
import Confirmation from './Confirmation'
import {CreateSellRequestData} from './types'
import Details from './Details'
import {buildSellRequest} from './utils'

export interface CreateSellRequestProps {
  product: Product
  loading?: boolean
  testID?: string
  fromShop?: boolean
  onDone?: (data: CreateSellRequest) => void
}

const CreateSellRequest = ({
  product,
  loading,
  fromShop,
  onDone,
  testID,
}: CreateSellRequestProps) => {
  const [, scrollTo] = useWindowScroll()
  const {isSm, isXs} = useBreakpoint()
  const [isSaving, setIsSaving] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [stepData, setStepData] = useState<CreateSellRequestData>({})

  useDidUpdate(() => {
    setActiveStep(0)
    setStepData({})
  }, [product?.id])

  useEffect(() => {
    if (activeStep) scrollTo({y: isXs ? 380 : isSm ? 360 : 0})
  }, [activeStep])

  const gotoStep = (increment: number) => {
    setActiveStep((current) => (current < 5 ? current + increment : current))
  }

  const prevStep = (name: keyof CreateSellRequestData, data: Record<string, any>) => {
    gotoStep(-1)
    setStepData((val) => ({...val, [name]: data}))
  }

  const nextStep = (name: keyof CreateSellRequestData, data: Record<string, any>) => {
    gotoStep(1)
    setStepData((val) => ({...val, [name]: data}))
  }

  const handleDone = async (data: CreateSellRequestData) =>
    onDone?.(await buildSellRequest(product!, data))

  const allowStepSelect = (step: number) => !isSaving && activeStep > step

  if (!activeStep)
    return (
      <Details
        product={product}
        loading={loading}
        estimate={!fromShop}
        onNext={(data) => nextStep('details', data)}
        testID={formatTestID(testID, 'estimate')}
      />
    )

  return (
    <Stepper active={activeStep} onStepClick={setActiveStep} size="md">
      <Stepper.Step
        allowStepSelect={allowStepSelect(0)}
        label="Details"
        icon={<Icon name="fileDescription" size="lg" />}
      >
        <div />
      </Stepper.Step>
      <Stepper.Step
        allowStepSelect={allowStepSelect(1)}
        label="Photos"
        icon={<Icon name="photo" size="lg" />}
      >
        <Photos
          hideMore={fromShop}
          initialValues={stepData.photos}
          onNext={(data) => nextStep('photos', data)}
          onBack={(data) => prevStep('photos', data)}
          testID={formatTestID(testID, 'photos')}
        />
      </Stepper.Step>
      <Stepper.Step
        allowStepSelect={allowStepSelect(2)}
        label="Issues"
        icon={<Icon name="tool" size="lg" />}
      >
        <Issues
          product={product}
          initialValues={stepData.issues}
          onNext={(data) => {
            if (fromShop) {
              handleDone?.({...stepData, issues: data})
            } else {
              nextStep('issues', data)
            }
          }}
          onBack={(data) => prevStep('issues', data)}
          testID={formatTestID(testID, 'issues')}
        />
      </Stepper.Step>
      {!fromShop && (
        <Stepper.Step
          allowStepSelect={allowStepSelect(3)}
          label="More Info"
          icon={<Icon name="infoSquare" size="lg" />}
        >
          <MoreInfo
            initialValues={stepData.moreInfo}
            onNext={(data) => nextStep('moreInfo', data)}
            onBack={(data) => prevStep('moreInfo', data)}
            testID={formatTestID(testID, 'more-info')}
          />
        </Stepper.Step>
      )}
      {!fromShop && (
        <Stepper.Completed>
          <Confirmation
            data={stepData}
            product={product}
            onBack={() => gotoStep(-1)}
            onSaveEnd={() => setIsSaving(false)}
            onSaveStart={() => setIsSaving(true)}
            testID={formatTestID(testID, 'confirmation')}
          />
        </Stepper.Completed>
      )}
    </Stepper>
  )
}

export default CreateSellRequest
