import {Notification} from 'model'
import {firebaseQueryBuilder} from '@resellam/firebase'
import {useMemo} from 'react'
import {useCollectionData} from 'react-firebase-hooks/firestore'
import {useAuth} from '@resellam/auth'

export const useQueryNotifications = () => {
  const {user} = useAuth()

  const notificationsQuery = firebaseQueryBuilder<Notification>('notifications', {
    orderBy: useMemo(() => [{field: 'createdAt', direction: 'desc'}], []),
    where: useMemo(
      () => [
        {field: 'userId', operator: '==', value: user?.id},
        {field: 'read', operator: '==', value: false},
      ],
      [user?.id],
    ),
    limit: 50,
  })
  const [data, loading, error] = useCollectionData<Notification>(
    !user?.id ? null : notificationsQuery,
  )
  return useMemo(
    () => ({
      data,
      loading,
      error,
    }),
    [data, loading, error],
  )
}
