import {MantineColor, MantineSize, useMantineTheme} from '@mantine/core'
import {useMemo} from 'react'
import icons from './icons'

export type IconName = keyof typeof icons

const sizes = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 28,
}

export interface IconProps {
  name: IconName
  size?: MantineSize | number
  color?: MantineColor
  thickness?: number
  testID?: string
}

const Icon = ({name, size = 'md', thickness = 1.5, color, testID}: IconProps) => {
  const theme = useMantineTheme()

  const colorVal = useMemo(() => {
    if (color && theme.colors[color]) {
      return theme.variantColorResolver({color, variant: 'outline', theme}).color
    }
    return color
  }, [color])

  const Element = icons[name]
  return (
    <Element
      data-testid={testID}
      strokeWidth={thickness}
      color={colorVal}
      size={typeof size === 'number' ? size : sizes[size]}
    />
  )
}

export default Icon
