import {Enum} from 'model'
import {CollectionName, useQueryDocuments} from '@resellam/firebase'
import {useMemo} from 'react'
import {EnumChipsSelect, EnumChipsSelectProps} from '../EnumChipsSelect'

export interface FirestoreEnumChipsSelectProps extends Omit<EnumChipsSelectProps, 'data'> {
  collection: CollectionName
  testID?: string
}

const FirestoreEnumChipsSelect = ({collection, ...rest}: FirestoreEnumChipsSelectProps) => {
  const {data = [], isLoading} = useQueryDocuments<Enum>({
    collection,
    orderBy: useMemo(() => [{field: 'order', direction: 'asc'}], []),
  })
  return <EnumChipsSelect loading={isLoading} {...rest} data={data} />
}

export default FirestoreEnumChipsSelect
