import {Product, SellRequestVariant} from 'model'
import {useAcceptOffer} from 'lib/sell-request'
import {trackProducts} from 'lib/analytics'
import {useSellRequest} from '@resellam/sell-request'
import {SellRequestOfferLayout} from '../SellRequestOfferLayout'

export type SellRequestSelectedOfferData = {
  price: number
  swap?: {
    product: Product
    variants?: SellRequestVariant[]
  }
}

export interface SellRequestSelectedOfferProps {
  onBack: () => void
  offer: SellRequestSelectedOfferData
  testID?: string
}

const SellRequestSelectedOffer = ({onBack, offer, testID}: SellRequestSelectedOfferProps) => {
  const {sellRequest} = useSellRequest()
  const {product, variants} = sellRequest || {}
  const [acceptOffer, acceptOfferState] = useAcceptOffer()

  const title = offer.swap ? 'Swap' : 'Sell'

  const handleAcceptOffer = () => {
    if (!sellRequest?.product) return

    acceptOffer({
      offer: {
        ...offer,
        swap: offer.swap
          ? {
              variants: offer.swap?.variants,
              productId: offer.swap?.product.id,
            }
          : undefined,
      },
    })

    if (offer.swap?.product) {
      trackProducts('purchase', {
        transaction_id: sellRequest?.id,
        affiliation: 'Resellam shop',
        value: offer.swap.product?.price,
        currency: 'NGN',
        tax: 0,
        shipping: sellRequest?.pickup?.payment?.amount,
        products: [
          {
            ...offer.swap.product,
            quantity: 1,
            variant: offer.swap.variants?.map((variant) => variant.value).join(', '),
          },
        ],
      })
    }

    trackProducts('sale', {
      transaction_id: sellRequest?.id,
      affiliation: 'Resellam shop',
      value: offer.price,
      currency: 'NGN',
      tax: 0,
      shipping: sellRequest?.pickup?.payment?.amount,
      products: [
        {
          ...sellRequest.product,
          quantity: 1,
          variant: sellRequest.variants?.map((variant) => variant.value).join(', '),
        },
      ],
    })
  }

  return (
    <SellRequestOfferLayout
      swap={!!offer.swap}
      price={offer.price}
      product={offer.swap ? offer.swap?.product : product}
      variants={offer.swap ? offer.swap?.variants : variants}
      onBack={onBack}
      cardLoading={acceptOfferState.isRunning}
      confirm={{
        children: `${title} Now`,
        onClick: handleAcceptOffer,
      }}
      testID={testID}
    />
  )
}

export default SellRequestSelectedOffer
