import {z} from 'zod'
import {zodResolver} from 'ui/form'
import {useForm} from 'core/hooks'
import {useRef, useState} from 'react'
import {formatTestID} from 'core/utils'
import {StepNavButtons, StepHeader, BooleanField} from 'components'
import {MoreInfoFormData} from '../types'
import Upgrade, {UpgradeRef} from '../Upgrade'

export interface MoreInfoProps {
  initialValues?: MoreInfoFormData
  onBack: (data: MoreInfoFormData) => void
  onNext: (data: MoreInfoFormData) => void
  testID?: string
}

const schema = z
  .object({
    isUpgrade: z.boolean({required_error: 'Select an option'}),
    upgrade: z.any().optional(),
  })
  .strict()

const MoreInfo = ({initialValues, onBack, onNext, testID}: MoreInfoProps) => {
  const [loading, setLoading] = useState(false)
  const upgradeRef = useRef<UpgradeRef>({
    validate: () => ({hasErrors: false}),
    values: () => undefined,
    safeValues: () => undefined,
  })

  const form = useForm({
    initialValues: {
      ...initialValues,
      isUpgrade: initialValues?.upgrade ? true : undefined,
    },
    validate: zodResolver(schema),
  })

  const isUpgrade = !!form.values.isUpgrade

  const back = () => {
    onBack({
      upgrade: isUpgrade ? upgradeRef.current?.safeValues() : undefined,
    })
  }

  const next = () => {
    if (
      [form.validate().hasErrors, isUpgrade && upgradeRef.current?.validate().hasErrors].some(
        (val) => val,
      )
    )
      return
    onNext({
      upgrade: isUpgrade ? upgradeRef.current?.values() : undefined,
    })
  }

  return (
    <>
      <StepHeader title="More Info" testID={formatTestID(testID, 'header')} />
      <BooleanField
        {...form.getInputProps('isUpgrade')}
        required
        size="lg"
        label="Do you want to swap / trade-in with another device?"
        value={form.values.isUpgrade}
        testID={formatTestID(testID, 'is-upgrade')}
      >
        <Upgrade
          ref={upgradeRef}
          initialValues={initialValues?.upgrade}
          onLoading={setLoading}
          testID={formatTestID(testID, 'upgrade')}
        />
      </BooleanField>
      <StepNavButtons
        back={{onClick: back}}
        next={{onClick: next, disabled: loading}}
        testID={formatTestID(testID, 'nav-buttons')}
      />
    </>
  )
}

export default MoreInfo
