import {FirestoreDataConverter, updateDoc} from 'firebase/firestore'
import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {CollectionName, getDocumentRef} from '../../utils/helpers'
import {defaultConverter} from '../../utils/default-converter'

interface UpdateDocument<T = Record<string, any>> {
  collection: CollectionName
  id: string
  data: Partial<T>
  converter?: FirestoreDataConverter<T>
}

const updateDocument = async <T = Record<string, any>>(options: UpdateDocument<T>): Promise<T> => {
  const {collection, id, data} = options
  const docRef = getDocumentRef(collection, id).withConverter(options.converter || defaultConverter)
  await updateDoc(docRef, data as T)
  return {id, ...data} as unknown as T
}

export const useUpdateDocument = <T = Record<string, any>>(): [
  (input: UpdateDocument<T>) => Promise<T | null>,
  TaskState<T>,
] => {
  const [taskFn, taskState] = useTask<T>()
  const run = (data: UpdateDocument<T>) => taskFn(() => updateDocument(data))
  return [run, taskState]
}
