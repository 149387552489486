import {CatalogueCard, CatalogueCardProps} from 'components'
import {Image} from 'core/components'
import {useBreakpoint} from 'core/hooks'
import {ProductPart} from 'model'
import {forwardRef} from 'react'
import {Text, Stack, UnstyledButton} from 'ui/core'
import classes from './ProductPartCard.module.css'

interface ProductPartCardProps extends Omit<CatalogueCardProps, 'children'> {
  productPart: ProductPart
  selected?: boolean
  description?: string
}

const ProductPartCard = forwardRef<HTMLButtonElement, ProductPartCardProps>(
  ({productPart, selected, description, ...rest}, ref) => {
    const {isXs} = useBreakpoint()
    return (
      <CatalogueCard
        {...rest}
        ref={ref}
        scale={false}
        component={UnstyledButton}
        className={selected ? classes.cardSelected : undefined}
      >
        <Stack align="center" gap="sm">
          {productPart.image && (
            <Image
              unoptimized
              src={productPart.image}
              alt={productPart.name}
              width={isXs ? 80 : 100}
              height={isXs ? 80 : 100}
            />
          )}
          <Text align="center">{productPart.name}</Text>
          {description && (
            <Text align="center" color="gray" size="sm" wordBreak="break-word">
              {description}
            </Text>
          )}
        </Stack>
      </CatalogueCard>
    )
  },
)

export default ProductPartCard
