import {Carousel, CarouselProps} from 'ui/carousel'
import {WheelGesturesPlugin} from 'embla-carousel-wheel-gestures'
import {ReactNode, useRef} from 'react'
import {useId} from 'ui/hooks'
import {formatTestID} from 'core/utils'
import classes from './RowCarousel.module.css'

interface RowCarouselProps<T> extends Omit<CarouselProps, 'children'> {
  items: T[]
  children: (item: T) => ReactNode
  testID?: string
}

const RowCarousel: <T>(props: RowCarouselProps<T>) => JSX.Element = ({
  items,
  slideSize,
  children,
  testID,
  ...rest
}) => {
  const carouselId = useId()
  const wheelGesturesRef = useRef(WheelGesturesPlugin())
  return (
    <Carousel
      data-testid={testID}
      withIndicators={false}
      slideGap="md"
      slideSize={{
        base: '100%',
        sm: `${100 / 2}%`,
        md: `${100 / 3}%`,
        lg: `${100 / 4}%`,
        ...(typeof slideSize === 'object' ? slideSize : {}),
      }}
      dragFree
      withControls
      containScroll="trimSnaps"
      nextControlProps={{'aria-label': 'Next'}}
      previousControlProps={{'aria-label': 'Previous'}}
      classNames={{
        viewport: classes.viewport,
        control: classes.control,
      }}
      {...rest}
      plugins={[wheelGesturesRef.current]}
    >
      {items.map((item, index) => (
        <Carousel.Slide
          key={`${carouselId}-${index}`}
          data-testid={formatTestID(testID, `slide-${index}`)}
        >
          {children(item)}
        </Carousel.Slide>
      ))}
    </Carousel>
  )
}

export default RowCarousel
