import {LoadingOverlay, Box} from 'ui/core'
import {DateTime, DetailList} from 'core/components'
import analytics from 'lib/analytics'
import {RequestPickupData, useRequestPickup} from 'lib/sell-request'
import {useNotifications} from 'core/hooks'
import {paymentMethodMap, formatTestID} from 'core/utils'
import {Alert, ShippingAddress, StepNavButtons} from 'components'
import {useMemo} from 'react'

export interface SellRequestPickupRequestProps {
  data: RequestPickupData
  onBack: () => void
  onNext: () => void
  testID?: string
}

const SellRequestPickupRequest = ({
  testID,
  data,
  onNext,
  onBack,
}: SellRequestPickupRequestProps) => {
  const notifications = useNotifications()
  const [requestPickup, requestPickupState] = useRequestPickup()

  const submit = async () => {
    analytics.track('add_sell_request_pickup', {
      category: 'ecommerce',
    })

    try {
      if (
        await requestPickup({
          pickup: data,
        })
      )
        onNext()
    } catch (error) {
      notifications.showNotification({
        title: 'Error',
        message: 'Failed to request pickup',
      })
    }
  }

  const items = useMemo(
    () => [
      {
        label: 'Date',
        value: <DateTime withIcon value={data.date} testID={formatTestID(testID, 'date')} />,
      },
      ...(data.payment?.method
        ? [
            {
              label: 'Payment',
              value: paymentMethodMap[data.payment?.method]?.title,
              iconName: paymentMethodMap[data.payment?.method]?.icon,
              testID: formatTestID(testID, 'payment-method'),
            },
          ]
        : []),
      ...(data.address
        ? [
            {
              label: 'Address',
              value: (
                <ShippingAddress
                  selected
                  address={data.address}
                  testID={formatTestID(testID, 'address')}
                />
              ),
            },
          ]
        : []),
    ],
    [data],
  )

  return (
    <Box pos="relative" data-testid={testID}>
      <LoadingOverlay visible={requestPickupState.isRunning} />
      <DetailList items={items} />
      <Alert mt="md" alertStyle="warning" title="Disclaimer">
        By selecting &quot;Request Pickup&quot;, I agree to release my device to Resellam and get
        paid after Resellam confirms my device condition matches the information I provided
        otherwise my device will be returned and I am liable for the cost of delivery fee.
      </Alert>
      <StepNavButtons
        size="md"
        back={{onClick: onBack}}
        next={{onClick: submit, children: 'Request Pickup'}}
        testID={formatTestID(testID, 'step-nav')}
      />
    </Box>
  )
}

export default SellRequestPickupRequest
