import {Card} from 'ui/core'
import {formatTestID} from 'core/utils'
import {SellRequestOfferDetails, SellRequestOfferDetailsProps} from '../SellRequestOfferDetails'
import {SellRequestOfferHeader, SellRequestOfferHeaderProps} from '../SellRequestOfferHeader'
import {SellRequestOfferButton, SellRequestOfferButtonProps} from '../SellRequestOfferButton'

export interface SellRequestOfferProps {
  headerProps: SellRequestOfferHeaderProps
  detailProps: SellRequestOfferDetailsProps
  accept: SellRequestOfferButtonProps
  testID?: string
}

const SellRequestOffer = ({headerProps, detailProps, accept, testID}: SellRequestOfferProps) => (
  <Card pos="relative" pb={74} maw={350} data-testid={testID}>
    <SellRequestOfferHeader {...headerProps} testID={formatTestID(testID, 'offer-header')} />
    <SellRequestOfferDetails {...detailProps} testID={formatTestID(testID, 'offer-details')} />
    <SellRequestOfferButton {...accept} testID={formatTestID(testID, 'accept-button')} />
  </Card>
)

export default SellRequestOffer
