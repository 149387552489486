import {Chip, Select, FormField, Group} from 'ui/core'
import {ReactNode} from 'react'
import {formatTestID} from '../../utils'
import {ColorChip} from './ColorChip'

export interface ProductVariantInputProps {
  label?: string
  required?: boolean
  disabled?: boolean
  error?: string | ReactNode
  value?: string
  inputType?: 'dropdown' | 'select' | 'color' | string
  options: {label: string; value: string}[]
  onChange?: (value: string | null) => void
  testID?: string
}

const ProductVariantInput = ({
  label,
  required,
  options,
  inputType,
  error,
  disabled,
  value,
  onChange,
  testID,
}: ProductVariantInputProps) => {
  if (inputType === 'dropdown')
    return (
      <Select
        required={required}
        label={label}
        placeholder={label}
        data={options}
        value={value}
        error={error}
        disabled={disabled}
        onChange={onChange}
        data-testid={testID}
      />
    )

  const handleChipGroupChange = (chipValue: string | string[]) => {
    onChange?.(Array.isArray(chipValue) ? chipValue[0] : chipValue)
  }

  if (inputType === 'color')
    return (
      <FormField required={required} label={label} error={error} testID={testID}>
        <Chip.Group value={value} onChange={handleChipGroupChange}>
          <Group gap="xs">
            {options.map((option) => (
              <ColorChip
                key={option.value}
                value={option.value}
                data-testid={formatTestID(testID, 'chip', option.value)}
              />
            ))}
          </Group>
        </Chip.Group>
      </FormField>
    )

  return (
    <FormField required={required} label={label} error={error} testID={testID}>
      <Chip.Group value={value} onChange={handleChipGroupChange}>
        <Group gap="xs">
          {options.map((option) => (
            <Chip
              variant="outline"
              disabled={disabled}
              key={option.value}
              value={option.value}
              data-testid={formatTestID(testID, 'chip', option.value)}
            >
              {option.label}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
    </FormField>
  )
}

export default ProductVariantInput
