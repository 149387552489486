import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {useMemo} from 'react'
import {User} from 'firebase/auth'
import {getAuth} from '@resellam/firebase'

const useCheckIsAdmin = (): [(user?: User) => Promise<boolean | null>, TaskState<boolean>] => {
  const [taskFn, taskState] = useTask<boolean>()
  const run = (user: User | undefined = getAuth().currentUser || undefined) =>
    taskFn(async () => {
      const result = await user?.getIdTokenResult(true)
      return !!result?.claims.admin
    })
  return useMemo(() => [run, taskState], [taskState])
}

export default useCheckIsAdmin
