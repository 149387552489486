import {useMemo} from 'react'
import {SellRequest, SellRequestOffer} from 'model'
import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {sellRequestConverter, useSellRequest} from '@resellam/sell-request'
import {useUpdateDocument} from '@resellam/firebase'
import {logger} from '@resellam/logger'
import {serverTimestamp} from 'firebase/firestore'

interface AcceptOffer {
  offer: SellRequestOffer
}

const useAcceptOffer = (): [(data: AcceptOffer) => Promise<boolean | null>, TaskState<boolean>] => {
  const [updateDoc] = useUpdateDocument<SellRequest>()
  const {sellRequest, updateSellRequest} = useSellRequest()
  const [taskFn, taskState] = useTask<boolean>()

  const run = ({offer}: AcceptOffer) =>
    taskFn(async () => {
      if (!sellRequest?.id) throw new Error('Requires sellrequest ID')
      const acceptedOffer = {
        price: offer.price,
        createdAt: serverTimestamp() as any,
        createdBy: offer.createdBy,
        expiresAt: offer.expiresAt,
        swap: offer.swap
          ? {
              variants: offer.swap?.variants,
              productId: offer.swap?.productId,
            }
          : undefined,
      }
      try {
        await updateDoc({
          id: sellRequest?.id,
          data: {
            acceptedOffer,
          },
          collection: 'sell-requests',
          converter: sellRequestConverter,
        })
        updateSellRequest({
          acceptedOffer: {
            ...acceptedOffer,
            createdAt: new Date(),
          },
        })
        return true
      } catch (error) {
        logger.error(error)
        return null
      }
    })

  return useMemo(() => [run, taskState], [run, taskState])
}

export default useAcceptOffer
