import {forwardRef} from 'react'
import {AppShell, AppShellFooterProps, AppShellHeaderProps, AppShellProps} from 'ui/core'

export interface AppLayoutProps extends AppShellProps {
  headerProps: AppShellHeaderProps
  footerProps: AppShellFooterProps
}

const AppLayout = forwardRef(
  ({children, headerProps, footerProps, ...rest}: AppLayoutProps, ref: any) => (
    <AppShell ref={ref} header={{height: 80}} padding="md" {...rest}>
      <AppShell.Header {...headerProps} />
      <AppShell.Main pb="80px">{children}</AppShell.Main>
      <AppShell.Footer pos="relative" py="xl" withBorder={false} {...footerProps} />
    </AppShell>
  ),
)

export default AppLayout
