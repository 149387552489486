import {PaymentMethod, PaymentType} from 'model'
import {IconName} from 'ui/core'

export const paymentMethodMap: Record<PaymentMethod, {icon: IconName; title: string}> = {
  [PaymentMethod.CARD]: {
    icon: 'creditCard',
    title: 'Debit or Credit Card',
  },
  [PaymentMethod.BANK_TRANSFER]: {
    icon: 'buildingBank',
    title: 'Bank Transfer',
  },
}

export const paymentTypeMap: Record<PaymentType, {title: string}> = {
  [PaymentType.NOW]: {
    title: 'Now',
  },
  [PaymentType.ON_DELIVERY]: {
    title: 'On Delivery',
  },
}
