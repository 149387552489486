import {Title, Group} from '@mantine/core'
import {CardActions, CardActionsProps} from '../CardActions'
import {formatTestID} from '../../utils'

export interface CardHeaderProps {
  title: string
  actions?: CardActionsProps['actions']
  testID?: string
}

const CardHeader = ({title, actions, testID}: CardHeaderProps) => (
  <Group
    mb="xs"
    wrap="nowrap"
    justify="space-between"
    align="center"
    pos="relative"
    data-testid={testID}
  >
    <Title order={4} data-testid={formatTestID(testID, 'title')}>
      {title}
    </Title>
    {actions && <CardActions actions={actions} data-testid={formatTestID(testID, 'actions')} />}
  </Group>
)

export default CardHeader
