import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  Query,
  QueryConstraint,
  query,
  where as fsWhere,
  limit as fsLimit,
  orderBy as fsOrderBy,
  OrderByDirection,
  WhereFilterOp,
} from 'firebase/firestore'
import {ref} from 'firebase/storage'
import {getFirestore, getStorage, getAuth} from '../config/client'
import {defaultConverter} from './default-converter'

export type StorageName = 'sell-requests' | 'profile-photos'
export type CollectionName =
  | 'sell-requests'
  | 'sell-request-sell-reasons'
  | 'sell-request-purchase-conditions'
  | 'sell-request-repair-parts'
  | 'sell-request-rejection-reasons'
  | 'users'
  | 'notifications'
  | 'bank-accounts'
  | 'notes'
  | 'note-types'
  | 'contact-methods'
  | 'sell-request-cancellation-reasons'
  | 'addresses'
  | 'orders'
  | 'product-conditions'

export const getDocumentRef = <T>(path: CollectionName, ...segments: string[]) =>
  doc(getFirestore(), path, ...segments) as DocumentReference<T>

export const getCollectionRef = <T>(path: CollectionName, ...segments: string[]) =>
  collection(getFirestore(), path, ...segments) as CollectionReference<T>

export const getStorageRef = (path: StorageName, ...segments: string[]) =>
  ref(getStorage(), `users/${getAuth().currentUser?.uid}/${path}/${segments.join('/')}`)

export const docFetcher = async <T = any>(reference: DocumentReference<T>) => {
  const snap = await getDoc(reference)
  if (!snap.exists()) throw new Error('Not found')
  return snap.data()
}

export const docsFetcher = async <T = any>(queryInput: Query<T>) => {
  const snap = await getDocs(queryInput)
  return snap?.docs.map((document) => document.data())
}

export type OrderByParam = {
  field: string
  direction: OrderByDirection
}

export type WhereParam = {
  field: string
  operator: WhereFilterOp
  value: unknown
}

export type FirebaseQueryProps = {where?: WhereParam[]; limit?: number; orderBy?: OrderByParam[]}

export const firebaseQueryBuilder = <T>(
  collectionName: CollectionName,
  {where, orderBy, limit = 10}: FirebaseQueryProps = {},
) => {
  const constraints: QueryConstraint[] = []
  if (where) {
    where.forEach(({field, operator, value}) => constraints.push(fsWhere(field, operator, value)))
  }
  if (orderBy) {
    orderBy.forEach(({field, direction}) => constraints.push(fsOrderBy(field, direction)))
  }
  constraints.push(fsLimit(limit))
  return query<T>(getCollectionRef(collectionName), ...constraints).withConverter<T>(
    defaultConverter,
  )
}

export const FirebaseErrorCodeMap = {
  'auth/invalid-phone-number': {
    message: 'Phone number is invalid',
  },
  'auth/code-expired': {
    message: 'Verification code expired',
  },
  'auth/invalid-verification-code': {
    message: 'Verification code is invalid',
  },
  'auth/email-already-in-use': {
    message: 'Email address is already in use',
  },
  'auth/phone-number-exists': {
    message: 'Phone number is already in use',
  },
  'auth/popup-closed-by-user': {
    message: 'auth/Popup closed by user',
  },
  'auth/account-exists-with-different-credential': {
    message: 'auth/Account exists with different credential',
  },
  'auth/requires-recent-login': {
    message: 'Requires recent login',
  },
  'auth/internal-error': {
    message: 'Internal error',
  },
}

export type FirebaseErrorCode = keyof typeof FirebaseErrorCodeMap

export const getErrorMessage = (error: any) => {
  const code = error.code as FirebaseErrorCode
  const message = code ? FirebaseErrorCodeMap[code]?.message : undefined
  return message || error.message || 'Unknown error'
}
