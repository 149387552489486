import {Brand, Category, Product} from 'model'
import {Box, Flex, Stack} from 'ui/core'
import {Deals} from 'components'
import {ShopBrands} from '../ShopBrands'
import {ShopCategories} from '../ShopCategories'
import {Carousel} from './Carousel'
import {MyCart} from '../MyCart'

export interface DashboardProps {
  brands: Brand[]
  products: Product[]
  categories: Category[]
}

const Dashboard = ({brands = [], categories = [], products = []}: DashboardProps) => (
  <Box w="100%">
    <Stack gap={48}>
      <Carousel />
      <MyCart />
      <Deals products={products} />
      <Flex w="100%" direction={{base: 'column', sm: 'row'}} gap={{base: 48, sm: 'md'}}>
        <ShopCategories categories={categories} />
        <ShopBrands brands={brands} />
      </Flex>
    </Stack>
  </Box>
)

export default Dashboard
