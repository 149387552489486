import {ReactNode} from 'react'
import {Container, ContainerProps, Box} from 'ui/core'
import {Header} from '../Header'

interface PageProps extends ContainerProps {
  title?: string
  loading?: boolean
  container?: boolean
  onClickBack?: () => void
  children?: ReactNode
}

const Page = ({title, loading, container = true, children, onClickBack, ...rest}: PageProps) => {
  const Element = container ? Container : Box
  return (
    <Element {...rest}>
      {(loading || title) && <Header loading={loading} title={title} onClickBack={onClickBack} />}
      {children}
    </Element>
  )
}

export default Page
