import {createContext} from 'react'
import {ActionSheetProps} from '../../components/ActionSheet'

export type PopupStyle = 'modal' | 'action-sheet'

export interface Popup extends Omit<ActionSheetProps, 'opened' | 'onDismiss' | 'style'> {
  style?: PopupStyle
}

export interface PopupContextValue {
  show: (popup: Popup) => void
  hide: () => void
}

export const PopupContext = createContext<PopupContextValue | undefined>(undefined)

export default PopupContext
