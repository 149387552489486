import {Card, Group, Button, Stack, Checkbox, Flex} from 'ui/core'
import {useCart} from 'lib/cart'
import {CartItem as CartItemModel} from 'lib/cart/types'
import {DiscountedPrice} from 'core/components'
import {ProductImage} from 'components'
import {QuantitySelect} from '../QuantitySelect'
import {InStock} from '../InStock'
import {CartItemVariants} from '../CartItemVariants'
import {CartItemRemoveButton} from '../CartItemRemoveButton'

interface CartItemProps {
  item: CartItemModel
}

const CartItem = ({item}: CartItemProps) => {
  const cart = useCart()
  const isNaijaUsed = item.product.condition === 'Naija Used'

  return (
    <Card>
      <Flex direction="column" gap="sm">
        <Group gap="xs" justify="space-between" align="start">
          <Checkbox
            lh={0}
            label={item.product.title}
            checked={item.checkout}
            onChange={(e) => cart.setItemCheckout(item, e.target.checked)}
          />
          <DiscountedPrice price={item.price} quantity={item.quantity} discount={item.discount} />
        </Group>

        <Group gap="xs" wrap="nowrap">
          <ProductImage size={150} product={item.product} />
          <Stack gap="sm">
            <div>
              <InStock inStock={item.product.inStock} />
            </div>
            <CartItemVariants variants={item.variants} />
            <Group align="center" gap="xs">
              <QuantitySelect
                label="Qty"
                comboboxProps={{
                  withinPortal: true,
                }}
                size="sm"
                value={item.quantity}
                style={{maxWidth: '120px'}}
                disabled={!item.product.inStock || isNaijaUsed}
                styles={{
                  root: {display: 'flex', alignItems: 'center'},
                  wrapper: {maxWidth: '80px', marginLeft: '8px'},
                  label: {marginBottom: '0px'},
                }}
                onChange={(val) => cart.setItemQuantity(item, val)}
              />
              <CartItemRemoveButton
                item={item}
                renderRoot={(props) => <Button {...props} size="sm" variant="outline" />}
              >
                Remove
              </CartItemRemoveButton>
            </Group>
          </Stack>
        </Group>
      </Flex>
    </Card>
  )
}

export default CartItem
