import Link from 'next/link'
import {Box, CardProps, Group, Text} from 'ui/core'
import {CatalogueCard, ProductImage} from 'components'
import {Money} from 'core/components'
import {Product} from 'model'
import {useDiscountedPrice} from 'core/hooks'

interface ProductCardProps extends Omit<CardProps, 'children'> {
  href: string
  product: Product
  imageSize?: number
  withPrice?: boolean
  onClick?: () => void
  testID?: string
}

const ProductCard = ({
  href,
  product,
  imageSize = 200,
  withPrice = true,
  onClick,
  testID,
  ...rest
}: ProductCardProps) => {
  const {title, price = 0, discount} = product
  const {label: discountLabel, price: discountedPrice} = useDiscountedPrice({price, discount})

  return (
    <CatalogueCard testID={testID} component={Link} href={href} onClick={onClick} {...rest}>
      {discountLabel && (
        <Box
          pos="absolute"
          top={0}
          right={0}
          bg="red"
          style={{borderBottomLeftRadius: 8, zIndex: 1}}
          py={8}
          px="xs"
        >
          <Text inline color="white" size="sm" fw="bold">
            {discountLabel}
          </Text>
        </Box>
      )}
      <ProductImage size={imageSize} product={product} />
      <Text mt="xs" align="center" lineClamp={1}>
        {title}
      </Text>
      {withPrice && (
        <>
          {discountedPrice ? (
            <Group gap="xs">
              <Money
                value={price}
                size="md"
                align="center"
                style={{textDecoration: 'line-through'}}
              />
              <Money value={discountedPrice} size="lg" fw="bold" align="center" />
            </Group>
          ) : (
            <Money value={price} size="lg" fw="bold" align="center" />
          )}
        </>
      )}
    </CatalogueCard>
  )
}

export default ProductCard
