import {Menu, IconButton, Icon} from 'ui/core'
import {formatTestID} from 'core/utils'
import {useState} from 'react'
import PhotosActionItem from '../PhotosActionItem'
import {useUploadSellRequestPhotos} from '../../../hooks'

const PhotosAction = ({testID}: {testID?: string}) => {
  const [opened, setOpened] = useState(false)
  const uploadSellRequestPhotos = useUploadSellRequestPhotos()

  return (
    <Menu
      position="bottom-end"
      opened={opened}
      width={200}
      onChange={setOpened}
      closeOnItemClick={false}
      data-testid={testID}
    >
      <Menu.Target>
        <IconButton
          loading={
            uploadSellRequestPhotos.addMorePhotosState.isRunning ||
            uploadSellRequestPhotos.uploadReceiptState.isRunning
          }
          name="photoUp"
          title="Add photos"
          testID={formatTestID(testID, 'add-photos')}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <PhotosActionItem
          multiple={false}
          icon={<Icon name="receipt" color="gray" />}
          onDrop={(files) => {
            uploadSellRequestPhotos.uploadReceipt(files)
            setOpened(false)
          }}
          disabled={uploadSellRequestPhotos.uploadReceiptState.isRunning}
          testID={formatTestID(testID, 'upload-receipt')}
        >
          Upload receipt
        </PhotosActionItem>
        <PhotosActionItem
          multiple
          icon={<Icon name="photo" color="gray" />}
          disabled={uploadSellRequestPhotos.addMorePhotosState.isRunning}
          onDrop={(files) => {
            uploadSellRequestPhotos.addMorePhotos(files)
            setOpened(false)
          }}
          testID={formatTestID(testID, 'add-more-photos')}
        >
          Add more photos
        </PhotosActionItem>
      </Menu.Dropdown>
    </Menu>
  )
}

export default PhotosAction
