import {useQueryNotifications} from 'lib/notification'
import {forwardRef} from 'react'
import {Indicator, IndicatorProps} from 'ui/core'

interface NotificationsIndicatorProps extends IndicatorProps {
  variant?: 'dot' | 'count'
}

const NotificationsIndicator = forwardRef<HTMLDivElement, NotificationsIndicatorProps>(
  ({variant = 'count', ...props}, ref) => {
    const {data} = useQueryNotifications()
    const attrs: Omit<IndicatorProps, 'children'> = {
      position: variant === 'count' ? 'top-end' : 'middle-end',
      size: variant === 'count' ? 22 : undefined,
      label:
        variant === 'count' ? (data?.length && data?.length > 9 ? `9+` : data?.length) : undefined,
      disabled: !data?.length,
    }
    return <Indicator color="red" {...attrs} {...props} ref={ref} />
  },
)

export default NotificationsIndicator
