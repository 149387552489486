import {Text} from 'ui/core'
import {ProductPhotos} from 'core/components'
import {ReactNode} from 'react'
import {formatTestID} from 'core/utils'
import {Photo} from './types'
import PhotoCard from './PhotoCard'
import UploadPhotoCard from './UploadPhotoCard'

interface SidePhotosProps {
  readonly?: boolean
  photos: Photo[]
  error?: ReactNode
  onChange: (file: File | null, index: number) => void
  testID?: string
}

const SidePhotos = ({photos, readonly, error, testID, onChange}: SidePhotosProps) => (
  <div data-testid={testID}>
    <Text mb="xs" caption>
      Upload Photos
    </Text>
    <ProductPhotos
      expanded
      photos={photos}
      renderPhoto={({photo, height, index}) => {
        if (photo.file)
          return (
            <PhotoCard
              key={`PhotoCard-${index}`}
              file={photo.file}
              label={photo.label}
              onDelete={readonly ? undefined : () => onChange(null, index)}
              height={height}
              testID={formatTestID(testID, 'photo-card', photo.label)}
            />
          )
        if (!readonly)
          return (
            <UploadPhotoCard
              key={`UploadPhotoCard-${index}`}
              label={photo.label}
              onChange={(file) => onChange(file, index)}
              height={height}
              error={!!error}
              testID={formatTestID(testID, 'upload-photo-card', photo.label)}
            />
          )
        return null
      }}
    />
  </div>
)

export default SidePhotos
