import {
  Center,
  Loader,
  Card as UICard,
  CardProps as UICardProps,
  createPolymorphicComponent,
} from '@mantine/core'
import {ReactNode, forwardRef} from 'react'
import {formatTestID} from '../../utils'
import {CardHeader, CardHeaderProps} from '../CardHeader'

export interface CardProps extends UICardProps {
  title?: string
  actions?: CardHeaderProps['actions']
  loading?: boolean | ReactNode
  testID?: string
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({title, actions, loading, children, testID, ...rest}, ref) => (
    <UICard ref={ref} data-testid={testID} {...rest}>
      {title && (
        <CardHeader title={title} actions={actions} testID={formatTestID(testID, 'header')} />
      )}
      {loading ? (
        typeof loading === 'boolean' ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          loading
        )
      ) : (
        children
      )}
    </UICard>
  ),
)

export default createPolymorphicComponent<'div', CardProps>(Card)
