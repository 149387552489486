import Compressor from 'compressorjs'
import {IMAGE_MIME_TYPE as UI_IMAGE_MIME_TYPE} from 'ui/dropzone'

export const IMAGE_MIME_TYPE = [...UI_IMAGE_MIME_TYPE, 'image/heic', 'image/heif']

export const compressFile = <T extends File | Blob>(input: T, options?: Compressor.Options) =>
  new Promise<T>((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(input, {
      strict: true,
      checkOrientation: true,
      quality: 0.8,
      mimeType: 'image/jpeg',
      convertTypes: IMAGE_MIME_TYPE,
      convertSize: 1000000,
      ...options,
      success: (result) => resolve(result as T),
      error: reject,
    })
  })
