import {useConfigure as useISConfigure} from 'react-instantsearch-hooks-web'

export const useConfigure = () => {
  useISConfigure({
    filters: 'price>0',
    analytics: true,
    clickAnalytics: true,
    restrictSearchableAttributes: ['title'],
    typoTolerance: false,
    queryType: 'prefixNone',
  })
  return null
}

const Configure = () => {
  useConfigure()
  return null
}

export default Configure
