import {Button, Card, Collapse, Divider, Group, Loader, Stack, Text} from 'ui/core'
import {useCheckout} from 'lib/checkout'
import {Money} from 'core/components'

const CheckoutTotal = () => {
  const checkout = useCheckout()

  return (
    <Card withBorder={false} shadow="md">
      <Stack>
        <Group justify="space-between">
          <Text>Subtotal ({checkout.cart.total.quantity} items)</Text>
          <Money inline fw="bold" value={checkout.cart.total.price} />
        </Group>
        {checkout.data.shipping?.address && (
          <>
            <Group justify="space-between">
              <Text>Shipping Fee</Text>
              {checkout.isCalculatingDeliveryFee ? (
                <Loader type="dots" />
              ) : (
                <Money
                  inline
                  td={checkout.freeDelivery ? 'line-through' : undefined}
                  fw="bold"
                  value={checkout.total.shippingFee || 0}
                />
              )}
            </Group>
          </>
        )}
        <Divider />
        <Group justify="space-between">
          <Text fw="bold">Total</Text>
          <Money inline fw="bold" value={checkout.total.price} />
        </Group>
        <Collapse in={!!checkout.createOrder}>
          <Button
            fullWidth
            onClick={checkout.createOrder}
            loading={checkout.isCreatingOrder || checkout.isCalculatingDeliveryFee}
          >
            Place Order
          </Button>
        </Collapse>
      </Stack>
    </Card>
  )
}

export default CheckoutTotal
