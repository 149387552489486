import {ReactNode} from 'react'
import {Group, HoverCard, Box, ActionIcon, Input, InputWrapperProps} from '@mantine/core'
import {Icon} from '../Icon'

export interface FormFieldProps extends InputWrapperProps {
  helpText?: ReactNode
  testID?: string
}

const FormField = ({
  helpText,
  children,
  error,
  label,
  required,
  testID,
  size = 'md',
  ...rest
}: FormFieldProps) => (
  <Box data-testid={testID} {...rest}>
    <Group justify="space-between">
      <Input.Label required={required} size={size}>
        {label}
      </Input.Label>
      {helpText && (
        <HoverCard
          position="top-end"
          transitionProps={{transition: 'pop-top-left'}}
          width={220}
          styles={{dropdown: {pointerEvents: 'none'}}}
        >
          <HoverCard.Target>
            <ActionIcon size="md" radius="lg" variant="default">
              <Icon name="infoCircle" />
            </ActionIcon>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Box>{helpText}</Box>
          </HoverCard.Dropdown>
        </HoverCard>
      )}
    </Group>
    {children}
    <Input.Error size={size}>{error}</Input.Error>
  </Box>
)

export default FormField
