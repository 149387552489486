import React, {useState} from 'react'
import {useRouter} from 'next/router'
import {AlgoliaProductHit} from 'lib/algolia'
import analytics from 'lib/analytics'
import {Modal, ModalProps} from 'ui/core'
import {useBreakpoint} from 'core/hooks'
import {getProductURL} from './utils'
import {ProductSearchForm} from './ProductSearchForm'
import {useProductSearch} from './useProductSearch'
import {ProductSearchTrigger, ProductSearchTriggerProps} from './ProductSearchTrigger'

interface ProductSearchProps {
  triggerProps?: Partial<ProductSearchTriggerProps>
  transitionProps?: ModalProps['transitionProps']
}

export const ProductSearch = ({triggerProps, transitionProps}: ProductSearchProps) => {
  const router = useRouter()
  const {autocomplete, searchType} = useProductSearch()
  const [isOpen, setIsOpen] = useState(false)
  const {isXs} = useBreakpoint()

  const openSearch = () => setIsOpen(true)

  const closeSearch = () => setIsOpen(false)

  const handleProductClick = (hit: AlgoliaProductHit) => {
    const eventName = searchType === 'Sell' ? 'view_product' : 'view_shop_product'
    analytics.track(eventName, {
      category: 'engagement',
      product_name: hit.title,
      product_brand_name: hit.brand?.title,
      product_category_name: hit.category?.title,
    })

    const url = getProductURL(searchType, hit)
    const isShop = router.pathname === '/shop'

    if (isShop) {
      router.push(url, undefined, {shallow: true})
    } else {
      router.push(url)
    }
    closeSearch()
  }

  return (
    <>
      <ProductSearchTrigger {...triggerProps} onTrigger={openSearch} />
      <Modal
        size="lg"
        radius={isXs ? 0 : 'lg'}
        xOffset={isXs ? 0 : 16}
        yOffset={isXs ? 0 : 16}
        fullScreen={isXs}
        withCloseButton={false}
        opened={isOpen}
        onClose={closeSearch}
        transitionProps={transitionProps}
      >
        <div {...autocomplete.getRootProps({})}>
          <ProductSearchForm onClickProduct={handleProductClick} onCancel={closeSearch} />
        </div>
      </Modal>
    </>
  )
}
