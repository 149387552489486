import {Container, Group, IconButton, Text} from 'ui/core'
import {ReactNode} from 'react'

export interface ModalHeaderProps {
  onBack: () => void
  title: ReactNode
  container?: boolean
}

const ModalHeader = ({onBack, title, container}: ModalHeaderProps) => {
  const content = (
    <Group align="center" style={{position: 'relative', justifyContent: 'center'}}>
      <IconButton
        title="Back"
        name="chevronLeft"
        onClick={onBack}
        style={{position: 'absolute', left: 0}}
      />
      <Text size="xl">{title}</Text>
    </Group>
  )

  return container ? <Container px={0}>{content}</Container> : content
}

export default ModalHeader
