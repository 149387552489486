import {Stack, Text, Title} from 'ui/core'
import {useCheckout} from 'lib/checkout'
import {StepHeader, StepNavButtons, ShippingAddressContent} from 'components'
import analytics from 'lib/analytics'
import {getCartItemID} from 'helpers/shop'
import {formatTestID} from 'ui/utils'
import {paymentMethodMap} from 'core/utils'
import {CheckoutItem} from '../CheckoutItem'
import {InfoCard} from '../InfoCard'

interface CheckoutConfirmationProps {
  testID?: string
}

const CheckoutConfirmation = ({testID}: CheckoutConfirmationProps) => {
  const checkout = useCheckout()

  const address = checkout.data.shipping?.address

  const next = async () => {
    analytics.track('shop_place_order', {
      category: 'engagement',
    })
    await checkout.createOrder?.()
  }

  const back = () => {
    checkout.prevStep()
  }

  return (
    <>
      <StepHeader title="Confirmation" testID={formatTestID(testID, 'title')} />
      <Stack>
        <Stack gap={4}>
          <Title order={4}>Shipping</Title>
          <InfoCard onChange={() => checkout.goToStep('shipping')}>
            {address ? (
              <ShippingAddressContent address={address} />
            ) : (
              <Text lineClamp={1} transform="capitalize">
                InStore Pickup
              </Text>
            )}
          </InfoCard>
        </Stack>
        <Stack gap={4}>
          <Title order={4}>Payment</Title>
          {checkout.data.payment?.method && (
            <InfoCard onChange={() => checkout.goToStep('payment')}>
              <Text lineClamp={1}>{paymentMethodMap[checkout.data.payment?.method].title}</Text>
            </InfoCard>
          )}
        </Stack>
        <Stack gap={4}>
          <Title order={4}>Items ({checkout.cart.total.quantity})</Title>
          <Stack gap="xs">
            {checkout.cart.items.map((item) => (
              <CheckoutItem key={getCartItemID(item)} item={item} />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <StepNavButtons
        next={{
          onClick: next,
          children: 'Place Order',
          loading: checkout.isCreatingOrder || checkout.isCalculatingDeliveryFee,
          disabled: !checkout.createOrder,
        }}
        back={{onClick: back}}
      />
    </>
  )
}

export default CheckoutConfirmation
