import {useMemo} from 'react'
import {Discount} from 'model'
import {calcDiscountedPrice, formatMoney, isValidDiscount} from '../../utils'

const useDiscountedPrice = ({price, discount}: {price?: number; discount?: Discount}) => {
  const valid = useMemo(() => discount && isValidDiscount(discount), [discount])

  const discountLabel = useMemo(() => {
    if (!valid) return undefined
    if (discount?.amount) return `${formatMoney(discount.amount)} Off`
    if (discount?.percentage) return `${discount.percentage}% Off`
    return undefined
  }, [discount, valid])

  const discountedPrice = useMemo(
    () => price && calcDiscountedPrice(price, discount),
    [price, discount, valid],
  )

  return useMemo(
    () => ({label: discountLabel, price: discountedPrice}),
    [discountLabel, discountedPrice],
  )
}

export default useDiscountedPrice
