import dayjs from 'dayjs'
import {Discount} from 'model'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

export const isValidDiscount = (discount: Discount) =>
  discount?.startDate &&
  discount?.endDate &&
  dayjs().isBetween(discount.startDate, discount.endDate, null, '[]')

export const calcDiscountedPrice = (price: number, discount?: Discount) => {
  if (!discount || !isValidDiscount(discount)) return undefined
  if (price && discount?.amount) return price - discount.amount
  if (price && discount?.percentage) return price - (discount.percentage / 100) * price
  return undefined
}
