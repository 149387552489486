import {
  Paper,
  UnstyledButton,
  PaperProps,
  createPolymorphicComponent,
  MantineStyleProp,
} from 'ui/core'
import {ReactNode, forwardRef} from 'react'
import classes from './EmptyPhotoCard.module.css'

export interface EmptyPhotoCardProps extends PaperProps {
  height?: number
  hasPhoto?: boolean
  onClick?: () => void
  style?: MantineStyleProp
  testID?: string
  children?: ReactNode
}

const _EmptyPhotoCard = forwardRef<HTMLDivElement, EmptyPhotoCardProps>(
  ({hasPhoto, height, children, style, testID, ...rest}, ref) => (
    <Paper
      ref={ref}
      component={(hasPhoto ? 'div' : UnstyledButton) as any}
      p={0}
      radius={0}
      withBorder={hasPhoto}
      h={height}
      pos="relative"
      className={hasPhoto ? undefined : classes.card}
      style={style}
      {...rest}
      data-testid={testID}
    >
      {children}
    </Paper>
  ),
)

export default createPolymorphicComponent<'div', EmptyPhotoCardProps>(_EmptyPhotoCard)
