import {useSellRequest} from '@resellam/sell-request'
import {PRODUCT_FIELDS, getSanityClient} from '@resellam/sanity'
import {Product, SellRequestVariant} from 'model'
import {useMemo} from 'react'
import {getVariantsMapping} from 'core/utils'
import {useGetDocument, useQueryDocuments} from '@aonawale/react-sanity-hooks'
import {filter, slice, order} from '@aonawale/sanity-query'

interface SwapOffer {
  price: number
  product: Product
  variants: SellRequestVariant[]
}

const flattenVariants = (product: Product) =>
  (product.variants || []).reduce<SellRequestVariant[]>(
    (acc, cur) => [
      ...acc,
      ...(cur.variantValue || [])
        .map((vv) => ({label: cur.variantName || '', value: vv.key || ''}))
        .filter((val) => val.label && val.value),
    ],
    [],
  )

const calcVariantPricing = (product: Product, upgradeVariants: SellRequestVariant[]) => {
  const productVariantsMap = getVariantsMapping(product)
  return upgradeVariants.reduce<{
    price: number
    variants: SellRequestVariant[]
  }>(
    (acc, cur) => {
      if (!productVariantsMap[cur.label]) return acc

      const sorted = Object.entries(productVariantsMap[cur.label])
        .map<{label: string; value: number}>(([label, value]) => ({label, value: Number(value)}))
        .sort((a, b) => a.value - b.value)

      if (!sorted.length) return acc

      const actual = sorted.find((a) => a.label === cur.value) || sorted[0]

      return {
        price: acc.price + actual.value,
        variants: [...acc.variants, {label: cur.label, value: actual.label}],
      }
    },
    {price: 0, variants: []},
  )
}

const useSwapOffers = () => {
  const {sellRequest} = useSellRequest()
  const {offer, upgrade} = sellRequest || {}
  const offerPrice = offer?.price || 0

  const {data: product, isLoading: isProductLoading} = useGetDocument<Product>(
    getSanityClient(),
    upgrade?.productId ? {id: upgrade?.productId} : undefined,
    PRODUCT_FIELDS,
  )

  const {data: products = [], isLoading: isProductsLoading} = useQueryDocuments<Product>(
    getSanityClient(),
    !product && !upgrade?.title
      ? undefined
      : {
          type: 'product',
          constraints: [
            product
              ? filter('title', '==', product.title)
              : filter('title', 'match', upgrade?.title),
            filter('price', '>', 0),
            filter('inStock', '==', true),
            filter('condition', 'in', ['New', 'UK Used']),
            slice(0, 1, true),
            order('title', 'asc'),
            order('condition', 'asc'),
          ],
          ordering: 'selection',
        },
    PRODUCT_FIELDS,
  )

  const swapOffers = useMemo(
    () =>
      products.reduce<SwapOffer[]>((acc, cur) => {
        if (cur.condition === 'Naija Used')
          return [
            ...acc,
            {
              product: cur,
              price: cur.price ? cur.price - offerPrice : 0,
              variants: flattenVariants(cur),
            },
          ]

        const variantPricing = calcVariantPricing(cur, upgrade?.variants || [])
        const price = (cur.price || 0) + variantPricing.price
        return [
          ...acc,
          {
            product: cur,
            variants: variantPricing.variants,
            price: price - offerPrice,
          },
        ]
      }, []),
    [products],
  )

  return {swapOffers, isLoading: isProductLoading || isProductsLoading}
}

export default useSwapOffers
