import {Product, SellRequest} from 'model'
import {ReactNode, useContext, useMemo, createContext} from 'react'
import {z} from 'zod'
import {UseFormReturnType, zodResolver} from 'ui/form'
import {useForm} from 'core/hooks'
import {isAppleSmartphone, variantsSchema, getFormVariants} from 'core/utils'

type SellRequestDetailsFormValues = Partial<
  Pick<SellRequest, 'variants' | 'imeiNumber' | 'serialNumber' | 'batteryHealth'>
>

export interface SellRequestDetailsFormContextValue {
  form: UseFormReturnType<SellRequestDetailsFormValues>
  schema: z.Schema<SellRequestDetailsFormValues>
}

export const SellRequestDetailsFormContext = createContext<
  SellRequestDetailsFormContextValue | undefined
>(undefined)

export interface SellRequestDetailsFormContextProps {
  children: ReactNode
  product?: Product
  initialValues?: SellRequestDetailsFormValues
}

const SellRequestDetailsFormProvider = ({
  product,
  initialValues,
  children,
}: SellRequestDetailsFormContextProps) => {
  const productVariants = product?.variants?.filter(
    (variant) => variant.variantInputType !== 'color' && variant.variantName !== 'Color',
  )
  const schema = useMemo(
    () =>
      z
        .object({
          variants: variantsSchema(productVariants),
          imeiNumber: z.string().optional(),
          serialNumber: z.string().optional(),
          batteryHealth: z
            .number()
            .optional()
            .refine(
              (val) => (isAppleSmartphone(product) ? val && val >= 49 : true),
              () => ({message: 'Slide on the battery to choose battery health'}),
            ),
        })
        .strict() as any as z.Schema<SellRequestDetailsFormValues>,
    [product],
  )

  const form = useForm<SellRequestDetailsFormValues>({
    initialValues: {
      batteryHealth: initialValues?.batteryHealth,
      imeiNumber: initialValues?.imeiNumber || '',
      serialNumber: initialValues?.serialNumber || '',
      variants: getFormVariants(productVariants, initialValues?.variants),
    } as any as SellRequestDetailsFormValues,
    validate: zodResolver(schema),
  })

  return (
    <SellRequestDetailsFormContext.Provider value={{form, schema}}>
      {children}
    </SellRequestDetailsFormContext.Provider>
  )
}

export default SellRequestDetailsFormProvider

export const useSellRequestDetailsForm = () => {
  const context = useContext(SellRequestDetailsFormContext)
  if (context === undefined) {
    throw new Error('useSellRequestDetailsForm must be used within a SellRequestDetailsFormContext')
  }
  return context
}
