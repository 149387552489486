import {Group, Container, BoxProps} from 'ui/core'
import {siteMetadata} from 'core/utils'
import {AppFooter as CoreAppFooter} from 'core/components'
import FooterLink from './FooterLink'

const links = [
  {
    title: 'FAQs',
    href: '/faqs',
  },
  {
    title: 'Privacy',
    href: '/privacy-policy',
  },
  {
    title: 'Terms',
    href: '/terms-of-service',
  },
  {
    title: 'Contact Us',
    href: `mailto:${siteMetadata.contact.email}`,
  },
]

const AppFooter = (props: BoxProps) => (
  <CoreAppFooter {...props}>
    <Container>
      <Group gap="xl" justify="center" py="xl">
        {links.map((link) => (
          <FooterLink key={link.title} {...link} />
        ))}
      </Group>
    </Container>
  </CoreAppFooter>
)

export default AppFooter
