import {Category} from 'model'

export type GroupedCategory = Category & {subCategories?: GroupedCategory[]}

export const groupCategories = (categories: Category[]) =>
  categories.reduce((result, curr) => {
    const accm = result
    if (curr.group?.id) {
      accm[curr.group.id] = {
        ...curr.group,
        subCategories: [...(accm[curr.group.id]?.subCategories || []), curr],
      }
    } else if (curr.id) {
      accm[curr.id] = curr
    }
    return accm
  }, {} as Record<string, GroupedCategory>)
