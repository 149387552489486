import {Group, Text, IconButton, Icon} from 'ui/core'
import {QuerySuggestionsHit} from '../types'

const SuggestedSearchHit = ({hit}: {hit: QuerySuggestionsHit}) => (
  <Group wrap="nowrap" justify="space-between" align="center">
    <Group gap="xs" align="center">
      <Icon name="search" size="md" color="gray" />
      <Text>{hit.query}</Text>
    </Group>
    <IconButton name="arrowUpLeft" iconSize="md" />
  </Group>
)

export default SuggestedSearchHit
