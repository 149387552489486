import {Product} from 'model'
import {useMemo} from 'react'
import {getVariantsMapping} from 'core/utils/product'

const useProductPrice = ({
  product,
  variants,
}: {
  product?: Product
  variants?: {label: string; value: string}[]
}) => {
  const variantsMapping = useMemo(
    () => (product ? getVariantsMapping(product) : undefined),
    [product],
  )

  return useMemo(() => {
    if (!product?.price) return
    return (
      product.price +
      (variants?.reduce((acc, cur) => acc + (variantsMapping?.[cur.label]?.[cur.value] || 0), 0) ||
        0)
    )
  }, [product, variants])
}

export default useProductPrice
