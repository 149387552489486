import {useEffect, useState} from 'react'
import {IdTokenResult, reauthenticateWithPhoneNumber} from 'firebase/auth'
import {FirebaseAuthProviderID, signInWithPhoneNumber} from '@resellam/auth'
import {getAuth} from '@resellam/firebase'
import {Stack, Text} from 'ui/core'
import {PhoneForm, SocialAuth} from '../AuthForm'

interface ReauthenticateProps {
  onSuccess: () => void
}

const Reauthenticate = ({onSuccess}: ReauthenticateProps) => {
  const [idTokenResult, setIdTokenResult] = useState<IdTokenResult | null>(null)
  const signInProvider = idTokenResult?.signInProvider as FirebaseAuthProviderID

  useEffect(() => {
    ;(async () => {
      const result = await getAuth().currentUser?.getIdTokenResult()
      if (result) setIdTokenResult(result)
    })()
  }, [])

  const getSignInUI = () => {
    const {currentUser} = getAuth()
    if (signInProvider === 'phone') {
      return (
        <PhoneForm
          onSignIn={(phoneNumber, recaptchaVerifier) =>
            currentUser
              ? reauthenticateWithPhoneNumber(currentUser, phoneNumber, recaptchaVerifier)
              : signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
          }
          onSuccess={onSuccess}
        />
      )
    }

    if (signInProvider === 'google.com') {
      return <SocialAuth onSuccess={onSuccess} providers={['google.com']} />
    }

    if (signInProvider === 'facebook.com') {
      return <SocialAuth onSuccess={onSuccess} providers={['facebook.com']} />
    }

    return null
  }

  return (
    <Stack>
      <Text size="lg">Sign in to continue</Text>
      {getSignInUI()}
    </Stack>
  )
}

export default Reauthenticate
