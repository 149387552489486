import {forwardRef, ReactNode} from 'react'
import {ActionIcon, ActionIconProps} from 'ui/core'

interface BottomTabActionIconProps extends ActionIconProps {
  active?: boolean
  children: ReactNode
  onClick?: () => void
}

const BottomTabActionIcon = forwardRef<HTMLButtonElement | null, BottomTabActionIconProps>(
  ({active, children, ...rest}, ref) => (
    <ActionIcon
      size="lg"
      radius="xl"
      variant={active ? 'light' : 'subtle'}
      color={active ? 'blue' : 'gray'}
      {...rest}
      ref={ref}
    >
      {children}
    </ActionIcon>
  ),
)

export default BottomTabActionIcon
