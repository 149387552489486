import {SellRequest} from 'model'
import {Stack} from 'ui/core'
import {DetailItem, SectionHeader} from 'core/components'
import {formatTestID} from 'core/utils'
import {SellRequestIssues} from './SellRequestIssues'

export interface SellRequestProductIssuesProps {
  sellRequest?: Partial<SellRequest>
  testID?: string
}

const SellRequestProductIssues = ({sellRequest, testID}: SellRequestProductIssuesProps) => (
  <div data-testid={testID}>
    <SectionHeader title="Issues" />
    <Stack gap="xs">
      <DetailItem
        label="Damages"
        value={
          <SellRequestIssues
            issues={sellRequest?.damages}
            testID={formatTestID(testID, 'damage')}
          />
        }
      />
      <DetailItem
        label="Repairs"
        value={
          <SellRequestIssues
            issues={sellRequest?.repairs}
            testID={formatTestID(testID, 'repair')}
          />
        }
      />
    </Stack>
  </div>
)

export default SellRequestProductIssues
