import {useMemo, useState, DependencyList, Dispatch, useEffect} from 'react'

const useComputedState = <T>(
  factory: () => T,
  dependecies: DependencyList | undefined,
): [T, Dispatch<T>] => {
  const [state, setState] = useState(factory)
  // eslint-disable-next-line, react-hooks/exhaustive-deps
  useEffect(() => setState(factory()), dependecies)
  return useMemo(() => [state, setState], [state])
}

export default useComputedState
