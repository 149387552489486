import {Skeleton, Stack} from 'ui/core'
import {CatalogueCard} from 'components'

interface ProductCardLoadingProps {
  imageWidth?: number
  imageHeight?: number
}

const ProductCardLoading = ({imageWidth = 200, imageHeight = 200}: ProductCardLoadingProps) => (
  <CatalogueCard>
    <Stack align="center">
      <Skeleton height={imageHeight} width={imageWidth} radius="md" />
      <Skeleton height={20} width={150} radius="xl" />
    </Stack>
  </CatalogueCard>
)

export default ProductCardLoading
