import {useAuth} from '@resellam/auth'
import analytics from 'lib/analytics'
import {useMemo} from 'react'

const useLiveChat = () => {
  const {user} = useAuth()

  const setSessionData = (data: [string, string][]) =>
    window.$crisp.push(['set', 'session:data', [data]])

  const setSessionEvent = (event: [string, Record<string, any>, string | undefined][]) =>
    window.$crisp.push(['set', 'session:event', [event]])

  const open = () => {
    window.$crisp.push(['do', 'chat:open'])
    setSessionData([
      ['page_url', window.location.href],
      ['user_id', user?.id || ''],
    ])
    analytics.track('open_live_chat', {
      category: 'engagement',
    })
  }

  return useMemo(
    () => ({
      open,
      setUserPhoto: (photoURL: string) => window.$crisp.push(['set', 'user:avatar', [photoURL]]),
      setUserEmail: (email: string) => window.$crisp.push(['set', 'user:email', [email]]),
      setUserPhone: (phone: string) => window.$crisp.push(['set', 'user:phone', [phone]]),
      setUserName: (name: string) => window.$crisp.push(['set', 'user:nickname', [name]]),
      setSessionData,
      setSessionEvent,
    }),
    [user],
  )
}

export default useLiveChat
