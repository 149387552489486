import {SellRequestIssue as SellRequestIssueModel} from 'model'
import {SimpleGrid} from 'ui/core'
import {Tumbleweed} from 'core/components'
import {formatTestID} from 'core/utils'
import {SellRequestIssueCard} from '../SellRequestIssueCard'

export interface SellRequestIssuesProps {
  issues?: SellRequestIssueModel[]
  testID?: string
}

const SellRequestIssues = ({issues, testID}: SellRequestIssuesProps) =>
  issues?.length ? (
    <SimpleGrid cols={{base: 2, sm: 3, md: 4, lg: 5}}>
      {issues?.map((issue) => (
        <SellRequestIssueCard
          key={issue.part.id}
          issue={issue}
          testID={formatTestID(testID, 'fault-chip', issue.part.id)}
        />
      ))}
    </SimpleGrid>
  ) : (
    <Tumbleweed />
  )

export default SellRequestIssues
