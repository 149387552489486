export const BRAND_FACET_ATTRIBUTE = 'brand.title'
export const CATEGORY_FACET_ATTRIBUTE = 'category.title'
export const CONDITION_FACET_ATTRIBUTE = 'condition'
export const PRODUCTS_INDEX = 'products'
export const PRODUCTS_QUERY_SUGGESTIONS_INDEX = 'products_query_suggestions'

export const CLICK_EVENT_NAMES = {
  productClicked: 'Product Clicked',
  plpProductClicked: 'PLP: Product Clicked',
  pdpRelatedProductClicked: 'PDP: Related Product Clicked',
  pdpRelatedProductClickedAfterSearch: 'PDP: Related Product Clicked after Search',
  autocompleteItemClicked: 'Autocomplete: Item Clicked',
  autocompleteProductClicked: 'Autocomplete: Product Clicked',
  autocompleteSuggestionClicked: 'Autocomplete: Suggestion Clicked',
}

export const CONVERSION_EVENT_NAMES = {
  pdpProductAddedToCart: 'PDP: Product Added to Cart',
  pdpProductAddedToCartAfterSearch: 'PDP: Product Added to Cart after Search',
  pdpProductBuyNow: 'PDP: Product Buy Now',
  pdpProductBuyNowAfterSearch: 'PDP: Product Buy Now after Search',
}
