import {getAuth} from '@resellam/firebase'
import {useSWR, SWRConfiguration} from '@resellam/hooks'

export const apiFetcher = async (
  input: RequestInfo,
  {headers, ...init}: RequestInit | undefined = {},
) => {
  const idToken = await getAuth().currentUser?.getIdToken()
  return fetch(input, {
    headers: {
      Authorization: idToken || '',
      ...headers,
    },
    ...init,
  }).then((res) => res.json())
}

const useApi = <T>(key?: string | null, options?: SWRConfiguration<T, Error> & {host?: string}) => {
  const {host, ...config} = options || {}
  return useSWR<T>(key ? `${host || ''}/api${key}` : null, apiFetcher, config)
}

export default useApi
