import {Button, Text, Group, Icon} from 'ui/core'
import {SectionHeader} from '../Section'

interface EditUserSectionProps {
  title: string
  value?: string | null
  onAdd?: () => void
  onEdit?: () => void
}

const EditableUserSection = ({title, value, onEdit, onAdd}: EditUserSectionProps) => (
  <div>
    <SectionHeader title={title} />
    {value ? (
      <>
        <Text color="gray">{value}</Text>
        {onEdit && (
          <Button mt="sm" variant="outline" leftSection={<Icon name="edit" />} onClick={onEdit}>
            Change {title.toLowerCase()}
          </Button>
        )}
      </>
    ) : (
      <Group>
        {onAdd && (
          <Button variant="outline" leftSection={<Icon name="plus" />} onClick={onAdd}>
            Add {title.toLowerCase()}
          </Button>
        )}
      </Group>
    )}
  </div>
)

export default EditableUserSection
