import {useSellRequest} from '@resellam/sell-request'
import {PRODUCT_FIELDS, getSanityClient} from '@resellam/sanity'
import {Product} from 'model'
import {useGetDocument} from '@aonawale/react-sanity-hooks'
import {SellRequestOfferLayout} from '../SellRequestOfferLayout'

export interface SellRequestAcceptedOfferProps {
  testID?: string
}

const SellRequestAcceptedOffer = ({testID}: SellRequestAcceptedOfferProps) => {
  const {sellRequest} = useSellRequest()
  const {product, variants, acceptedOffer: offer} = sellRequest || {}

  const {data: swapProduct} = useGetDocument<Product>(
    getSanityClient(),
    offer?.swap?.productId ? {id: offer?.swap?.productId} : undefined,
    PRODUCT_FIELDS,
  )

  if (!offer) return null

  return (
    <SellRequestOfferLayout
      swap={!!offer.swap}
      price={offer.price}
      product={offer.swap ? swapProduct : product}
      variants={offer.swap ? offer.swap?.variants : variants}
      testID={testID}
    />
  )
}

export default SellRequestAcceptedOffer
