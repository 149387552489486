import {Box, BoxProps, SimpleGrid} from 'ui/core'
import {Product} from 'model'
import {DetailItem, SectionHeader} from 'core/components'

export interface ProductFeaturesProps extends BoxProps {
  product: Product
  testID?: string
}

const ProductFeatures = ({product, testID, ...rest}: ProductFeaturesProps) => (
  <Box {...rest} data-testid={testID}>
    <SectionHeader title="Features" />
    <SimpleGrid cols={{base: 1, sm: 2, md: 3, lg: 4}}>
      {product.metas?.map((meta) => (
        <DetailItem
          key={meta.name}
          label={meta.name || 'Unknown'}
          value={meta.value || 'Unknown'}
        />
      ))}
    </SimpleGrid>
  </Box>
)

export default ProductFeatures
