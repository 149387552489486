import type {ReactNode} from 'react'
import {CartProvider} from 'lib/cart'
import {FloatingCart} from 'components/Shop'
import {ShopProvider} from 'lib/shop'
import AppLayout from '../AppLayout'

export interface ShopLayoutProps {
  children: ReactNode
}

const ShopLayout = ({children}: ShopLayoutProps) => (
  <ShopProvider>
    <CartProvider>
      <AppLayout rightSection={<FloatingCart />}>{children}</AppLayout>
    </CartProvider>
  </ShopProvider>
)

export default ShopLayout
