export const BRAND_FIELDS = `
  'id': _id,
  'slug': slug.current,
  'title': title,
  'logo': logo.asset -> url
`

export const CATEGORY_BRAND_FIELDS = `
  'id': _id,
  'slug': slug.current,
  title,
  'brand': brand -> {
    ${BRAND_FIELDS}
  }
`
