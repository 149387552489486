import {SellRequestIssue as SellRequestIssueModel} from 'model'
import {Text, Card, Flex} from 'ui/core'
import {useBreakpoint} from 'core/hooks'
import {forwardRef} from 'react'
import {Image} from 'core/components'

export interface SellRequestIssueCardProps {
  issue: SellRequestIssueModel
  testID?: string
}

const SellRequestIssueCard = forwardRef<HTMLDivElement, SellRequestIssueCardProps>(
  ({issue, testID, ...rest}, ref) => {
    const {isXs} = useBreakpoint()
    return (
      <Card
        component={Flex}
        align="center"
        gap="sm"
        direction="column"
        justify="center"
        ref={ref}
        data-testid={testID}
        {...rest}
      >
        {issue.part.image && (
          <Image
            src={issue.part.image}
            alt={issue.part.name}
            width={isXs ? 80 : 100}
            height={isXs ? 80 : 100}
          />
        )}
        <Text align="center">{issue.part.name}</Text>
        <Text align="center" color="gray" size="sm">
          {issue.description}
        </Text>
      </Card>
    )
  },
)
export default SellRequestIssueCard
