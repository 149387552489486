import {ProductCard, ProductCardLoading} from 'components'
import {trackProducts} from 'lib/analytics'
import {Product} from 'model'
import {RowCarousel} from 'components/RowCarousel'
import {clickedProductIDs} from 'lib/algolia'
import {useEffect, useState} from 'react'
import {SectionTitle} from '../SectionTitle'

const shuffleArray: <T>(items: T[]) => T[] = (items) => {
  const array = [...items]
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

interface DealsProps {
  title?: string
  products: Product[]
}

const Deals = ({title = 'Deals', products}: DealsProps) => {
  const [shuffledProducts, setShuffledProducts] = useState<Product[]>()

  useEffect(() => {
    setShuffledProducts(shuffleArray(products))
  }, [products.length])

  if (shuffledProducts?.length === 0) return null

  return (
    <div>
      <SectionTitle title={title} rightAction={{title: 'Sell all', href: '/shop/deals'}} />
      {shuffledProducts ? (
        <RowCarousel items={shuffledProducts}>
          {(product) => (
            <ProductCard
              href={`/shop/${product.slug}`}
              product={product}
              onClick={() => {
                clickedProductIDs([product.id], 'productClicked')
                trackProducts('shop_view_product', {
                  productsListName: 'Deals',
                  products: [product],
                })
              }}
            />
          )}
        </RowCarousel>
      ) : (
        <RowCarousel items={new Array(4).fill({})}>{() => <ProductCardLoading />}</RowCarousel>
      )}
    </div>
  )
}

export default Deals
