import {Text} from 'ui/core'
import Link from 'next/link'
import {Image} from 'core/components'
import cx from 'clsx'
import classes from './CarouselImage.module.css'

interface CarouselImageProps {
  href: string
  src: string
  caption: string
  className?: string
}

const CarouselImage = ({href, src, caption, className}: CarouselImageProps) => (
  <Link passHref href={href} className={cx(classes.root, className)}>
    <Image fill priority src={src} quality={100} alt={caption} className={classes.image} />
    <Text className={classes.caption}>{caption}</Text>
  </Link>
)

export default CarouselImage
