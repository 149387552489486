import {updateDoc} from 'firebase/firestore'
import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {getDocumentRef, getAuth, getStorageRef, defaultConverter} from '@resellam/firebase'
import {User} from 'model'
import {useMemo} from 'react'
import {uploadBytes} from 'firebase/storage'
import {useSWRConfig} from 'swr'

type UploadUserPhoto = {
  photo: File
}

const uploadUserPhoto = async (userId: string, {photo}: UploadUserPhoto): Promise<User> => {
  const result = await uploadBytes(getStorageRef('profile-photos', userId), photo, {
    customMetadata: {
      userId,
    },
  })
  const data = {
    photo: {
      path: result.ref.fullPath,
      name: result.ref.name,
      contentType: result.metadata.contentType || null,
    },
  }
  const docRef = getDocumentRef('users', userId).withConverter(defaultConverter)
  await updateDoc(docRef, data)
  return {id: userId, ...data}
}

export const useUploadUserPhoto = (): [
  (data: UploadUserPhoto) => Promise<User | null>,
  TaskState<User>,
] => {
  const {mutate} = useSWRConfig()
  const [taskFn, taskState] = useTask<User>()
  const create = (data: UploadUserPhoto) =>
    taskFn(async () => {
      const {currentUser} = getAuth()
      if (!currentUser?.uid) throw new Error('Cannot update unauthenticated User')
      const user = await uploadUserPhoto(currentUser?.uid, data)
      mutate(user.id, (val: User = {} as User) => ({...val, ...user}))
      return user
    })
  return useMemo(() => [create, taskState], [taskState])
}
