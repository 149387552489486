import {Box, Icon, SimpleGrid} from 'ui/core'
import {useState} from 'react'
import {useRouter} from 'next/router'
import BottomTab from './BottomTab'
import MoreMenu from './MoreMenu'

const tabs = [
  {
    title: 'Home',
    children: <Icon name="home2" />,
    href: '/',
  },
  {
    title: 'Shop',
    children: <Icon name="buildingStore" />,
    href: '/shop',
    indicatorProps: {
      inline: true,
      label: 'New',
      size: 20,
      color: 'red',
    },
  },
  {
    title: 'Orders',
    children: <Icon name="shoppingBag" />,
    href: '/orders',
  },
  {
    title: 'Requests',
    children: <Icon name="layoutList" />,
    href: '/sell-requests',
  },
  {
    title: 'More',
    children: <MoreMenu />,
  },
]

const BottomTabs = () => {
  const router = useRouter()
  const [activeTab, setActiveTab] = useState(() => {
    const index = tabs.findIndex(({href}) => href?.split('/')[1] === router.pathname.split('/')[1])
    return index > -1 ? index : 0
  })

  const clickTab = (index: number) => {
    if (tabs[index].href) {
      router.push(tabs[index].href as string)
      setActiveTab(index)
    }
  }

  return (
    <Box
      pb="xl"
      pt="sm"
      px="sm"
      style={{
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 100,
        background: 'white',
        borderTop: '1px solid #e9ecef',
      }}
    >
      <SimpleGrid cols={tabs.length} spacing="md">
        {tabs.map((tab, index) => (
          <BottomTab
            key={tab.title}
            title={tab.title}
            icon={tab.children}
            indicatorProps={tab.indicatorProps}
            active={activeTab === index}
            withAction={!!tab.href}
            onClick={() => clickTab(index)}
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default BottomTabs
