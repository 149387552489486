import {forwardRef} from 'react'
import {createPolymorphicComponent, Card, CardProps} from 'ui/core'
import cx from 'clsx'
import classes from './CatalogueCard.module.css'

export interface CatalogueCardProps extends CardProps {
  onClick?: () => void
  scale?: boolean
  testID?: string
}

const CatalogueCard = forwardRef<HTMLDivElement, CatalogueCardProps>(
  ({children, className, scale = true, testID, ...rest}, ref) => (
    <Card
      p="md"
      className={cx(classes.root, scale ? classes.scale : undefined, className)}
      data-testid={testID}
      {...rest}
      ref={ref}
    >
      {children}
    </Card>
  ),
)

export default createPolymorphicComponent<'div', CatalogueCardProps>(CatalogueCard)
