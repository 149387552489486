import {Box, BoxProps, ButtonProps} from 'ui/core'
import {SellRequestPickupInfo, useSellRequest} from '@resellam/sell-request'
import {useState} from 'react'
import {formatTestID} from 'core/utils'
import {Segments} from 'components'
import {useGetDocument} from '@resellam/firebase'
import {Address} from 'model'
import {SellRequestPickUp} from '../SellRequestPickUp'
import {SellRequestDropoff} from '../SellRequestDropoff'
import {SellRequestDeliveryOption} from '../SellRequestDeliveryOption'
import {formatDeliveryDate} from '../SellRequestStatus.utils'

enum DeliveryMethod {
  PICKUP = 'PICKUP',
  DROPOFF = 'DROPOFF',
}

interface SellRequestDeliveryProps extends BoxProps {
  testID?: string
  confirm?: ButtonProps & {onClick: () => void}
}

const SellRequestDelivery = ({confirm, testID, ...rest}: SellRequestDeliveryProps) => {
  const {sellRequest} = useSellRequest()
  const pickup = sellRequest?.pickup
  const hasPickup = pickup?.payment?.status === 'SUCCESS'
  const [deliveryMethod, setDeliveryMethod] = useState(
    hasPickup ? DeliveryMethod.PICKUP : DeliveryMethod.DROPOFF,
  )

  const {data: address} = useGetDocument<Address>({
    collection: 'addresses',
    id: sellRequest?.pickup?.addressId,
  })

  return (
    <Box {...rest} data-testid={testID}>
      {!sellRequest?.acceptedOffer && confirm && (
        <Segments
          fullWidth
          value={deliveryMethod}
          onChange={(val) => setDeliveryMethod(val as DeliveryMethod)}
          testID={formatTestID(testID, 'segmented-control')}
          data={[
            {
              label: 'Dropoff',
              value: DeliveryMethod.DROPOFF,
              children: (
                <SellRequestDropoff confirm={confirm} testID={formatTestID(testID, 'dropoff')} />
              ),
            },
            {
              label: 'Pickup',
              value: DeliveryMethod.PICKUP,
              children: (
                <SellRequestPickUp
                  testID={formatTestID(testID, 'pickup')}
                  onRequest={confirm.onClick}
                />
              ),
            },
          ]}
        />
      )}

      {sellRequest?.acceptedOffer &&
        (deliveryMethod === DeliveryMethod.DROPOFF ? (
          <SellRequestDeliveryOption
            title="We're expecting you"
            testID={formatTestID(testID, 'delivery-option-dropoff')}
          >
            <SellRequestDropoff testID={formatTestID(testID, 'accepted-dropoff')} />
          </SellRequestDeliveryOption>
        ) : (
          <SellRequestDeliveryOption
            pickupAddress={address}
            title={pickup?.date ? `Arriving ${formatDeliveryDate(pickup.date)}` : 'Arriving soon'}
            testID={formatTestID(testID, 'delivery-option-pickup')}
          >
            {pickup && (
              <SellRequestPickupInfo
                address={address}
                testID={formatTestID(testID, 'accepted-pickup')}
              />
            )}
          </SellRequestDeliveryOption>
        ))}
    </Box>
  )
}

export default SellRequestDelivery
