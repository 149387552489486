import {Button, Icon} from 'ui/core'
import {useClearRefinements} from 'react-instantsearch-hooks-web'

interface ClearRefinementProps {
  attribute: string
}

const ClearRefinement = ({attribute}: ClearRefinementProps) => {
  const clearRefinements = useClearRefinements({includedAttributes: [attribute]})
  return (
    <Button
      p={0}
      leftSection={<Icon name="chevronLeft" size="sm" />}
      size="compact-sm"
      variant="white"
      onClick={() => clearRefinements.refine()}
      styles={{section: {marginRight: '4px'}}}
    >
      Clear
    </Button>
  )
}

export default ClearRefinement
