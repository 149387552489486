import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {PRODUCT_FIELDS, getSanityClient} from '@resellam/sanity'
import {fetch, filter} from '@aonawale/sanity-query'
import {apiFetcher} from 'core/hooks'
import {getVariantsMapping} from 'core/utils/product'
import {Product} from 'model'
import {useMemo} from 'react'

interface EstimatedProductPrice {
  product: Product
  variants?: {label: string; value: string}[]
  batteryHealth?: number
}

export const calcPercentage = (value: number, total: number) => (value / 100) * total

export const calcPrice = ({
  product,
  variants,
  batteryHealth,
  percentage,
}: EstimatedProductPrice & {percentage: number}) => {
  if (!product.price) return null

  const variantsMapping = getVariantsMapping(product)

  const basePrice = calcPercentage(percentage, product.price)
  const batteryHealthPrice =
    batteryHealth && batteryHealth > 80 ? calcPercentage(0.05, product.price) * batteryHealth : 0
  const variantPrice =
    variants?.reduce((acc, cur) => acc + (variantsMapping?.[cur.label]?.[cur.value] || 0), 0) || 0

  return Math.ceil(basePrice + batteryHealthPrice + variantPrice)
}

export const estimateProductPrice = async ({product, ...rest}: EstimatedProductPrice) => {
  if (product.price) return calcPrice({product, ...rest, percentage: 75})

  const products = await fetch<Product[]>(
    getSanityClient(),
    {
      constraints: [
        filter('_type', '==', 'product'),
        filter('title', '==', product.title),
        filter('condition', 'in', ['UK Used', 'Naija Used']),
      ],
    },
    PRODUCT_FIELDS,
  )

  const ukUsed = products.find(({condition}) => condition === 'UK Used')
  if (ukUsed?.price) return calcPrice({...rest, product: ukUsed, percentage: 85})

  const naijaUsed = products.find(({condition}) => condition === 'Naija Used')
  if (naijaUsed?.price) return calcPrice({...rest, product: naijaUsed, percentage: 90})

  const result = await apiFetcher(`/api/products/estimate-price?product_id=${product.id}`)
  if (result?.data?.prices?.length) return Math.max(...result.data.prices)

  return null
}

const useEstimateProductPrice = (): [
  (input: EstimatedProductPrice) => Promise<number | null>,
  TaskState<number>,
] => {
  const [taskFn, taskState] = useTask<number>()
  const run = (input: EstimatedProductPrice) => taskFn(() => estimateProductPrice(input))
  return useMemo(() => [run, taskState], [taskState])
}

export default useEstimateProductPrice
