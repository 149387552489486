import {useMemo} from 'react'
import {useMediaQuery} from 'ui/hooks'
import {useMantineTheme} from 'ui/core'

const useBreakpoint = () => {
  const theme = useMantineTheme()

  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`, true, {
    getInitialValueInEffect: true,
  })
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true, {
    getInitialValueInEffect: true,
  })
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`, true, {
    getInitialValueInEffect: true,
  })
  const isLg = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`, true, {
    getInitialValueInEffect: true,
  })
  const isXl = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`, true, {
    getInitialValueInEffect: true,
  })
  return useMemo(() => ({isXs, isSm, isMd, isLg, isXl}), [isXs, isSm, isMd, isLg, isXl])
}

export default useBreakpoint
