import {Card, Group, Button} from 'ui/core'
import {ReactNode} from 'react'
import {formatTestID} from 'core/utils'

export interface InfoCardProps {
  children: ReactNode
  onChange: () => void
  testID?: string
}

const InfoCard = ({testID, children, onChange}: InfoCardProps) => (
  <Card>
    <Group wrap="nowrap" justify="space-between" align="center">
      {children}
      <Button
        variant="subtle"
        size="compact-sm"
        onClick={onChange}
        data-testid={formatTestID(testID, 'change')}
      >
        Change
      </Button>
    </Group>
  </Card>
)

export default InfoCard
