import {Button, ButtonProps, Card, Center, Group, Icon, IconName} from 'ui/core'
import {useBreakpoint} from 'core/hooks'
import {SearchIcon} from '../SearchIcon'
import {SearchType} from '../types'
import classes from './ProductSearchTrigger.module.css'
import {useProductSearch} from '../useProductSearch'

export interface ProductSearchTriggerProps {
  id?: string
  size?: ButtonProps['size']
  onTrigger?: (val: SearchType) => void
}

const TriggerButton = ({
  iconName,
  ...rest
}: ButtonProps & {iconName: IconName; onClick?: () => void}) => {
  const {isXs} = useBreakpoint()
  return (
    <Button
      fullWidth
      leftSection={<Icon name={iconName} size={isXs ? 'md' : 'lg'} />}
      variant="default"
      className={classes.button}
      {...rest}
    />
  )
}

const ProductSearchTrigger = ({id, size, onTrigger}: ProductSearchTriggerProps) => {
  const {isXs} = useBreakpoint()
  const {setSearchType} = useProductSearch()

  const handleClick = (type: SearchType) => {
    setSearchType(type)
    onTrigger?.(type)
  }

  return (
    <Center>
      <Card id={id} p={8} className={classes.card} withBorder={false} radius="xl">
        <Group className={classes.group} wrap="nowrap">
          <TriggerButton size={size} iconName="shoppingBag" onClick={() => handleClick('Buy')}>
            Buy
          </TriggerButton>
          <TriggerButton size={size} iconName="cash" onClick={() => handleClick('Sell')}>
            Sell
          </TriggerButton>
          {!isXs && (
            <TriggerButton size={size} iconName="repeat" onClick={() => handleClick('Sell')}>
              Swap
            </TriggerButton>
          )}
        </Group>
        {!isXs && <SearchIcon />}
      </Card>
    </Center>
  )
}

export default ProductSearchTrigger
