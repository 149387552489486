import {useMemo, ReactNode, useEffect} from 'react'
import {useAuthState} from 'react-firebase-hooks/auth'
import {User} from 'model'
import {getAuth, useGetDocument} from '@resellam/firebase'
import {LOGIN_PAGE_URL, ORIGIN_QUERY_PARAM} from '../../utils/constants'
import {getRedirectURL, navigateToOrigin} from '../../utils/helpers'
import {useCheckIsAdmin} from '../../hooks'
import {AuthContext, AuthContextValue} from '../AuthContext'

interface AuthProviderProps {
  publicPages?: string[]
  loginPageUrl?: string
  children: ReactNode
}

const AuthProvider = ({
  children,
  publicPages,
  loginPageUrl = LOGIN_PAGE_URL,
}: AuthProviderProps) => {
  const [currentUser, loading] = useAuthState(getAuth())
  const {data: user, error} = useGetDocument<User>({collection: 'users', id: currentUser?.uid})
  const [checkIsAdmin, checkIsAdminState] = useCheckIsAdmin()

  const pathname = typeof window === 'undefined' ? null : window.location.pathname

  useEffect(() => {
    checkIsAdmin()
  }, [currentUser])

  useEffect(() => {
    if (currentUser && pathname && pathname === LOGIN_PAGE_URL) {
      navigateToOrigin()
      return
    }
    if (
      loading ||
      currentUser ||
      pathname === LOGIN_PAGE_URL ||
      (pathname && publicPages?.some((page) => pathname?.split('/')[1] === page.split('/')[1]))
    )
      return
    window?.location.replace(getRedirectURL(loginPageUrl, ORIGIN_QUERY_PARAM))
  }, [loading, currentUser, pathname])

  const authContext: AuthContextValue = useMemo(
    () => ({
      user: user
        ? {
            ...user,
            email: currentUser?.email || undefined,
            phoneNumber: currentUser?.phoneNumber || undefined,
          }
        : undefined,
      isAdmin: !!checkIsAdminState.value,
      loading: loading || (!user && !error && !!currentUser?.uid),
      signout: () => getAuth().signOut(),
    }),
    [user, checkIsAdminState.value, loading],
  )

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
}

export default AuthProvider
