const dateTypes = [
  {
    format: 'timeOnly',
    friendlyTime: false,
    type: 'timeOnly',
    variant: 'date',
  },
  {
    format: 'timeOnly',
    friendlyTime: true,
    type: 'timeOnly',
    variant: 'date',
  },
  {
    format: 'timeOnly',
    friendlyTime: false,
    type: 'timeOnly',
    variant: 'dateTime',
  },
  {
    format: 'timeOnly',
    friendlyTime: true,
    type: 'timeOnly',
    variant: 'dateTime',
  },
  {
    format: 'default',
    friendlyTime: false,
    type: 'defaultNormalDate',
    variant: 'date',
  },
  {
    format: 'default',
    friendlyTime: true,
    type: 'defaultFriendlyDate',
    variant: 'date',
  },
  {
    format: 'short',
    friendlyTime: false,
    type: 'shortNormalDate',
    variant: 'date',
  },
  {
    format: 'short',
    friendlyTime: true,
    type: 'shortFriendlyDate',
    variant: 'date',
  },
  {
    format: 'default',
    friendlyTime: false,
    type: 'defaultNormalDateTime',
    variant: 'dateTime',
  },
  {
    format: 'default',
    friendlyTime: true,
    type: 'defaultFriendlyDateTime',
    variant: 'dateTime',
  },
  {
    format: 'short',
    friendlyTime: false,
    type: 'shortNormalDateTime',
    variant: 'dateTime',
  },
  {
    format: 'short',
    friendlyTime: true,
    type: 'shortFriendlyDateTime',
    variant: 'dateTime',
  },
]
export default dateTypes
