import {getAuth} from '@resellam/firebase'
import {PaymentMethod} from 'model'

export type PaymentType = 'ORDER' | 'SELL_REQUEST_PICKUP'

export const generateReference = () => `${Math.floor(Math.random() * 1000000000 + 1)}`

export const nairaToKobo = (naira: number) => naira * 100

export const getChannels = (method?: PaymentMethod) => {
  const channels: PaystackChannel[] = []
  if (method === PaymentMethod.CARD) {
    channels.push('card')
  } else if (method === PaymentMethod.BANK_TRANSFER) {
    channels.push('bank_transfer')
  }
  return channels
}

// https://paystack.com/pricing
export const calculateFees = (amount: number) => {
  const percentage = (1.5 / 100) * amount
  const standard = amount < 2500 ? 0 : 100
  return Math.min(Math.ceil(percentage + standard), 2000)
}

interface Pay {
  type: PaymentType
  method: PaymentMethod
  amount: number
  reference?: string
  metadata?: object
}

export const pay = async ({
  type,
  method,
  amount,
  reference,
  metadata,
}: Pay): Promise<PaystackTransaction | null> => {
  const currentUser = getAuth().currentUser
  if (!currentUser) throw new Error('Unauthenticated')

  const PaystackPop = (await import('@paystack/inline-js')).default
  const paystack = new PaystackPop()

  return new Promise((resolve) => {
    paystack.newTransaction({
      key: process.env.NEXT_PUBLIC_PAYSTACK_PUBLIC_KEY as string,
      email: currentUser.email || `${currentUser.uid}@resellam.com`,
      amount: nairaToKobo(amount),
      currency: 'NGN',
      ref: reference,
      channels: getChannels(method),
      label: currentUser.displayName || undefined,
      metadata: {
        ...metadata,
        type,
        user_id: currentUser.uid,
      },
      onSuccess: (transaction) => {
        resolve(transaction)
      },
      onCancel: () => {
        resolve(null)
      },
    })
  })
}
