import {doc, setDoc} from 'firebase/firestore'
import {uploadBytes} from 'firebase/storage'
import {SellRequest} from 'model'
import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'
import {getAuth, getCollectionRef, getStorageRef} from '@resellam/firebase'
import {useMemo} from 'react'
import {sellRequestConverter} from '@resellam/sell-request'

export type CreateSellRequest = Pick<
  SellRequest,
  'product' | 'variants' | 'batteryHealth' | 'repairs' | 'damages' | 'upgrade'
> & {
  images: {file: File; isReceipt: boolean; side?: string}[]
}

export const uploadSellRequestImages = (
  images: {file: File | Blob; name: string}[],
  docRefId: string,
) =>
  Promise.all(
    images.map(({file, name}) =>
      uploadBytes(getStorageRef('sell-requests', docRefId, name), file, {
        customMetadata: {
          sellRequestId: docRefId,
        },
      }),
    ),
  )

const createSellRequest = async ({
  images,
  ...sellRequest
}: CreateSellRequest): Promise<SellRequest> => {
  const docRef = doc(getCollectionRef('sell-requests')).withConverter(sellRequestConverter)
  const uploadResult = await uploadSellRequestImages(
    images.map(({file}) => ({file, name: file.name})),
    docRef.id,
  )
  const data = {
    ...sellRequest,
    status: {
      name: 'SUBMITTED',
      createdAt: new Date(),
      createdBy: getAuth().currentUser?.uid,
    },
    images: uploadResult.map((result, index) => ({
      name: result.ref.name,
      fullPath: result.ref.fullPath,
      contentType: result.metadata.contentType || null,
      isReceipt: images[index].isReceipt,
      side: images[index].side,
    })),
  }
  await setDoc(docRef, data)
  return {...data, id: docRef.id} as SellRequest
}

export const useCreateSellRequest = (): [
  (sellRequest: CreateSellRequest) => Promise<SellRequest | null>,
  TaskState<SellRequest>,
] => {
  const [taskFn, taskState] = useTask<SellRequest>()
  const create = (sellRequest: CreateSellRequest) => taskFn(() => createSellRequest(sellRequest))
  return useMemo(() => [create, taskState], [taskState])
}
