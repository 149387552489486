import {Text, Group, Card, Badge, createPolymorphicComponent, CardProps} from 'ui/core'
import {SellRequest} from 'model'
import {forwardRef} from 'react'
import {formatVariantValue} from 'core/utils'
import {SellRequestStatus} from '../SellRequestStatus'

export interface SellRequestCardProps extends Omit<CardProps, 'children'> {
  sellRequest: SellRequest
  compact?: boolean
}

const SellRequestCard = forwardRef<HTMLDivElement, SellRequestCardProps>(
  ({sellRequest, compact, ...rest}, ref) => (
    <Card ref={ref} {...rest}>
      <Group justify="space-between" wrap="nowrap">
        <Text lineClamp={1}>{sellRequest.product?.title}</Text>
        {sellRequest.status?.name && <SellRequestStatus status={sellRequest.status.name} />}
      </Group>
      {!compact && sellRequest.variants?.length ? (
        <Group wrap="nowrap" gap="xs" mt="sm">
          {sellRequest.variants?.map(({value}) => (
            <Badge key={value} color="gray" variant="light">
              {formatVariantValue(value)}
            </Badge>
          ))}
        </Group>
      ) : null}
    </Card>
  ),
)

export default createPolymorphicComponent<'div', SellRequestCardProps>(SellRequestCard)
