import {Text, Stack, Indicator, IndicatorProps} from 'ui/core'
import {ReactNode} from 'react'
import BottomTabActionIcon from './BottomTabActionIcon'

interface BottomTabProps {
  title: string
  active: boolean
  icon: ReactNode
  onClick: () => void
  withAction?: boolean
  indicatorProps?: Omit<IndicatorProps, 'children'>
}

const BottomTab = ({title, active, icon, indicatorProps, withAction, onClick}: BottomTabProps) => (
  <Stack gap={0} align="center" onClick={onClick}>
    {withAction ? (
      <Indicator {...indicatorProps} disabled={!indicatorProps}>
        <BottomTabActionIcon active={active}>{icon}</BottomTabActionIcon>
      </Indicator>
    ) : (
      icon
    )}
    <Text size="sm" color={active ? 'blue' : 'gray'}>
      {title}
    </Text>
  </Stack>
)

export default BottomTab
