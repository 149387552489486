import {Box, BoxProps, Container} from 'ui/core'
import {ReactNode} from 'react'

export interface AppHeaderProps extends BoxProps {
  leftSection?: ReactNode
  centerSection?: ReactNode
  rightSection?: ReactNode
  className?: string
}

const AppHeader = ({leftSection, centerSection, rightSection, style, ...rest}: AppHeaderProps) => (
  <Container
    {...rest}
    style={[
      {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
      },
      style,
    ]}
  >
    <Box style={{flexShrink: 0}}>{leftSection}</Box>
    <Box
      style={{
        width: '100%',
        maxWidth: 600,
        paddingLeft: 'var(--mantine-spacing-sm)',
        paddingRight: 'var(--mantine-spacing-sm)',
      }}
    >
      {centerSection}
    </Box>
    <Box style={{flexShrink: 0}}>{rightSection}</Box>
  </Container>
)

export default AppHeader
