import {createPolymorphicComponent, Stack, Text} from 'ui/core'
import {CatalogueCard, CatalogueCardProps} from 'components'
import {forwardRef} from 'react'
import {Image} from 'core/components'

export interface CategoryCardProps extends Omit<CatalogueCardProps, 'children'> {
  image?: string
  title?: string
  imageWidth?: number
  imageHeight?: number
  onClick?: () => void
}

const CategoryCard = forwardRef<HTMLDivElement, CategoryCardProps>(
  ({onClick, image, imageWidth = 100, imageHeight = 100, title, ...rest}, ref) => (
    <CatalogueCard {...rest} ref={ref} onClick={onClick}>
      <Stack align="center" gap="xs">
        <Image src={image} alt={title || ''} width={imageWidth} height={imageHeight} />
        {title && <Text align="center">{title}</Text>}
      </Stack>
    </CatalogueCard>
  ),
)

export default createPolymorphicComponent<'div', CategoryCardProps>(CategoryCard)
