const siteName = 'Resellam'
const siteNameLower = siteName.toLowerCase()

export const siteMetadata = {
  name: siteName,
  // title: `Sell used phone, laptop, furniture, and appliances`,
  title: `Buy, Swap, Sell New and Used Phones, Laptops, Gadgets.`,
  description:
    'Buy, Swap or Sell New, UK Used and Naija Used Phones, Laptops, Gadgets and get paid instantly from the comfort of your home.',
  // description:
  //   'Get paid instantly for your used or new phones, laptops, appliances, furnitures and more from the comfort of your home.',
  author: `@${siteName}`,
  siteUrl: `https://www.${siteNameLower}.com`,
  contact: {
    email: `hello@${siteNameLower}.com`,
    phoneNumber: '2348034674046',
    formattedPhoneNumber: '08034674046',
    address: {
      name: '20 Eric Manuel Crescent, Surulere, Lagos',
      url: 'https://www.google.com/maps/place/20+Eric+Manuel+Cres,+Surulere+101241,+Lagos/@6.490483,3.3586978,17z/data=!3m1!4b1!4m5!3m4!1s0x103b8c72e5469f0b:0x4f11732130d61b14!8m2!3d6.4904777!4d3.3608865',
    },
  },
  social: [
    {
      link: `https://www.linkedin.com/company/${siteNameLower}`,
      name: 'Linkedin',
    },
    {
      link: `https://twitter.com/${siteName}`,
      name: 'Twitter',
    },
    {
      link: `https://www.instagram.com/${siteNameLower}hq`,
      name: 'Instagram',
    },
    {
      link: `https://www.facebook.com/${siteNameLower}hq`,
      name: 'Facebook',
    },
  ],
}
