import {TaskState} from '@resellam/hooks'
import {useNotifications} from 'core/hooks'
import {useFeatureFlag} from 'core/services'
import {getAgentForCategory} from 'core/utils'
import {useAuth} from 'hooks/use-auth'
import {trackProducts} from 'lib/analytics'
import {useCart} from 'lib/cart'
import {Cart} from 'lib/cart/types'
import {usePickupEstimate, UsePickupEstimateResult} from 'lib/errandlr'
import {Order, ShippingMethod, PaymentType} from 'model'
import {useRouter} from 'next/router'
import {ReactNode, createContext, useContext, useState, useEffect} from 'react'
import {CheckoutData, CheckoutTotal} from './types'
import useCreateOrder, {CreateOrderResult} from './use-create-order'

export interface CheckoutContextValue {
  activeStep: number
  checkoutData: CheckoutData
  pickupEstimate?: TaskState<UsePickupEstimateResult, Error>
  createOrderState?: TaskState<CreateOrderResult, Error>
  setActiveStep: (state: number) => void
  setCheckoutData: (state: CheckoutData) => void
  total: CheckoutTotal
  cart: Cart
  createOrder: () => Promise<Order | null>
  freeDelivery?: boolean
}

export const CheckoutContext = createContext<CheckoutContextValue | undefined>(undefined)

interface CheckoutProviderProps {
  children: ReactNode
}

const CheckoutProvider = (props: CheckoutProviderProps) => {
  const auth = useAuth()
  const router = useRouter()
  const featureFlag = useFeatureFlag()
  const notifications = useNotifications()
  const [activeStep, setActiveStep] = useState(0)
  const [checkoutData, setCheckoutData] = useState<CheckoutData>({})
  const [pickupEstimate, pickupEstimateState] = usePickupEstimate()
  const [createOrder, createOrderState] = useCreateOrder()
  const cart = useCart({checkout: true, total: {checkout: true}})

  const freeDelivery = !!featureFlag.value.freeDelivery
  const isDelivery = checkoutData.shipping?.method === ShippingMethod.DELIVERY
  const shippingFee = isDelivery ? pickupEstimateState.value?.data?.estimate || 0 : undefined
  const totalPrice = freeDelivery ? cart.total.price : cart.total.price + (shippingFee || 0)

  const total = {
    price: totalPrice,
    shippingFee,
  }

  useEffect(() => {
    const address = checkoutData.shipping?.address
    if (address && address.googlePlaceId) {
      const agent = getAgentForCategory()
      pickupEstimate({
        pickupLocation: {
          id: agent.location.googlePlaceId,
          name: agent.location.googlePlaceName,
        },
        dropoffLocation: {
          id: address.googlePlaceId,
          name: address.street,
        },
      })
    }
  }, [checkoutData.shipping?.address?.googlePlaceId])

  const shippingPrice = isDelivery && freeDelivery ? 0 : total.shippingFee

  const handleCreate = (order: Order) => {
    cart.clearCheckedoutItems()
    setActiveStep((step) => step + 1)
    trackProducts('purchase', {
      transaction_id: order.payment?.paystack?.reference,
      affiliation: 'Resellam shop',
      value: total.price,
      currency: 'NGN',
      tax: 0,
      shipping: shippingPrice,
      products: cart.items.map((item) => ({
        ...item.product,
        price: item.price,
        quantity: item.quantity,
        variant: item.variants?.map((variant) => variant.value).join(', '),
      })),
    })
  }

  const createOrderFn = async () => {
    await auth.requireAuth()

    if (!checkoutData.shipping || !checkoutData.payment)
      throw new Error('Missing shipping or payment data')

    try {
      const result = await createOrder({
        payment: {
          amount: total.price,
          type: checkoutData.payment.type,
          method: checkoutData.payment.method,
        },
        shipping:
          checkoutData.shipping.method === ShippingMethod.DELIVERY
            ? {
                addressId: checkoutData.shipping.address?.id,
                price: shippingPrice,
              }
            : undefined,
        items: cart.items.map((item) => ({
          product: item.product,
          price: item.price,
          variants: item.variants,
          quantity: item.quantity,
          discount: item.discount,
        })),
        onCreate: handleCreate,
        requiresPayment: checkoutData.payment.type === PaymentType.NOW,
      })

      if (!result) throw new Error()

      if (result.paymentFailed) {
        router.replace(`/orders/${result.order.id}`)
      }

      return result.order
    } catch (error) {
      notifications.show({
        entity: 'order',
        variant: 'error',
        action: 'create',
      })
      return null
    }
  }

  const contextValue: CheckoutContextValue = {
    total,
    cart,
    activeStep,
    setActiveStep,
    checkoutData,
    setCheckoutData,
    pickupEstimate: pickupEstimateState,
    createOrderState,
    createOrder: createOrderFn,
    freeDelivery,
  }

  return <CheckoutContext.Provider value={contextValue} {...props} />
}

export default CheckoutProvider

export const useCheckoutContext = (): CheckoutContextValue => {
  const context = useContext(CheckoutContext)
  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutProvider')
  }
  return context
}
