import {Radio, RadioGroupProps} from 'ui/core'

interface RadioButtonsProps extends RadioGroupProps {
  testID?: string
}

const RadioButtons = ({testID, ...rest}: RadioButtonsProps) => (
  <Radio.Group {...rest} data-testid={testID} />
)

export default RadioButtons
