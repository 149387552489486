import {
  BRAND_FACET_ATTRIBUTE,
  CATEGORY_FACET_ATTRIBUTE,
  CONDITION_FACET_ATTRIBUTE,
} from 'lib/algolia'
import {Stack} from 'ui/core'
import {Panel} from '../Panel'
import {PriceMenu} from '../PriceMenu'
import {RefinementList} from '../RefinementList'

const Filters = () => (
  <Stack>
    <Panel header="Brands">
      <RefinementList attribute={BRAND_FACET_ATTRIBUTE} />
    </Panel>
    <Panel header="Categories">
      <RefinementList attribute={CATEGORY_FACET_ATTRIBUTE} />
    </Panel>
    <Panel header="Condition">
      <RefinementList attribute={CONDITION_FACET_ATTRIBUTE} />
    </Panel>
    <Panel header="Price">
      <PriceMenu />
    </Panel>
  </Stack>
)

export default Filters
