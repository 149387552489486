import {ReactNode, useState, useMemo} from 'react'
import {Text} from 'ui/core'
import {useModals} from 'ui/modals'
import {useBreakpoint} from '../../hooks/use-breakpoint'
import {ActionSheet} from '../../components/ActionSheet'
import {PopupContext, Popup, PopupContextValue} from './PopupContext'

interface PopupProviderProps {
  children: ReactNode
}

const PopupProvider = ({children}: PopupProviderProps) => {
  const modals = useModals()
  const {isXs} = useBreakpoint()
  const [popupInstance, setPopupInstance] = useState<Popup | null>(null)

  const hidePopup = () => setPopupInstance(null)

  const alertContext: PopupContextValue = useMemo(
    () => ({
      show: (popup: Popup) => {
        if ((!isXs || popup.style === 'modal') && popup.variant === 'confirmation') {
          modals.openConfirmModal({
            title: popup.title,
            children: popup.children || <Text>{popup.message}</Text>,
            labels: {
              confirm: popup.confirm?.title || 'Yes',
              cancel: popup.close?.title || 'No',
            },
            confirmProps: {
              color: popup.confirm?.color,
              onClick: popup.confirm?.onClick,
            },
            cancelProps: {
              color: popup.close?.color,
              onClick: popup.close?.onClick,
            },
          })
        } else {
          setPopupInstance(popup)
        }
      },
      hide: hidePopup,
    }),
    [isXs],
  )

  return (
    <PopupContext.Provider value={alertContext}>
      {children}
      <ActionSheet
        {...popupInstance}
        opened={!!popupInstance}
        withinPortal={popupInstance?.withinPortal}
        onDismiss={hidePopup}
      />
    </PopupContext.Provider>
  )
}

export default PopupProvider
