import {Text} from 'ui/core'
import {Address} from 'model'
import {fullName} from 'core/utils'
import {AddressLink, PhoneNumber} from 'core/components'
import {SelectableCard, SelectableCardProps} from '../SelectableCard'

interface ShippingAddressProps extends Omit<SelectableCardProps, 'children'> {
  address: Address
  testID?: string
}

export const ShippingAddressContent = ({testID, address}: ShippingAddressProps) => (
  <div data-testid={testID}>
    <Text lineClamp={1}>{fullName(address.firstName, address.lastName)}</Text>
    {address.phoneNumber && <PhoneNumber value={address.phoneNumber} />}
    {address.googlePlaceId && (
      <AddressLink placeName={address.street} placeId={address.googlePlaceId} />
    )}
  </div>
)

const ShippingAddress = ({address, selected, onClick, testID}: ShippingAddressProps) => (
  <SelectableCard selected={selected} onClick={onClick} testID={testID}>
    <ShippingAddressContent address={address} />
  </SelectableCard>
)

export default ShippingAddress
