import useTask from '@resellam/hooks/hooks/use-task'
import {
  FirebaseAuthProviderID,
  signInWithExistingProvider,
  signInWithFacebook,
  signInWithGoogle,
} from '@resellam/auth'
import {FirebaseErrorCode, getAuth} from '@resellam/firebase'
import {FacebookAuthProvider, fetchSignInMethodsForEmail, GoogleAuthProvider} from 'firebase/auth'
import {useState} from 'react'
import {Stack, LoadingOverlay, GoogleButton, FacebookButton, Text} from 'ui/core'

type SocialSigninProviderID = Extract<
  FirebaseAuthProviderID,
  typeof GoogleAuthProvider.PROVIDER_ID | typeof FacebookAuthProvider.PROVIDER_ID
>

const getProviderName = (providerID: SocialSigninProviderID) => {
  switch (providerID) {
    case 'google.com':
      return 'Google'
    case 'facebook.com':
      return 'Facebook'
    default:
      return null
  }
}

const SignInFuncMap = {
  [GoogleAuthProvider.PROVIDER_ID]: signInWithGoogle,
  [FacebookAuthProvider.PROVIDER_ID]: signInWithFacebook,
}

export interface SocialAuthProps {
  onStart?: (provider: string) => void
  onSuccess?: () => void
  providers?: SocialSigninProviderID[]
}

const SocialAuth = ({providers, onStart, onSuccess}: SocialAuthProps) => {
  const [authTaskFn, authTaskState] = useTask<void>()
  const [signinProvider, setSigninProvider] = useState<SocialSigninProviderID>()
  const [existingAccountProvider, setExistingAccountProvider] = useState<{
    providerId: SocialSigninProviderID
    error: any
  }>()

  const authenticate = async (provider: SocialSigninProviderID) =>
    authTaskFn(async () => {
      onStart?.(provider)
      try {
        setSigninProvider(provider)
        await SignInFuncMap[provider]()
        onSuccess?.()
      } catch (error: any) {
        const errorCode = error.code as FirebaseErrorCode
        if (errorCode === 'auth/account-exists-with-different-credential') {
          const email = error.customData?.email
          const [method] = await fetchSignInMethodsForEmail(getAuth(), email)
          setExistingAccountProvider({
            providerId: method as SocialSigninProviderID,
            error,
          })
        }
      }
    })

  const existing = async () =>
    authTaskFn(async () => {
      if (!existingAccountProvider || !signinProvider) return
      await signInWithExistingProvider(
        signinProvider,
        existingAccountProvider.providerId,
        existingAccountProvider.error,
      )
      onSuccess?.()
    })

  return (
    <Stack>
      <LoadingOverlay visible={authTaskState.isRunning} />
      {existingAccountProvider ? (
        <>
          <div>
            <Text>
              You already have an account registered with{' '}
              {getProviderName(existingAccountProvider.providerId)}.
            </Text>
            <Text>Click the button below to signin to your exisiting account.</Text>
          </div>
          {existingAccountProvider.providerId === 'google.com' && (
            <GoogleButton onClick={existing}>Continue with Google</GoogleButton>
          )}
          {existingAccountProvider.providerId === 'facebook.com' && (
            <FacebookButton onClick={existing}>Continue with Facebook</FacebookButton>
          )}
        </>
      ) : (
        <>
          {providers?.includes('google.com') && (
            <GoogleButton onClick={() => authenticate('google.com')} />
          )}
          {providers?.includes('facebook.com') && (
            <FacebookButton onClick={() => authenticate('facebook.com')} />
          )}
        </>
      )}
    </Stack>
  )
}
export default SocialAuth
