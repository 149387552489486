import {Carousel as UICarousel} from 'ui/carousel'
import Autoplay from 'embla-carousel-autoplay'
import {useRef} from 'react'
import {CarouselImage} from '../CarouselImage'
import classes from './Carousel.module.css'

const content = [
  {
    text: 'Phone',
    category: 'Smartphone',
    imageUrl: '/images/hero-phone.png',
  },
  {
    text: 'Laptop',
    category: 'Laptop',
    imageUrl: '/images/hero-laptop.png',
  },
  {
    text: 'Smartwatch',
    category: 'Wearable',
    imageUrl: '/images/hero-smartwatches.png',
  },
  {
    text: 'Tablet',
    category: 'Tablet',
    imageUrl: '/images/hero-tablets.png',
  },
  {
    text: 'Gadgets',
    category: 'Smartphone',
    imageUrl: '/images/hero-gadgets.png',
  },
]

const Carousel = () => {
  const autoplay = useRef(Autoplay({delay: 2000}))

  return (
    <UICarousel
      loop
      align="center"
      withControls={false}
      withIndicators={false}
      slideGap="lg"
      height="100%"
      style={{flex: 1}}
      slideSize="100%"
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
    >
      {content.map((item) => (
        <UICarousel.Slide key={`slide-photo-${item.text}`}>
          <CarouselImage
            className={classes.root}
            src={item.imageUrl}
            caption={item.text}
            href={`/shop?category=${item.category}`}
          />
        </UICarousel.Slide>
      ))}
    </UICarousel>
  )
}

export default Carousel
