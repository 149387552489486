import {Flex} from 'ui/core'
import {useEffect, useState} from 'react'
import {useInterval} from 'ui/hooks'
import {formatTestID} from 'core/utils'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import {SellRequestCountdownTime} from '../SellRequestCountdownTime'

dayjs.extend(duration)

interface SellRequestCountdownProps {
  expiresAt: Date
  testID?: string
}

const SellRequestCountdown = ({expiresAt, testID}: SellRequestCountdownProps) => {
  const [expiryDuration, setExpiryDuration] = useState(
    dayjs.duration(dayjs(expiresAt).diff(new Date())),
  )
  const interval = useInterval(
    () => setExpiryDuration(dayjs.duration(dayjs(expiresAt).diff(new Date()))),
    1000,
  )

  useEffect(() => {
    interval.start()
    return interval.stop
  }, [expiresAt])

  const days = expiryDuration.days()
  const hours = expiryDuration.hours()
  const minutes = expiryDuration.minutes()
  const seconds = expiryDuration.seconds()

  return (
    <Flex justify="center" gap="md" data-testid={testID}>
      <SellRequestCountdownTime
        label={days > 1 ? 'Days' : 'Day'}
        value={days.toString()}
        testID={formatTestID(testID, 'days')}
      />
      <SellRequestCountdownTime
        label={hours > 1 ? 'Hours' : 'Hour'}
        value={hours.toString()}
        testID={formatTestID(testID, 'hours')}
      />
      <SellRequestCountdownTime
        label={minutes > 1 ? 'Minutes' : 'Minute'}
        value={minutes.toString()}
        testID={formatTestID(testID, 'minutes')}
      />
      <SellRequestCountdownTime
        label={seconds > 1 ? 'Seconds' : 'Second'}
        value={seconds.toString()}
        testID={formatTestID(testID, 'seconds')}
      />
    </Flex>
  )
}

export default SellRequestCountdown
