import {Card, Text, Group, Stack} from 'ui/core'
import {useCart} from 'lib/cart'
import {Money} from 'core/components'
import {CheckoutButton} from '../CheckoutButton'

const CartTotal = () => {
  const cart = useCart({checkout: true, total: {checkout: true}})

  return (
    <Card shadow="md">
      <Stack>
        <Group justify="space-between" gap="xs">
          <Text>Subtotal ({cart.total.quantity} items)</Text>
          <Money inline fw="bold" value={cart.total.price} />
        </Group>
        <CheckoutButton disabled={cart.isEmpty} />
      </Stack>
    </Card>
  )
}

export default CartTotal
