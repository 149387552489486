import {Product, ProductVariant, ProductVariantValue} from 'model'
import {z} from 'zod'

interface ProductVariantProps {
  label?: string
  inputType?: 'dropdown' | 'select' | 'color' | string
  options: {label: string; value: string}[]
}

export const getProductVariants = (
  variants?: ProductVariant[],
  filterVariantValue: (variantValue: ProductVariantValue) => boolean = () => true,
): ProductVariantProps[] =>
  variants?.map((variant) => ({
    label: variant.variantName,
    inputType: variant?.variantInputType || undefined,
    options:
      variant.variantValue
        ?.filter(filterVariantValue)
        .filter(({key}) => !!key)
        .sort((a, b) => (a.value || 0) - (b.value || 0))
        .map(({key}) => ({
          label: key || 'Unknown',
          value: key || 'Unknown',
        })) || [],
  })) || []

export const isAppleSmartphone = (product?: Product) =>
  product?.category?.slug === 'smartphone' && product?.brand?.slug === 'apple'

export const transformVariants = (variants: Record<string, string>) =>
  [...Object.entries(variants)].reduce<{label: string; value: string}[]>(
    (accm, [label, value]) => [...accm, {label, value}],
    [],
  )

interface Variant {
  label: string
  value: string
}

export const variantsSchema = (variants?: ProductVariant[]) =>
  z
    .object(
      (variants || []).reduce<Record<string, z.ZodString>>(
        (accm, variant) =>
          variant.variantName
            ? {
                ...accm,
                [variant.variantName]: z
                  .string({required_error: `Choose ${variant.variantName}`})
                  .min(1, {message: `Choose ${variant.variantName}`}),
              }
            : accm,
        {},
      ),
    )
    .transform<Variant[]>(transformVariants)

export const getFormVariants = (
  variants?: ProductVariant[],
  initialValues?: Variant[],
  findValue?: (variant: ProductVariant) => string | undefined,
) =>
  variants?.reduce<Record<string, string>>(
    (accm, variant) =>
      variant.variantName
        ? {
            ...accm,
            [variant.variantName]: findValue
              ? findValue(variant) || ''
              : initialValues?.find((val) => val.label === variant.variantName)?.value || '',
          }
        : accm,
    {},
  ) || {}

export const getVariantsMapping = (product: Product) =>
  (product?.variants || []).reduce((acc, cur) => {
    if (cur.variantName && cur.variantValue) {
      acc[cur.variantName] = cur.variantValue.reduce((_acc, _cur) => {
        if (_cur.key) {
          // eslint-disable-next-line no-param-reassign
          _acc[_cur.key] = typeof _cur.value === 'number' ? _cur.value : 0
        }
        return _acc
      }, {} as Record<string, number>)
    }
    return acc
  }, {} as Record<string, Record<string, number>>)

export const formatVariantValue = (value: string) => {
  const splitted = value.split('||')
  return splitted.length > 1 ? splitted[1] : value
}
