import {Card, Text, Group, Button, Icon, Center, IconButton} from 'ui/core'
import {useCart} from 'lib/cart'
import {CartItem as CartItemModel} from 'lib/cart/types'
import Link from 'next/link'
import {DiscountedPrice} from 'core/components'
import {ProductImage} from 'components'
import {CartItemRemoveButton} from '../CartItemRemoveButton'

interface FloatingCartItemProps {
  item: CartItemModel
}

const FloatingCartItem = ({item}: FloatingCartItemProps) => {
  const cart = useCart()
  const isNaijaUsed = item.product.condition === 'Naija Used'

  return (
    <Card p="xs">
      <Center mb="xs">
        <Link href={`/shop/${item.product.slug}`} onClick={() => cart.close()}>
          <ProductImage product={item.product} size={100} />
        </Link>
      </Center>
      <DiscountedPrice
        align="center"
        price={item.price}
        quantity={item.quantity}
        discount={item.discount}
      />
      <Group mt="xs" justify="space-between" wrap="nowrap">
        <Text size="sm">Qty: {item.quantity}</Text>
        <Button.Group>
          <Button
            variant="default"
            radius="sm"
            size="compact-xs"
            disabled={item.quantity <= 1 || isNaijaUsed}
            onClick={() => cart.setItemQuantity(item, item.quantity - 1)}
          >
            <Icon name="minus" size="xs" />
          </Button>
          <Button
            variant="default"
            radius="sm"
            size="compact-xs"
            disabled={item.quantity >= 10 || isNaijaUsed}
            onClick={() => cart.setItemQuantity(item, item.quantity + 1)}
          >
            <Icon name="plus" size="xs" />
          </Button>
        </Button.Group>
      </Group>
      <CartItemRemoveButton<any>
        item={item}
        renderRoot={(props: any) => (
          <IconButton
            {...props}
            name="trash"
            size="lg"
            style={{position: 'absolute', top: 4, right: 4}}
          />
        )}
      />
    </Card>
  )
}

export default FloatingCartItem
