import {Box, Button, BoxProps, Icon, IconName, Title} from 'ui/core'
import {formatTestID} from 'core/utils'

export interface SellRequestOfferHeaderProps extends BoxProps {
  title: string
  iconName: IconName
  onBack?: () => void
  testID?: string
}

const SellRequestOfferHeader = ({
  title,
  iconName,
  onBack,
  testID,
  ...rest
}: SellRequestOfferHeaderProps) => (
  <Box mb="md" data-testid={testID} {...rest}>
    {onBack && (
      <Button
        size="compact-md"
        pl={0}
        variant="subtle"
        leftSection={<Icon name="chevronLeft" />}
        onClick={onBack}
        data-testid={formatTestID(testID, 'back-button')}
      >
        Back
      </Button>
    )}
    <Title
      order={3}
      style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4}}
      data-testid={formatTestID(testID, 'title')}
    >
      <Icon color="gray" name={iconName} size="xl" />
      {title}
    </Title>
  </Box>
)

export default SellRequestOfferHeader
