import {Skeleton, Card, SimpleGrid} from 'ui/core'

const BankAccountCardLoading = () => (
  <Card>
    <SimpleGrid cols={{base: 1, sm: 3}}>
      <Skeleton height={20} width="50%" radius="xl" />
      <Skeleton height={20} width="50%" radius="xl" />
      <Skeleton height={20} width="50%" radius="xl" />
    </SimpleGrid>
  </Card>
)

export default BankAccountCardLoading
