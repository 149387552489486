import {BottomSheet} from 'core/components'
import {Button, ButtonProps, Group, Icon, ScrollArea, Stack, Title} from 'ui/core'
import {useState} from 'react'
import {useStats} from '../../Stats'
import Filters from '../Filters'

const FiltersButton = (props: Partial<ButtonProps>) => {
  const stats = useStats()
  const [opened, setOpened] = useState(false)

  const closeSheet = () => setOpened(false)

  const maxHeight = typeof window === 'undefined' ? 500 : (70 / 100) * window.innerHeight

  return (
    <div>
      <Button
        {...props}
        size="sm"
        variant="subtle"
        rightSection={<Icon name="chevronDown" color="blue" />}
        onClick={() => setOpened(!opened)}
      >
        Filters
      </Button>
      <BottomSheet keepMounted opened={opened} onClose={closeSheet}>
        <Stack>
          <Group justify="space-between">
            <Title order={4}>Filters</Title>
            <Button variant="subtle" size="compact-sm" onClick={closeSheet}>
              Close
            </Button>
          </Group>
          <ScrollArea.Autosize mah={maxHeight} type="auto">
            <Filters />
          </ScrollArea.Autosize>
          <Button onClick={closeSheet}>Show {stats.nbHits} results</Button>
        </Stack>
      </BottomSheet>
    </div>
  )
}

export default FiltersButton
