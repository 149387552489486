export const CATEGORY_FIELDS = `
  'id': _id,
  'slug': slug.current,
  title,
  active,
  'image': image.asset -> url,
  'group': group -> {
    'id': _id,
    title,
    'slug': slug.current,
    'image': image.asset -> url
  }
`
