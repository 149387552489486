import {Stack, Text} from 'ui/core'
import {ReactNode} from 'react'

interface PanelProps {
  header: ReactNode
  children: ReactNode
}

const Panel = ({header, children}: PanelProps) => (
  <Stack gap={4}>
    <Text size="sm" style={{fontWeight: 500}}>
      {header}
    </Text>
    {children}
  </Stack>
)

export default Panel
